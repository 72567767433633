import React, { Component } from "react";
import ApiService from "./../utils/ApiService";
import ApiServiceCNPJ from "./../utils/ApiServiceCNPJ";
import serialize from "form-serialize";
import LoadingOverlay from "react-loading-overlay";
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

class CadastrarDestinatario extends Component {
  state = {
    dest: {
      idEmpresa: 0,
      cpfCnpj: "",
      inscricaoEstadual: "",
      nomeRazaoSocial: "",
      cep: "",
      endereco: "",
      bairro: "",
      idMunicipio: 0,
      telefone: "",
      celular: "",
      contato: "",
      email: "",
      idIndicadorIE: 0,
      aliqPIS: 0,
      aliqCOFINS: 0,
      aliqCSLL: 0,
      aliqIRRF: 0,
      aliqISS: 0,
      aliqPREV: 0,
      municipio: {
        idUF: 0
      }
    },
    uf: [],
    municipios: [],
    CNPJ: {},
    Aliquotas: false,
    idDestinatario: null,
    error: [],
    loading: false
  };

  componentWillMount() {}

  toggleAliquotas = () => {
    this.setState(prev => ({
      Aliquotas: !prev.Aliquotas
    }));
  };

  handleUFChange = e => {
    var api = new ApiService();
    var idUF = e.target.value;
    if (idUF !== "") {
      var dest = Object.assign({}, this.state.dest);
      dest["municipio"]["idUF"] = e.target.value;
      api.obterMunicipio(idUF).then(x => {
        this.setState({ municipios: x.data, dest });
      });
    }
  };

  componentDidMount() {
    var api = new ApiService();
    api.obterUFs().then(x => {
      this.setState({ uf: x.data });
      //console.log();
    });

    var { idDestinatario } = this.state;

    if (this.props.match) {
      idDestinatario = this.props.match.params.id
        ? parseInt(this.props.match.params.id, 10)
        : null;

      this.setState({ idDestinatario });
    }   

    if (idDestinatario) {
      var api = new ApiServiceCNPJ();
      api.carregarDestinatario(idDestinatario).then(d => {
          var dest = d.data;
          dest.cpfCnpj = dest.cpfCnpj.replace(/\D/g, '');
          if (dest.cpfCnpj.length === 11) 
            dest.cpfCnpj = dest.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
          else
            dest.cpfCnpj = dest.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
          dest.cep = dest.cep.replace(/(\d{2})(\d{3})(\d{3})/g,"$1.$2-$3");
          dest.telefone = dest.telefone.replace(/^(\d{2})(\d)/g,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2");
          if (!dest.celular) dest.celular = ""; else dest.celular = dest.celular.replace(/^(\d{2})(\d)/g,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2");
          if (!dest.contato) dest.contato = "";
          if (!dest.email) dest.email = "";
          this.setState({ dest });

          api.obterMunicipio(dest.municipio.idUF).then(x => {
            this.setState({ municipios: x.data, dest });
        });
      });
    }

    var options = {
      onChange : function(cpfcnpj, e, field, options) {
        var masks = ['000.000.000-000', '00.000.000/0000-00'];
        var mask = (cpfcnpj.length > 14) ? masks[1] : masks[0];
        $('.cpfcnpj').mask(mask, options);
      }
    };
  
    $('.cpfcnpj').mask('000.000.000-000', options);
    $('.telefone').mask('(00) 0000-0000', { reverse: false });
    $('.cep').mask('00.000-000', { reverse: false });
    $('.inscricao').mask('0000000000000', { reverse: false });
  }

    handleInputChange = e => {
        var dest = Object.assign({}, this.state.dest);
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            dest[e.target.name] = !dest[e.target.name];
        } else {
            dest[e.target.name] = e.target.value;
            console.log(e.target.value);
            if (e.target.name === "cpfCnpj") {
              var cpjCnpjUnMask = e.target.value
                              .toString()
                              .replace(/[^0-9]+/g, "");

                if (cpjCnpjUnMask.length === 14) {
                    var api = new ApiServiceCNPJ();
                    api.getCNPJ(cpjCnpjUnMask).then(cnpj => {
                        if (cnpj.error && cnpj.error.count > 0) {
                            alert(cnpj.error.erros[0]);
                        } else {
                            cnpj = cnpj.data;
                            if (cnpj.status != "ERROR") {
                                if(cnpj.nome.length > 60)
                                {
                                  dest["nomeRazaoSocial"] = cnpj.nome.substring(0, 60);
                                } else {
                                  dest["nomeRazaoSocial"] = cnpj.nome;
                                }
                                dest["municipio"]["idUF"] = this.state.uf.filter(
                                    x => x.sigla === cnpj.uf
                                )[0].idUF;
                                dest["cep"] = cnpj.cep.replace(/[^0-9]+/g, "").replace(/(\d{2})(\d{3})(\d{3})/g,"$1.$2-$3");
                                dest["endereco"] = cnpj.logradouro;
                                var telefone =  cnpj.telefone.replace(/[^0-9]+/g, "")
                                if (telefone.length > 10) telefone = telefone.substring(0, 10);
                                dest["telefone"] = telefone.replace(/^(\d{2})(\d)/g,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2");
                                dest["bairro"] = cnpj.bairro;
                                api.obterMunicipio(dest["municipio"]["idUF"]).then(x => {
                                    this.setState({ municipios: x.data });
                                });
                            } else {
                                alert("CNPJ Inválido");
                            }
                        }                        
                    });
                }
            }
        }
        this.setState({
            dest
        });
    };

  handleSubmit = e => {
    e.preventDefault();
    var api = new ApiServiceCNPJ();
    const { idDestinatario, dest } = this.state;
    this.setState({ loading: true });
    if (idDestinatario) {
      api.alterarDestinario(dest).then(r => {
        if (r.error && r.error.count > 0) {
          this.setState({ error: r.error.erros });
        } else {
          alert("Operação realizada com sucesso.");
          this.props.history.push("/destinatarios");
        }
      });
    } else {
      var form = serialize(e.target, { hash: true });
      api.incluirDestinario(form).then(r => {
        if (r.error && r.error.count > 0) {
          this.setState({ error: r.error.erros });
        } else {
          alert("Operação realizada com sucesso.");

          const { PrimeiroCadastro, history } = this.props;
          if (PrimeiroCadastro && history) history.push("/cadastrarNotaFiscal");
          else this.props.history.push("/destinatarios");
        }
      });
    }
    this.setState({ loading: false });
  };
  render() {
    const { dest, uf, municipios, Aliquotas, error, loading } = this.state;
    return (
      <div className="container">
        <LoadingOverlay active={loading} spinner>
          {error.length > 0 && (
            <div className="callout callout-danger">
              {error.map((e, c) => (
                <p>
                  {c + 1} - {e}
                </p>
              ))}
            </div>
          )}
          <form onSubmit={this.handleSubmit}>
            <div className="card">
              <div className="card-header">Incluir Destinatário</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>CNPJ/CPF</label>
                      <input
                        type="text"
                        className="form-control cpfcnpj"
                        value={dest.cpfCnpj}
                        name="cpfCnpj"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Inscrição Estadual</label>
                      <input
                        type="text"
                        className="form-control inscricao"
                        value={dest.inscricaoEstadual}
                        name="inscricaoEstadual"
                        onChange={this.handleInputChange}
                        onInput={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>Nome / Razão Social</label>
                      <input
                        type="text"
                        className="form-control"
                        value={dest.nomeRazaoSocial}
                        name="nomeRazaoSocial"
                        required
                        maxLength="60"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3 col-lg-3">
                    <div className="form-group">
                      <label>CEP</label>
                      <input
                        type="text"
                        className="form-control cep"
                        value={dest.cep}
                        name="cep"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-9 col-lg-9">
                    <div className="form-group">
                      <label>Endereço/Logradouro</label>
                      <input
                        type="text"
                        className="form-control"
                        value={dest.endereco}
                        name="endereco"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Bairro</label>
                      <input
                        type="text"
                        className="form-control"
                        value={dest.bairro}
                        name="bairro"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2">
                    <div className="form-group">
                      <label>UF</label>
                      <select
                        name="idUF"
                        className="form-control"
                        onChange={this.handleUFChange}
                        required
                        value={dest.municipio.idUF}
                      >
                        <option />
                        {uf.map(u => (
                          <option value={u.idUF} key={u.idUF}>
                            {u.sigla}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Município</label>
                      <select
                        className="form-control"
                        name="idMunicipio"
                        value={dest.idMunicipio}
                        onChange={this.handleInputChange}
                        required
                      >
                        <option />
                        {municipios.map(m => (
                          <option key={m.idMunicipio} value={m.idMunicipio}>
                            {m.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Telefone</label>
                      <input
                        type="text"
                        className="form-control telefone"
                        value={dest.telefone}
                        name="telefone"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Celular</label>
                      <input
                        type="text"
                        className="form-control telefone"
                        value={dest.celular}
                        name="celular"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Contato</label>
                      <input
                        type="text"
                        className="form-control"
                        value={dest.contato}
                        name="contato"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>E-MAIL</label>
                      <input
                        type="text"
                        className="form-control"
                        value={dest.email}
                        name="email"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-8">
                    <div className="form-group">
                      <label>Indicador IE</label>
                      <select
                        className="form-control"
                        name="idIndicadorIE"
                        value={dest.idIndicadorIE}
                        onChange={this.handleInputChange}
                      >
                        <option value="1">Contribuinte ICMS</option>
                        <option value="2">Contribuinte isento</option>
                        <option value="9">Não Contribuinte</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <input
                        type="button"
                        onClick={this.toggleAliquotas}
                        className="btn btn-primary mr-1"
                        value="Aliquotas para Retenção de Impostos"
                      />
                    </div>
                  </div>
                </div>
                {Aliquotas && (
                  <div className="row">
                    <div className="col-12 col-md-2 col-lg-2">
                      <div className="form-group">
                        <label>% PIS</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dest.aliqPIS}
                          name="aliqPIS"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <div className="form-group">
                        <label>% COFINS</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dest.aliqCOFINS}
                          name="aliqCOFINS"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <div className="form-group">
                        <label>% CSLL</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dest.aliqCSLL}
                          name="aliqCSLL"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <div className="form-group">
                        <label>% IRRF</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dest.aliqIRRF}
                          name="aliqIRRF"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <div className="form-group">
                        <label>% ISS</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dest.aliqISS}
                          name="aliqISS"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <div className="form-group">
                        <label>% PREV</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dest.aliqPREV}
                          name="aliqPREV"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <button
                type="submit"
                className="btn btn-primary mr-1"
                style={{ marginTop: 10, marginLeft: "auto" }}
              >
                Salvar
              </button>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}

export default CadastrarDestinatario;
