import React, { Component } from "react";
import ApiServiceGrupoImposto from "./../utils/ApiServiceGrupoImposto";
import ApiServiceProduto from "./../utils/ApiServiceProduto";
import serialize from "form-serialize";
import ModalProduto from "./Produto/ModalProduto";
import FormProduto from "./Produto/FormProduto";
import { isNumber } from "../../node_modules/util";
import LoadingOverlay from "react-loading-overlay";

class cadastrarProduto extends Component {
  state = {
    GrupoImpostos: [],
    UnidadeMedida: [],
    loading: false,
    modal: false,
    NCMDesc: "",
    NCMId: "",
    idProduto: null,
    produto: {
      idProduto: 0,
      idGrupoImposto: 0,
      codigoProduto: "",
      descricao: "",
      valorUnitario: 0,
      valorUnitarioFormatado: 0,
      codBarras: "",
      idUnidadeMedida: 0,
      idNCM: 0
    },
    error: []
  };

  componentDidMount() {
    var { idProduto } = this.state;

    if (this.props.match) {
      idProduto = this.props.match.params.id
        ? parseInt(this.props.match.params.id, 10)
        : null;

      this.setState({ idProduto });
    } else {
    }   

    if (idProduto) {
      this.setState({ loading: true });
      var api = new ApiServiceProduto();
      api.getProduto(idProduto).then(p => {
        var produto = p.data;
        if (!produto.codBarras) produto.codBarras = "";
        if (!produto.codigoProduto) produto.codigoProduto = "";
        if (produto.valorUnitario)
          produto.valorUnitarioFormatado = produto.valorUnitario;
        this.setState({
          produto: p.data,
          NCMDesc: p.data.ncm.descricao,
          loading: false
        });
      });
    }
  }

  salvar = () => {
    var api = new ApiServiceProduto();
    const { produto } = this.state;
    if (!this.state.idProduto) {
      api.incluirProduto(produto).then(r => {
        if (r.error && r.error.count > 0) {
          this.setState({ error: r.error.erros });
        } else {
          alert("Operação realizada com sucesso.");
          if (this.props.history) this.props.history.push("/produtos");
        }
      });
    } else {
      this.setState({ loading: true });
      api.alterarProduto(produto).then(r => {
        if (r.error && r.error.count > 0) {
          this.setState({ error: r.error.erros });
          this.props.history.push("/produtos");
        } else {
          alert("Operação realizada com sucesso.");
          this.props.history.push("/produtos");
        }
        this.setState({ loading: false });
      });
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  selecionarItem = (id, desc) => {
    var produto = Object.assign({}, this.state.produto);
    produto["idNCM"] = id;
    this.setState({ NCMDesc: desc, produto });
    this.toggle();
  };

  alterarDadosProduto = e => {
    var produto = Object.assign({}, this.state.produto);
    produto[e.target.name] = e.target.value;
    this.setState({ produto });
  };

  alterarDadoCurreny = (e, maskedvalue, floatvalue) => {
    var produto = Object.assign({}, this.state.produto);
    produto[e.target.name + "Formatado"] = e.target.value;
    produto[e.target.name] = floatvalue;
    this.setState({ produto });
  };

  render() {
    const { produto, NCMDesc, error, loading } = this.state;
    return (
      <div className="container">
        <LoadingOverlay active={loading} spinner>
          {error.length > 0 && (
            <div className="callout callout-danger">
              {error.map((e, c) => (
                <p>
                  {c + 1} - {e}
                </p>
              ))}
            </div>
          )}

          <ModalProduto
            backdrop="static"
            toggle={this.toggle}
            modal={this.state.modal}
            className="modal-content bg-light"
            selecionarItem={this.selecionarItem}
          />
          <FormProduto
            onInputChange={this.alterarDadosProduto}
            onCurrenyChange={this.alterarDadoCurreny}
            idNCM={produto.idNCM}
            toggle={this.toggle}
            NCMDesc={NCMDesc}
            salvar={this.salvar}
            produto={produto}
          />
        </LoadingOverlay>
      </div>
    );
  }
}

export default cadastrarProduto;
