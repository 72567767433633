import React from "react";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ResumoTotalICMS = props => {
  const { resumoCalc } = props;
  return (
    <div className="card">
      <div className="card-header">ICMS</div>
      <div className="card-body">
        <div className="row justify-content-around">
          <div className="col-2 col-md-2 col-lg-2">
            <div className="form-group ">
              <label>Base de Cálculo</label>
              <div className="form-group ">{formatter.format(resumoCalc.icmsBaseCalculo)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>ICMS</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.icms)}</div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>PIS</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.icmsPis)}</div>
              </div>
            </div>
          </div>
          <div className="col-2 col-md-2 col-lg-2">
            <div className="form-group ">
              <label>COFINS</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.icmsCofins)}</div>
              </div>
            </div>
          </div>

          <div className="col-4 col-md-4 col-lg-4">
            <div className="form-group ">
              <label>Substituição Tributária Base de Cálculo</label>
              <div className="form-group ">
                <div className="form-group ">
                  {formatter.format(resumoCalc.icmsStBaseCalculo)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 col-md-2 col-lg-2">
            <div className="form-group ">
              <label>ICMS-ST</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.icmsSt)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResumoTotalICMS.propTypes = {};

export default ResumoTotalICMS;
