import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import {
  TextField,
  Select,
  Table,
  TableContainer,
  TextareaAutosize,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  CircularProgress
} from "@material-ui/core";

import ApiServiceNota from "../utils/ApiServiceNota";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  table: {
    minWidth: 650
  },
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function SimpleModal({ props }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [rows, setRow] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [dados, setDado] = React.useState({
    serie: 1,
    numeroInicial: 0,
    numeroFinal: 0,
    ano: 20,
    tipoDocumento: 55,
    justificativa: ""
  });
  const timer = React.useRef();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChangeInput = e => {
    setDado({ ...dados, [e.target.name]: e.target.value });
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success
  });

  const registarSolicitacao = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);

      var api = new ApiServiceNota();
      api.InutilizarNumeracaoNota(dados).then(x => {
        var noError = x.error && x.error.count == 0;
        setSuccess(noError);
        setLoading(false);
        if (!noError) alert(x.error.erros[0]);
        if (noError) {
          setOpen(false);
        }
      });
      //
    }
  };

  useEffect(() => {
    var api = new ApiServiceNota();
    api.getHistoricoInutilizacao().then(x => {
      setRow(x.data);
    });
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={handleOpen}
        className="btn btn-primary mr-1"
      >
        Inutilizar Número
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Inutilizar número NFe</h2>
            <p id="transition-modal-description">
              Informe a Serie e faixa de número que deseja inutilizar.
            </p>
            <form>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  id="standard-basic"
                  label="Serie"
                  name="serie"
                  value={dados.serie}
                  onChange={handleOnChangeInput}
                />
                <TextField
                  id="standard-basic"
                  label="Faixa Inicial"
                  name="numeroInicial"
                  value={dados.numeroInicial}
                  onChange={handleOnChangeInput}
                />
                <TextField
                  id="standard-basic"
                  label="Faixa Final"
                  name="numeroFinal"
                  value={dados.numeroFinal}
                  onChange={handleOnChangeInput}
                />
              </div>
              <div style={{ width: "100%", marginTop: 10 }}>
                <TextareaAutosize
                  rowsMin={4}
                  style={{ flex: 1, width: "100%" }}
                  value={dados.justificativa}
                  name="justificativa"
                  onChange={handleOnChangeInput}
                  placeholder="Informe a justificativa"
                />
              </div>
              <div className={classes.root}>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={buttonClassname}
                    disabled={loading}
                    onClick={registarSolicitacao}
                  >
                    Registrar Solicitação
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </form>

            <p></p>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Data Ação</TableCell>
                    <TableCell align="center">Ano</TableCell>
                    <TableCell align="center">Serie</TableCell>
                    <TableCell align="center">Número Inicial</TableCell>
                    <TableCell align="center">Número Final</TableCell>
                    <TableCell align="center">Justificativa</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.idNotaFiscalInutilizacao}>
                      <TableCell align="center">
                        {new Date(row.dataRegistro).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">{row.ano}</TableCell>
                      <TableCell align="center">{row.serie}</TableCell>
                      <TableCell align="center">{row.numeroInicial}</TableCell>
                      <TableCell align="center">{row.numeroFinal}</TableCell>
                      <TableCell align="left">{row.justificativa}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
