import { LOGGIN_USER, LOGOUT_USER } from "./../actions/index";
import { combineReducers } from "../../node_modules/redux";
import { connectRouter } from "connected-react-router";

function user(state = { user: {}, isAuthenticated: false }, action) {
  switch (action.type) {
    case LOGGIN_USER:
      const { user } = action;
      return {
        ...state,
        user,
        isAuthenticated: true
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null
      };
    default:
      return state;
  }
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    user
  });
