import React, { Component } from "react";
import { Route, Redirect } from "react-router";
// import { Layout } from "./components/Layout";
import Layout from "./ComponentsUI/Layout";
//import { Home } from "./components/Home";
import Home from "./components/Home";
import { FetchData } from "./components/FetchData";
import { Counter } from "./components/Counter";
import Empresa from "./components/empresa";
import Configuracoes from "./components/configuracoes";
import Register from "./components/Register";
import Login from "./components/Login";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { loginUser } from "./actions/index";
import ApiService from "./utils/ApiService";
import Certificado from "./components/certificado";
import GrupoImpostos from "./components/grupoImpostos";
import CadastrarGrupoImposto from "./components/cadastrarGrupoImposto";
import produtos from "./components/produtos";
import cadastrarProduto from "./components/cadastrarProduto";
import Destinatarios from "./components/destinatarios";
import CadastrarDestinatario from "./components/cadastrarDestinatario";
import Notafiscal from "./components/Notafiscal";
import CadastrarNotaFiscal from "./components/cadastrarNotaFiscal";
import RecuperarSenha from "./components/RecuperarSenha";
import RecuperarSenhaNova from "./components/RecuperarSenhaNova";
import pacotes from "./components/pacotes";
import Comprar from "./components/comprar";
import historicoCompra from "./components/historicoCompra";
import PrimeiroCadastro from "./pages/PrimeiroCadastro";
import CadastrarCertificado from "./components/CadastrarCertificado";
import { PoliticaPrivacidade } from "./politica/PoliticaPrivacidade";
import { TermoUso } from "./politica/TermosUso";

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      isAuthenticated: false,
      nomeEmpresa: ""
    };
  }

  sair = () => {
    var api = new ApiService();
    api.logout();
    window.location.reload();
  };

  render() {
    var isAuthenticated = this.api.loggedIn();
    
    var cnpj = this.api.getCnpj();
    if (cnpj != null && cnpj != ''){
      cnpj = cnpj.length > 11
      ? cnpj.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        )
      : cnpj.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
          "$1.$2.$3-$4");
    }

    var nomeEmpresa =
      this.api.getNomeFantasia() == "null"
        ? ""
        : this.api.getNomeFantasia() + " - " + cnpj;
    return (
      <ConnectedRouter history={this.props.history}>
        <Layout
          withTemplate={isAuthenticated}
          isAuthenticated={isAuthenticated}
          onLogout={this.sair}
          nomeEmpresa={nomeEmpresa}
        >
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path="/Home"
            component={Home}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path="/"
            component={Home}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/empresa"
            component={Empresa}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/configuracoes"
            component={Configuracoes}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/grupoImpostos"
            component={GrupoImpostos}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/counter"
            component={Counter}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/fetch-data"
            component={FetchData}
          />
          <Route path="/Register" component={Register} />
          <Route path="/Login" component={Login} />
          <Route path="/RecuperarSenha" component={RecuperarSenha} />
          <Route path="/PoliticaPrivacidade" component={PoliticaPrivacidade} />
          <Route path="/TermoUso" component={TermoUso} />
                    
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/PrimeiroCadastro"
            component={PrimeiroCadastro}
          />
          <Route
            path="/CadastrarCertificado/:id"
            component={CadastrarCertificado}
          />
          <Route
            path="/RecuperarNovaSenha/:id"
            component={RecuperarSenhaNova}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/cert"
            component={Certificado}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/CadastrarGrupoImposto"
            exact
            component={CadastrarGrupoImposto}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/CadastrarGrupoImposto/:id"
            component={CadastrarGrupoImposto}
          />
          <PrivateRoute isAuthenticated path="/produtos" component={produtos} />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/cadastrarProduto"
            exact
            component={cadastrarProduto}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/cadastrarProduto/:id"
            component={cadastrarProduto}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/Destinatarios"
            component={Destinatarios}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/CadastrarDestinatario"
            exact
            component={CadastrarDestinatario}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/CadastrarDestinatario/:id"
            component={CadastrarDestinatario}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/Notafiscal"
            component={Notafiscal}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/cadastrarNotaFiscal"
            exact
            component={CadastrarNotaFiscal}
          />
          
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/cadastrarNotaFiscal/:id"
            component={CadastrarNotaFiscal}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/cadastrarNotaFiscalCopia/:id/:acao"
            component={CadastrarNotaFiscal}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/pacote/"
            component={pacotes}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/comprar/:id"
            component={Comprar}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/historicoCompra"
            component={historicoCompra}
          />
        </Layout>
      </ConnectedRouter>
    );
  }
}

function mapStateToProps({ user }) {
  return user;
}

function mapDispatchToProps(dispatch) {
  return {
    logar: data => dispatch(loginUser(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
