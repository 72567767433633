import React from "react";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const TotalResumo = props => {
  const { resumoCalc } = props;
  return (
    <div className="card">
      <div className="card-header">Totalização</div>
      <div className="card-body">
        <div className="row justify-content-around">
          <div className="col-1 col-md-1 col-lg-1 ">
            <div className="form-group ">
              <label>Produtos</label>
              <div className="form-group ">{formatter.format(resumoCalc.produtos)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>Serviços</label>
              <div className="form-group ">{formatter.format(resumoCalc.servicos)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>Desconto</label>
              <div className="form-group ">{formatter.format(resumoCalc.descontos)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>IPI</label>
              <div className="form-group ">{formatter.format(resumoCalc.ipi)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>Seguro</label>
              <div className="form-group ">{formatter.format(resumoCalc.seguro)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>Frete</label>
              <div className="form-group ">{formatter.format(resumoCalc.frete)}</div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>Despesas</label>
              <div className="form-group ">--</div>
            </div>
          </div>
          <div className="col-2 col-md-3 col-lg-3">
            <div className="form-group ">
              <label>Valor Total</label>
              <div className="form-group ">{formatter.format(resumoCalc.valorTotal)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TotalResumo.propTypes = {};

export default TotalResumo;
