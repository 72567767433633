import React, { Component } from "react";

class ModalNotaProdutoSimplesNacionalServico extends Component {
  render() {
    const {
      CFOP,
      loading,
      ORIGEM,
      ICMS,
      IPI,
      PIS,
      COFINS,
      PRODUTO,
      NOMECAMPO,
      produtoKey
    } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>CFOP</label>
              <div className="form-group ">
                {!loading && (
                  <select
                    className="form-control"
                    name={"cfop"}
                    value={PRODUTO.notaFiscalProdutoImposto[0].cfop}
                    onChange={e =>
                      this.props.onAlterarDadosImposto(produtoKey, e)
                    }
                  >
                    {CFOP.map(c => (
                      <option key={c.cfop} value={c.cfop}>
                        {c.cfop} - {c.descricao}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ORIGEM</label>
              <div className="form-group ">
                {!loading && (
                  <select
                    className="form-control"
                    name={"idOrigem"}
                    value={PRODUTO.notaFiscalProdutoImposto[0].idOrigem}
                    onChange={e =>
                      this.props.onAlterarDadosImposto(produtoKey, e)
                    }
                  >
                    {ORIGEM.map(c => (
                      <option key={c.idOrigem} value={c.idOrigem}>
                        {c.descricao}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>PIS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"aliqPIS"}
                      placeholder="%Aliq"
                      onChange={e =>
                        this.props.onAlterarDadosImposto(produtoKey, e)
                      }
                      value={PRODUTO.notaFiscalProdutoImposto[0].aliqPIS}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        className="form-control"
                        name={"idPIS"}
                        value={PRODUTO.notaFiscalProdutoImposto[0].idPIS}
                        onChange={e =>
                          this.props.onAlterarDadosImposto(produtoKey, e)
                        }
                      >
                        {PIS.map(c => (
                          <option key={c.idPIS} value={c.idPIS}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>COFINS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"aliqCOFINS"}
                      placeholder="%Aliq"
                      onChange={e =>
                        this.props.onAlterarDadosImposto(produtoKey, e)
                      }
                      value={PRODUTO.notaFiscalProdutoImposto[0].aliqCOFINS}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        className="form-control"
                        name="idCOFINS"
                        onChange={e =>
                          this.props.onAlterarDadosImposto(produtoKey, e)
                        }
                        value={PRODUTO.notaFiscalProdutoImposto[0].idCOFINS}
                      >
                        {COFINS.map(c => (
                          <option key={c.idCofins} value={c.idCofins}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ISS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="%Aliq"
                      name="aliqISS"
                      onChange={e =>
                        this.props.onAlterarDadosImposto(produtoKey, e)
                      }
                      value={PRODUTO.notaFiscalProdutoImposto[0].aliqISS}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalNotaProdutoSimplesNacionalServico;
