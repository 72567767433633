import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ApiServiceProduto from "./../../utils/ApiServiceProduto";
import Dialog from '@material-ui/core/Dialog';

class ModalPesquisarProduto extends Component {
  state = {
    produtos: [],
    loading: false
  };

  keypressHandler = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  pesquisaNcm = e => {
    var api = new ApiServiceProduto();
    if (e.target.value !== "") {
      this.setState({ loading: true });

      api
        .getPesquisarProduto(e.target.value, this.props.idDestinatario)
        .then(r => {
          this.setState({ produtos: r.data, loading: false });
        });
    } else {
      this.setState({ NCM: [] });
    }
  };
  render() {
    const { produtos, loading } = this.state;
    return (
      <div>
        <Dialog          
          open={this.props.modal}
          className={this.props.className}
          backdrop={this.props.backdrop}
          size="lg"
        >
          <div className="modal-header">
            <input
              type="text"
              className="form-control input-block-level"
              placeholder="Pesquisar Serviço"
              onBlur={this.pesquisaNcm}
              onKeyPress={this.keypressHandler}
            />
          </div>

          <ModalBody
          style={{width: 600}}
          >
            {loading ? (
              <div>Carregando..</div>
            ) : (
              <div>
                {produtos.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Item</th>
                      </tr>
                    </thead>
                    <tbody>
                      {produtos.map(n => (
                        <tr key={n.idProduto}>
                          <td 
                            style={{ cursor: "pointer" }}
                            onClick={() => this.props.selecionarItem(n)}>
                            {n.produto.codigoProduto + ' - ' + n.produto.descricao}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>Use o campo acima para pesquisar.</div>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Dialog>
      </div>
    );
  }
}

export default ModalPesquisarProduto;
