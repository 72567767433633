import React, { Component } from "react";
import ApiServiceGrupoImposto from "./../../utils/ApiServiceGrupoImposto";
import ApiServiceProduto from "./../../utils/ApiServiceProduto";
import CurrencyInput from "react-currency-input";

class FormProduto extends Component {
  state = {
    GrupoImpostos: [],
    UnidadeMedida: []
  };

  componentDidMount() {
    var api = new ApiServiceGrupoImposto();
    var apiProduto = new ApiServiceProduto();

    api.getGrupoImposto().then(x => {
      this.setState({ GrupoImpostos: x.data });
    });

    apiProduto.getUnidadeMedida().then(x => {
      this.setState({ UnidadeMedida: x.data, loading: false });
    });
  }

  render() {
    const { GrupoImpostos, UnidadeMedida } = this.state;
    const { produto } = this.props;
    return (
      <form>
        <div className="card">
          <div className="card-header">Cadastar Produto/Serviço</div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="form-group ">
                  <label>Código do Item</label>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name="codigoProduto"
                      value={this.props.produto.codigoProduto}
                      onChange={e => {
                        this.props.onInputChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="form-group ">
                  <label>Descrição</label>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name="descricao"
                      value={produto.descricao}
                      onChange={e => {
                        this.props.onInputChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="form-group ">
                  <label>Grupo de Impostos</label>
                  <div className="form-group ">
                    <select
                      className="form-control"
                      name="idGrupoImposto"
                      value={produto.idGrupoImposto}
                      onChange={e => {
                        this.props.onInputChange(e);
                      }}
                    >
                      <option value="0" />
                      {GrupoImpostos.map(g => (
                        <option key={g.idGrupoImposto} value={g.idGrupoImposto}>
                          {g.descricao}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="form-group ">
                  <label>Valor Unitário</label>
                  <div className="form-group ">
                    <CurrencyInput
                      value={produto.valorUnitarioFormatado}
                      decimalSeparator=","
                      thousandSeparator="."
                      name="valorUnitario"
                      className="form-control"
                      onChangeEvent={this.props.onCurrenyChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="form-group ">
                  <label>Código de Barras</label>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name="codBarras"
                      value={produto.codBarras}
                      onChange={e => {
                        this.props.onInputChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="form-group ">
                  <label>Unidade Comercial</label>
                  <div className="form-group ">
                    <select
                      className="form-control"
                      name="idUnidadeMedida"
                      value={produto.idUnidadeMedida}
                      onChange={e => {
                        this.props.onInputChange(e);
                      }}
                    >
                      <option />
                      {UnidadeMedida.map(u => (
                        <option
                          key={u.idUnidadeMedida}
                          value={u.idUnidadeMedida}
                        >
                          {u.sigla}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="form-group ">
                  <label>NCM</label>
                  <div className="form-group ">
                    <input
                      type="hidden"
                      name="idNCM"
                      value={this.props.idNCM}
                    />
                    <input
                      type="text"
                      className="form-control"
                      readOnly={true}
                      onClick={this.props.toggle}
                      value={this.props.NCMDesc}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group" style={{ display: "flex" }}>
          <button
            type="button"
            className="btn btn-primary mr-1"
            onClick={this.props.salvar}
            style={{ marginTop: 10, marginLeft: "auto" }}
          >
            Salvar
          </button>
        </div>
      </form>
    );
  }
}

export default FormProduto;
