import React, { Component } from "react";

export class TermoUso extends Component {
    render() {
      return (
        <div>
          <h3><a name="termo-condicoes-uso">TERMOS E CONDIÇÕES DE USO</a></h3>
          <p><b>INTRODUÇÃO</b></p>
          <p>Esse termo de uso e condições gerais de subscrição de uso de software é um acordo legal entre o CONTRATANTE (pessoa física ou jurídica) (o CONTRATANTE) e a WALDEN TECNOLOGIA LTDA pessoa jurídica de direito privado, inscrita no CNPJ sob n. 30.715.055/0001-49, com sede na QUADRA EQNL 6/8 BLOCO A SALA 203, CEP 72155511, Brasília, Distrito Federal – Brasil, (a CONTRATADO) para uso do programa de computador denominado Web NFe, disponibilizado neste ato pelo “CONTRATADO” (o “SOFTWARE”) por meio do site “nfe.waldentecnologia.com.br” (“Site”) ou subdomínios, pelo determinado pelo CONTRATANTE no ato do licenciamento do SOFTWARE, compreendendo o programa de computador e podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente ou a título de teste, o CONTRATANTE estará vinculado aos termos deste contrato, concordando com suas disposições, principalmente com relação ao CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do CONTRATANTE pelo “CONTRATADO”, necessárias para a integral execução das funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância com os termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida pelo CONTRATANTE.</p>
          <p><b>&nbsp;</b></p>
          <p><b>SEÇÃO 1 - DA PROPRIEDADE INTELECTUAL</b></p>
          <p>O CONTRATANTE não adquire, pelo presente instrumento ou pela utilização do SOFTWARE, nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo disponibilizado no Site, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material, sobre ou relacionados ao SOFTWARE ou nenhuma parte dele. O CONTRATANTE também não adquire nenhum direito sobre ou relacionado ao SOFTWARE ou qualquer componente dele, além dos direitos expressamente CONTRATANTEs ao CONTRATANTE sob o presente Contrato ou em qualquer outro contrato mutuamente acordado por escrito entre o CONTRATANTE e O “CONTRATADO”. Quaisquer direitos não expressamente concedidos sob o presente instrumento são reservados.</p>
          <p>Também será de propriedade exclusiva do “CONTRATADO” ou está devidamente CONTRATANTE, todo o conteúdo disponibilizado no Site, incluindo, mas não se limitando a textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 2 - DECLARAÇÕES DO CONTRATANTE</b></p>
          <p>O CONTRATANTE declara ter pleno conhecimento dos direitos e obrigações decorrentes do presente Contrato, constituindo este instrumento o acordo completo entre as partes. Declara, ainda, ter lido, compreendido e aceito todos os seus termos e condições.</p>
          <p>O CONTRATANTE declara que foi devidamente informado da política de confidencialidade e ambientes de proteção de informações confidenciais, dados pessoais e registros de acesso do “CONTRATADO”, consentindo livre e expressamente às ações de coleta, uso, armazenamento e tratamento das referidas informações e dados.</p>
          <p>O CONTRATANTE declara estar ciente de que as operações que correspondam à aceitação do presente Contrato, de determinadas condições e opções, bem como eventual rescisão do presente instrumento e demais alterações, serão registradas nos bancos de dados do “CONTRATADO”, juntamente com a data e hora em que foram realizadas pelo CONTRATANTE, podendo tais informações serem utilizadas como prova pelas partes, independentemente do cumprimento de qualquer outra formalidade.</p>
          <p>O CONTRATANTE declara que está ciente de que, em qualquer hipótese, deve atender rigorosamente a legislação, especialmente no que se refere às suas obrigações tributárias, fiscais, trabalhistas e previdenciárias, seja de natureza principal ou acessória, bem como quaisquer outras.</p>
          <p>O CONTRATANTE poderá acessar aplicativo de terceiros através de API’s de conexão com a finalidade de compartilhar suas Informações. O CONTRATANTE compreende e concorda que tais Informações poderão ser atribuídas à sua conta no Aplicativo de Terceiros e poderão ser publicadas em tal serviço. Embora essas informações possam ser automaticamente compartilhadas por definição. O Aplicativo de Terceiros também poderá fornecer controles semelhantes, porém o acesso deverá ser feito diretamente através do Aplicativo de Terceiros, objetivando suspender tal acesso.</p>
          <p>O CONTRATANTE compreende e concorda que o uso de suas Informações por um Aplicativo de Terceiros coletadas junto ao mesmo (ou conforme autorizado pelo CONTRATANTE) é regido pelas políticas de privacidade do Aplicativo de Terceiros e por suas configurações no respectivo serviço, e o uso de tais informações por parte do CONTRATANTE é regido por esse Termo de Uso e pelas configurações da sua conta no SOFTWARE do “CONTRATADO”. O “CONTRATADO” não será responsável, sob qualquer hipótese, pelo tratamento dado pelas informações por um Aplicativo Terceiro.</p>
          <p>O CONTRATANTE declara ainda que está ciente de que para usufruir de algumas das funcionalidades do SOFTWARE, em especial, assinatura do XML para envio de notas fiscais e captura automática de XML no SEFAZ, deverá disponibilizar à “CONTRATADO” as INFORMAÇÕES DE CONTA e Certificado Digital, assim como sua senha, para que o SOFTWARE, de maneira automatizada, colete informações diretamente nos sites e/ou outros meios virtuais disponibilizados pelas instituições financeiras, com as quais mantenha relacionamento, agindo O “CONTRATADO”, neste caso, como representante e mandatária do CONTRATANTE nestes atos.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 3 - LICENÇA DE USO DO SOFTWARE</b></p>
          <p>Sujeito aos termos e condições aqui estabelecidos, este Contrato concede ao CONTRATANTE uma licença revogável, não exclusiva e intransferível para uso do SOFTWARE. O CONTRATANTE não poderá utilizar e nem permitir que o SOFTWARE seja utilizado para outra finalidade que não seja o processamento de suas informações ou de pessoas jurídicas indicadas pelo CONTRATANTE no ato do cadastramento, exceto caso o CONTRATANTE seja empresa de contabilidade que utiliza o SOFTWARE para processar informações de seus clientes, observados os limites estabelecidos neste CONTRATO. Esta licença não implica a capacidade de acessar outros softwares além daqueles originalmente localizados no SOFTWARE. Em nenhuma hipótese o CONTRATANTE terá acesso ao código fonte do SOFTWARE ora CONTRATANTE, por este se tratar de propriedade intelectual do “CONTRATADO”.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 4- DAS RESTRIÇÕES</b></p>
          <p>Em hipótese alguma é permitido ao CONTRATANTE ou a terceiros, de forma geral:</p>
          <p>Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o SOFTWARE objeto deste CONTRATO, assim como seus módulos, partes, manuais ou quaisquer informações a ele relativas;</p>
          <p>Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito existente no SOFTWARE e na documentação;</p>
          <p>Praticar engenharia reversa, descompilação ou desmontagem do SOFTWARE.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 5 - DO PRAZO</b></p>
          <p>O presente CONTRATO entra em vigor na data de seu aceite pelo CONTRATANTE e vigorará pelo prazo do Plano “CONTRATADO” pelo CONTRATANTE. Este CONTRATO será automaticamente renovado por iguais períodos caso o CONTRATANTE não se manifeste expressamente em contrário. Este CONTRATO poderá ser rescindido conforme estabelecido abaixo nesse instrumento.</p>
          <p>Ele também pode ser alterado sem aviso prévio, sendo sua copia enviado por email. Caso o CONTATRATANTE não entre em contato, em até 5 dias uteis, será entendido como aceito os termos.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 6- DA REMUNERAÇÃO E FORMA DE PAGAMENTO</b></p>
          <p>O CONTRATANTE deverá pagar ao “CONTRATADO” o valor do plano de licenciamento escolhido e de acordo com a periodicidade definida entre as opções de pagamento disponibilizadas ao CONTRATANTE no ato da contratação.</p>
          <p>Caso o CONTRATANTE, no decorrer da vigência do presente instrumento, opte por outro plano de licenciamento, os valores serão alterados de acordo com o respectivo plano escolhido.</p>
          <p>A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará na rescisão automática do CONTRATO, mas causará a suspensão do uso do CONTRATANTE ao SOFTWARE até que as pendências financeiras tenham sido regularizadas. O acesso ao SOFTWARE somente será restabelecido após a identificação pelo “CONTRATADO” do pagamento integral de todos os valores devidos enquanto o mesmo esteve suspenso. A identificação poderá ocorrer em até dois dias úteis após a data de pagamento pelo CONTRATANTE. Caso o CONTRATANTE não resolva a pendência financeira no prazo de 90 (noventa) dias contados do vencimento do valor não pago, O “CONTRATADO” se reserva o direito de rescindir o presente CONTRATO e apagar de forma definitiva e irrecuperável todas as informações do CONTRATANTE que por ventura estejam armazenadas no SOFTWARE.</p>
          <p>Os valores dos planos de licenciamento estabelecidos no ato do licenciamento do SOFTWARE poderão ser atualizados sem qualquer parâmetro, podendo aumentar ou diminuir.</p>
          <p>Eventualmente poderão ocorrer alterações nos preços dos planos e adicionais “CONTRATADO”s.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 7- RESTITUIÇÃO DAS INFORMAÇÕES</b></p>
          <p>Suspenso o uso do CONTRATANTE ao SOFTWARE, O “CONTRATADO” manterá as informações do CONTRATANTE armazenadas no SOFTWARE pelo período de 90 (noventa) dias, contados da suspensão de acesso. Durante este período, O “CONTRATADO” tornará as informações do CONTRATANTE disponíveis para serem extraídas quando solicitada via central de atendimento em formato a ser determinado pelo “CONTRATADO”.</p>
          <p>Conforme descrito na 6 cláusula acima, passados 90 (noventa) dias da suspensão do acesso do CONTRATANTE ao SOFTWARE, todas as INFORMAÇÕES do CONTRATANTE, incluindo as INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA e INFORMAÇÕES FINANCEIRAS, em poder do “CONTRATADO” poderão ser excluídos permanentemente do banco de dados do “CONTRATADO”, independentemente de terem sido extraídas ou não pelo CONTRATANTE.</p>
          <p>Não obstante o disposto acima, as informações referentes à data e hora de acesso e ao endereço de protocolo de internet utilizado pelo CONTRATANTE para acessar o Site e o SOFTWARE poderão permanecer armazenadas pelo “CONTRATADO” por 6 (meses) a contar da data de cada acesso realizado, independentemente do término da relação jurídica e comercial entre O “CONTRATADO” e o CONTRATANTE, em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por um período maior de tempo mediante ordem judicial.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 8- DAS OBRIGAÇÕES DO LICENCIADO</b></p>
          <p>Obriga-se o CONTRATANTE a:</p>
          <p>Manter pessoal treinado para a operação do SOFTWARE e para a comunicação com O “CONTRATADO” e prover, sempre que ocorrerem quaisquer problemas com o SOFTWARE, toda a documentação, relatórios e demais informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos;</p>
          <p>Manter, às suas expensas, linha de telecomunicação, modem, software de comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com O “CONTRATADO”;</p>
          <p>Responder pelas INFORMAÇÕES inseridas no SOFTWARE, pelo cadastramento, permissões, senhas e modo de utilização de seus usuários, incluindo, mas não se limitando, às informações fornecidas no que diz respeito aos métodos de pagamento (boletos), números de contas bancárias e a informações financeiras disponibilizadas às autoridades fiscais por meio do SOFTWARE no que diz respeito à emissão de notas fiscais. O “CONTRATADO” em hipótese alguma será responsável pelo conteúdo (INFORMAÇÕES, senhas, cópias de informações, etc.) incluído no SOFTWARE, não sendo, portanto, estas INFORMAÇÕES revisadas em momento algum. A responsabilidade pelas INFORMAÇÕES inseridas no SOFTWARE é sempre do CONTRATANTE;</p>
          <p>Certificar-se de que não está proibido por determinação legal e/ou contratual de passar INFORMAÇÕES, INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS, bem como quaisquer outros dados à “CONTRATADO”, necessários para a execução do serviço oferecido por este CONTRATO;</p>
          <p>Não utilizar o SOFTWARE de qualquer forma que possa implicar em ato ilícito, infração, violação de direitos ou danos à “CONTRATADO” ou terceiros, incluindo, mas não se limitando ao uso para invasão de dispositivo informático com o objetivo de obter, adulterar ou destruir dados ou informações sem a autorização expressa do titular de tais dados ou do dispositivo ou servidor nos quais estes estejam armazenados;</p>
          <p>Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de troia ou qualquer outro programa que possa contaminar, destruir ou interferir no bom funcionamento do SOFTWARE;</p>
          <p>Informar O “CONTRATADO” sempre que houver qualquer alteração das INFORMAÇÕES fornecidas à “CONTRATADO” e que possam impedir, limitar ou prejudicar o acesso do “CONTRATADO” às INFORMAÇÕES necessárias para a execução das funcionalidades ofertadas pelo SOFTWARE;</p>
          <p>Atender rigorosamente a legislação brasileira e toda obrigação legal imposta e/ou decorrente de sua atividade e em razão da utilização deste SOFTWARE;</p>
          <p>Caso o CONTRATANTE acredite que seu login e senha de acesso ao SOFTWARE tenham sido roubados ou sejam de conhecimento de outras pessoas, por qualquer razão, o CONTRATANTE deverá imediatamente comunicar tal fato à “CONTRATADO”, sem prejuízo da alteração da sua senha imediatamente, por meio do SOFTWARE.</p>
          <p>Quando o CONTRATANTE quiser criar outros acessos à sua base, como liberar acesso à contabilidade terá que entrar em contato com o suporte via central de atendimento.</p>
          <p>Para a utilização dos recursos oferecidos pelo SOFTWARE, no que diz respeito a emissão, inutilização, correção, contingência, cancelamento de documentos fiscais, detalhamento das regiões atendidas, versões do SOFTWARE, backup das informações implementadas, entre outros, o CONTRATANTE deve verificar e se manter informado, através da nossa central de atendimento. Uma vez aceito os termos deste CONTRATO, isso garante também a aceitação das especificações do produto conforme definido em nosso site.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 9- DAS OBRIGAÇÕES DO LICENCIANTE</b></p>
          <p>Obriga-se O “CONTRATADO” a:</p>
          <p>O “CONTRATADO” garante ao CONTRATANTE que o SOFTWARE deverá funcionar regularmente, se respeitadas as condições de uso definidas na documentação. Na ocorrência de falhas de programação (“bugs”), O “CONTRATADO” obrigar-se-á a corrigir tais falhas, podendo a seu critério substituir a cópia dos Programas instalados com falhas por cópias corrigidas;</p>
          <p>Fornecer, ato contínuo ao aceite deste CONTRATO, acesso ao SOFTWARE durante a vigência deste CONTRATO;</p>
          <p>Suspender o acesso ao SOFTWARE do CONTRATANTE que esteja desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor;</p>
          <p>Alterar as especificações e/ou características do SOFTWARE CONTRATANTEs para a melhoria e/ou correções de erros;</p>
          <p>Disponibilizar acesso aos serviços de suporte compreendido de segunda a sexta-feira, das 08h00 às 18h00 (horário de Brasília), sem intervalo, via central de atendimento para esclarecimento de dúvidas de ordem não técnica diretamente relacionadas ao SOFTWARE (conforme o plano “CONTRATADO”).</p>
          <p>Manter as INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS do CONTRATANTE, bem como seus registros de acesso, em sigilo, sendo que as referidas INFORMAÇÕES serão armazenadas em ambiente seguro, sendo respeitadas a intimidade, a vida privada, a honra e a imagem do CONTRATANTE, em conformidade com as disposições da Lei nº 12.965/2014.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 10- INSEÇÃO DE RESPONSABILIDADE DO LICENCIANTE</b></p>
          <p>O “CONTRATADO” não se responsabiliza:</p>
          <p>Por falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa do “CONTRATADO”;</p>
          <p>Pelo cumprimento dos prazos legais do CONTRATANTE para a entrega de documentos fiscais ou pagamentos de impostos;</p>
          <p>Pelos danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base nas informações fornecidas pelo SOFTWARE;</p>
          <p>Por problemas definidos como “caso fortuito” ou “força maior”, contemplados pelo Art. 393 do Código Civil Brasileiro;</p>
          <p>Por eventuais problemas oriundos de ações de terceiros que possam interferir na qualidade do serviço;</p>
          <p>Por danos causados a terceiros em razão de conteúdo gerado pelo “CONTRATADO” através de SOFTWARE;</p>
          <p>Por revisar as INFORMAÇÕES DE CONTA fornecidas pelo CONTRATANTE, bem como as INFORMAÇÕES FINANCEIRAS obtidas junto aos sites e/ou meios virtuais das instituições financeiras, seja no que tange à precisão dos dados, seja quanto à legalidade ou ameaça de violação em função do fornecimento destas informações;</p>
          <p>Por quaisquer produtos e/ou serviços oferecidos por meio dos sites e/ou meios virtuais das instituições financeiras, ainda que de maneira solidária ou subsidiária;</p>
          <p>Por eventuais infrações legais cometidas pelo CONTRATANTE, de ordem fiscal, tributária, trabalhista, previdenciária, criminal, ou qualquer outra;</p>
          <p>Pela geração e envio de obrigações fiscais acessórias, cabendo este procedimento somente ao CONTRATANTE.</p>
          <p>O acesso às INFORMAÇÕES DE CONTA e às INFORMAÇÕES FINANCEIRAS do CONTRATANTE dependem de serviços prestados pelas instituições financeiras. Sendo assim, O “CONTRATADO” não assume qualquer responsabilidade quanto à qualidade, precisão, pontualidade, entrega ou falha na obtenção das referidas INFORMAÇÕES junto aos sites e/ou meios virtuais das instituições financeiras.</p>
          <p>Conforme o uso do SOFTWARE pelo CONTRATANTE, este pode sugerir naturezas de operações ou os impostos de acordo com o histórico de uso. O “CONTRATADO” não se responsabiliza pelo aceite e pelo preenchimento incorreto dessas informações, cabendo somente ao CONTRATANTE a checagem correta de suas próprias informações inseridas.</p>
          <p>O “CONTRATADO” adota as medidas de segurança adequadas de acordo com os padrões de mercado para a proteção das INFORMAÇÕES do CONTRATANTE armazenadas no SOFTWARE, assim como para o acesso às INFORMAÇÕES FINANCEIRAS do CONTRATANTE. Contudo, o CONTRATANTE reconhece que nenhum sistema, servidor ou software está absolutamente imune a ataques e/ou invasões de hackers e outros agentes maliciosos, não sendo O “CONTRATADO” responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de INFORMAÇÕES resultantes de ataques que O “CONTRATADO” não poderia razoavelmente evitar por meio dos referidos padrões de segurança.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 11- DA RETOMADA DOS SOFTWARE</b></p>
          <p>O “CONTRATADO” se reserva o direito de cancelar imediatamente o acesso do CONTRATANTE ao SOFTWARE nos casos em que o CONTRATANTE usar o SOFTWARE de forma diversa daquela estipulada no presente instrumento.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 12- DAS GARANTIAS LIMITADAS</b></p>
          <p>Na extensão máxima permitida pela lei em vigor, o SOFTWARE é fornecido “no estado em que se encontra” e “conforme a disponibilidade”, com todas as falhas e sem garantia de qualquer espécie.</p>
          <p>O “CONTRATADO” não garante que as funções contidas no SOFTWARE atendam às suas necessidades, que a operação do SOFTWARE será ininterrupta ou livre de erros, que qualquer serviço continuará disponível, que os defeitos no SOFTWARE serão corrigidos ou que o SOFTWARE será compatível ou funcione com qualquer SOFTWARE, aplicações ou serviços de terceiros.</p>
          <p>Além disso, o CONTRATANTE reconhece que o SOFTWARE não deve ser utilizado ou não é adequado para ser utilizado em situações ou ambientes nos quais a falha ou atrasos de, os erros ou inexatidões de conteúdo, dados ou informações fornecidas pelo SOFTWARE possam levar à morte, danos pessoais, ou danos físicos ou ambientais graves, incluindo, mas não se limitando, à operação de instalações nucleares, sistemas de navegação ou de comunicação aérea, controle de tráfego aéreo, sistemas de suporte vital ou de armas.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 14- LIMITAÇÃO DE RESPONSABILIDADE</b></p>
          <p>Em nenhum caso O “CONTRATADO” será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o SOFTWARE, por qualquer outro motivo. Sob nenhuma circunstância a responsabilidade integral do “CONTRATADO” com relação ao CONTRATANTE por todos os danos excederá a quantia correspondente ao último plano de licenciamento pago pelo CONTRATANTE ao “CONTRATADO” pela obtenção da presente licença de SOFTWARE.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 15- CONSENTIMENTO LIVRE, EXPRESSO E INFORMADO PARA ACESSO A INFORMAÇÕES CONFIDENCIAIS E DADOS PESSOAIS</b></p>
          <p>O CONTRATANTE, ao aceitar utilizar o SOFTWARE, além de aceitar integralmente este “CONTRATO”, também consente, livre e expressamente, que O “CONTRATADO” colete, use, armazene e faça o tratamento de suas INFORMAÇÕES, incluindo seus dados pessoais, financeiros, bancários, de conta, os quais serão necessários para que o serviço ofertado seja prestado em sua integralidade.</p>
          <p>Para tanto, o CONTRATANTE consente, livre e expressamente, em fornecer os dados que permitam o acesso as INFORMAÇÕES necessárias para que o SOFTWARE execute todas as funções para as quais foi projetado.</p>
          <p>Ainda, o CONTRATANTE declara e reconhece que para a prestação dos serviços e funcionalidades de integração a rede bancária ofertadas pelo SOFTWARE, O “CONTRATADO” acessa INFORMAÇÕES FINANCEIRAS diretamente no site e/ou outros meios virtuais das instituições financeiras, sem fazer qualquer emulação de medidas de segurança, utilizando-se apenas das INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA, bem como outras eventualmente necessárias, fornecidas pelo CONTRATANTE, conforme autorizado.</p>
          <p>O CONTRATANTE, por meio deste CONTRATO e fornecendo as INFORMAÇÕES DE CONTA, autoriza e consente expressamente que O “CONTRATADO” acesse suas INFORMAÇÕES DE CONTA e INFORMAÇÕES FINANCEIRAS na qualidade de mandatária.</p>
          <p>O “CONTRATADO” declara que todas as INFORMAÇÕES DE CONTA serão utilizadas única e exclusivamente para a visualização das informações financeiras nos sites e/ou meios virtuais das instituições financeiras, sendo absolutamente vedada O “CONTRATADO” a realização de quaisquer transações.</p>
          <p>O CONTRATANTE consente que, ao acessar o site do “CONTRATADO”, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser usadas para orientar o próprio CONTRATANTE e melhorar os serviços ofertados.</p>
          <p>O CONTRATANTE consente livre e expressamente que suas INFORMAÇÕES poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle do “CONTRATADO”. O “CONTRATADO”, contudo, compromete-se, nestes casos, a informar o CONTRATANTE.</p>
          <p>O CONTRATANTE consente livre e expressamente que O “CONTRATADO” utilize cookies apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil do CONTRATANTE. O “CONTRATADO” garante que estas informações coletadas por meio de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos dos expressamente previstos neste CONTRATO, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.</p>
          <p>15.1 Válido somente para CONTRATANTES conectados a um contador</p>
          <p>Com o intuito de facilitar a comunicação e o tráfego de informações entre o CONTRATANTE e seu contador, diminuir entraves burocráticos e margens de erro na entrega das informações contábeis, a plataforma do “CONTRATADO” permite a liberação pelo CONTRATANTE de acesso direto ao seu sistema financeiro para seu contador, permite que sejam exportadas informações dos lançamentos financeiros para integrar com o sistema contábil que utiliza.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 16- DA RECISÃO</b></p>
          <p>O CONTRATANTE não poderá solicitar devolução total dos valores pagos.</p>
          <p>No plano de assinatura mensal, trimestral e semestral, caso o CONTRATANTE decida rescindir este CONTRATO antes do término do prazo “CONTRATADO”, o “CONTRATADO” não restituirá ao CONTRATANTE o saldo restante do plano de licenciamento “CONTRATADO”.</p>
          <p>Na compra avulsa das notas, caso o CONTRATANTE deseje rescindir este contrato o valor não utilizado não será devolvido. Ficando ativo no SITE para o mesmo utilizá-lo quando quiser.</p>
          <p>O “CONTRATADO” poderá rescindir o CONTRATO a qualquer momento em caso de violação pelo CONTRATANTE dos termos e condições ora acordados, ou em caso de atraso de pagamento não sanado no prazo de 90 (noventa) dias, conforme cláusula 6 acima.</p>
          <p>No caso de rescisão do presente contrato, os dados pessoais, bancários, financeiros e demais informações do CONTRATANTE ficarão disponíveis conforme cláusula 7 deste termo, sendo excluídos permanentemente após 90 (noventa) dias da data da rescisão.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 17- DAS DISPOSIÇÕES LEGAIS</b></p>
          <p>O CONTRATANTE não poderá prestar serviços a terceiros utilizando o SOFTWARE do “CONTRATADO” sem autorização prévia e expressa do “CONTRATADO”. A autorização de uso do SOFTWARE é fornecida por CNPJ. Desta forma, o SOFTWARE não pode operar sob o regime de multiempresa, necessitando para cada CNPJ e Inscrição Estadual uma licença específica;</p>
          <p>Caso o CONTRATANTE venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, quer seja do dicionário de dados quer seja do programa, será considerado como parte do SOFTWARE fornecido pelo “CONTRATADO”, ficando, portanto, sua propriedade incorporada pelo “CONTRATADO” e seu uso condicionado a estas cláusulas contratuais;</p>
          <p>Este CONTRATO obriga as partes e seus sucessores e somente o CONTRATANTE possui licença não exclusiva para a utilização do SOFTWARE, sendo-lhe, entretanto, vedado transferir os direitos e obrigações acordados por este instrumento. Tal limitação, no entanto, não atinge O “CONTRATADO”, que poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao presente CONTRATO;</p>
          <p>A tolerância de uma parte para com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas neste instrumento não implicará em novação ou renúncia de direito. A parte tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento deste instrumento;</p>
          <p>Não constitui causa de rescisão contratual o não cumprimento das obrigações aqui assumidas em decorrência de fatos que independam da vontade das partes, tais como os que configuram o caso fortuito ou força maior, conforme previsto no artigo 393 do Código Civil Brasileiro;</p>
          <p>Se qualquer disposição deste CONTRATO for considerada nula, anulável, inválida ou inoperante, nenhuma outra disposição deste CONTRATO será afetada como consequência disso e, portanto, as disposições restantes deste CONTRATO permanecerão em pleno vigor e efeito como se tal disposição nula, anulável, inválida ou inoperante não estivesse contida neste CONTRATO;</p>
          <p>O CONTRATANTE concorda que O “CONTRATADO” possa divulgar a celebração deste instrumento para fins comerciais, fazendo menção ao nome e à marca do CONTRATANTE em campanhas comerciais, podendo, inclusive, divulgar mensagens enviadas de forma escrita ou oral, por telefone, para uso em sites, jornais, revistas e outras campanhas, enquanto vigorar o presente CONTRATO. O CONTRATANTE aceita, ainda, receber comunicações via correio eletrônico sobre treinamentos, parcerias e campanhas relacionadas ao SOFTWARE;</p>
          <p>Neste ato, o “CONTRATADO” expressamente autoriza o CONTRATANTE a colher e utilizar seus dados técnicos e operacionais presentes no SOFTWARE, para fins de estudos e melhorias no SOFTWARE;</p>
          <p>O “CONTRATADO” poderá, ao seu exclusivo critério, a qualquer tempo e sem a necessidade de comunicação prévia ao CONTRATANTE:</p>
          <p>a. Encerrar, modificar ou suspender, total ou parcialmente, o acesso do CONTRATANTE ao SOFTWARE, quando referido acesso ou cadastro estiver em violação das condições estabelecidas neste CONTRATO;</p>
          <p>b. Excluir, total ou parcialmente, as informações cadastradas pelo CONTRATANTE que não estejam em consonância com as disposições deste CONTRATO;</p>
          <p>c. Acrescentar, excluir ou modificar o Conteúdo oferecido no site;</p>
          <p>d. Alterar quaisquer termos e condições deste CONTRATO mediante simples comunicação ao CONTRATANTE.</p>
          <p>O “CONTRATADO” ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades do SOFTWARE, mediante comunicação prévia por escrito ao CONTRATANTE, com antecedência mínima de 15 (quinze) dias, disponibilizando formas e alternativas de extrair do Site as informações, salvo nas hipóteses de caso fortuito ou força maior.</p>
          <p>O “CONTRATADO” poderá, por meio de comunicação ao endereço eletrônico indicado pelo CONTRATANTE em seu cadastro ou por meio de aviso no Site, definir preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita, sendo a utilização destes, após o referido aviso, considerada como concordância do CONTRATANTE com a cobrança de tais preços.</p>
          <p>Fica certo e entendido pelo CONTRATANTE que somente a pessoa cadastrada pelo próprio CONTRATANTE como administradora de conta poderá solicitar que as informações do CONTRATANTE inseridas no Software sejam apagadas. Ainda, o CONTRATANTE declara sua ciência de que uma vez apagadas, estas não poderão mais ser recuperadas, ficando O “CONTRATADO” isenta de qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste procedimento solicitado pelo CONTRATANTE.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 18- DA LEI APLICAVEL</b></p>
          <p>Este CONTRATO será regido, interpretado e se sujeitará às leis brasileiras e, o CONTRATANTE e O “CONTRATADO” desde logo elegem, de forma irrevogável e irretratável, o foro da Comarca da Cidade de Brasília, Distrito Federal, para dirimir quaisquer dúvidas ou controvérsias oriundas deste CONTRATO, com a exclusão de qualquer outro, por mais privilegiado que seja.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 19- DAS DEFINIÇÕES</b></p>
          <p>Os termos utilizados neste instrumento deverão ser interpretados e usados conforme definições abaixo, seja no singular ou plural:</p>
          <p>INFORMAÇÕES DE CONTA: informações e dados relativos a contas correntes, contas poupanças, cartões de crédito, incluindo logins, senhas e demais informações necessárias para acessar, coletar, armazenar, usar e tratar as informações obtidas nos sites das instituições financeiras.</p>
          <p>INFORMAÇÕES FINANCEIRAS: informações fornecidas pelo CONTRATANTE ou coletadas diretamente dos sites e dispositivos da instituição financeira por meio do SOFTWARE com os dados do CONTRATANTE, tais como recebimentos, pagamentos, investimentos, etc.</p>
          <p>INFORMAÇÕES PESSOAIS: qualquer informação disponibilizada pelo CONTRATANTE que o identifique, tais como nome, endereço, data de nascimento, número de telefone, fax, endereço eletrônico, número de documentos, etc.</p>
          <p>INFORMAÇÕES: entende-se todas as informações do CONTRATANTE relacionadas a INFORMAÇÕES PESSOAIS, INFORMAÇÕES FINANCEIRAS e INFORMAÇÕES DE CONTA.</p>
          <p>CONTRATANTE: pessoa física ou jurídica, com plena capacidade de contratar, que acessa o SOFTWARE do “CONTRATADO” por meio do site, realizando seu cadastro, aceitando os termos do presente CONTRATO e usufruindo das funcionalidades oferecidos.</p>
          <p>SOFTWARE: software de propriedade exclusiva do “CONTRATADO”, cujas funcionalidades e serviços estão disponibilizados pelo site, por meio do qual as INFORMAÇÕES FINANCEIRAS do CONTRATANTE serão fornecidas diretamente por ele ou coletadas diretamente nos sites das instituições financeiras de maneira automatizada.</p>
          <p>API: Application Programming Interface que em português significa Interface de Programação de Aplicativos. É um conjunto de rotinas e padrões de programação para acesso a um aplicativo de software ou plataforma baseado na Web.</p>
        </div>
      );
    }
  }