import React, { Component } from 'react';
import ApiServiceCertificado from "../utils/ApiServiceCertificado";
import ApiService from "./../utils/ApiService";

export default class Home extends Component {

  state = {
    certificado: null,
    config: {
      idUsuario: "",
      ambienteProducao: false,
      nFe: false,
      nfCe: false,
      certificadoByte: "",
      naturezaOperacao: "",
      informacoesComplementares: "",
      numeroNFe: 0,
      serieNFe: 0,
      numeroNotaProducaoNFe: 0,
      serieNotaProducaoNFe: 0,
      numeroNFCe: 0,
      serieNFCe: 0,
      idCSC: 0,
      csc: 0
    },
    qtdGrupoImposto: 0,
    qtdProduto: 0,
    qtdDestinatario: 0,
    cadastroIncompleto: false,
    cadastroCompleto: {
      fezUploadCertificado: false,
      certificadoVencido: true,
      informouDadosEmpresa: false,
      temProdutoCadastro: false,
      temGrupoDeImposto: false,
      dataVencimentoAssinatura: ""
    }
  };
  
  componentDidMount() {
    this.carregarDadosCertificado();
    this.carregarConfiguracoes();
  }

  carregarDadosCertificado() {
    var api = new ApiServiceCertificado();
    api.getCertificado().then(c => {
      if (c.cnpj) {
        this.setState({ loading: false, certificado: c });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  carregarConfiguracoes() {
    var api = new ApiService();
    api.obterConfiguracoes().then(x => {
      this.setState({ config: x });
    });

    api.obterStatusCadastro().then(x => {
      this.setState({ cadastroCompleto: x });

      if (x.certificadoVencido || !x.fezUploadCertificado || !x.informouDadosEmpresa || !x.temGrupoDeImposto || !x.temProdutoCadastro)
      {
        this.setState({ cadastroIncompleto: true });
      }
    });
  }
    
  render() {
    const { certificado, config, cadastroCompleto, cadastroIncompleto} = this.state;
    var dtExpiracao = new Date(cadastroCompleto.dataVencimentoAssinatura);
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-12">
              <div>
                <label><b><font color="red">AVISO IMPORTANTE:</font></b></label>
                <p><font color="red">Prezado(a) usuário,<br />
                    Informamos que a partir do dia <b>31/05/2023</b>, o nosso sistema de emissão de notas fiscais de serviço será descontinuado. Essa decisão foi tomada em virtude da disponibilização pelo governo do distrito federal da NFS-e (Nota Fiscal de Serviço Eletrônica), um modelo padrão e gratuito para emissão de nota fiscal de prestação de serviços.
                    Para emitir a NFS-e, você pode acessar o Portal da <a href='https://df.issnetonline.com.br/'>Nota Fiscal de Serviço Eletrônica</a> (https://df.issnetonline.com.br/). Você precisará apenas do seu CPF e senha ou certificado digital para acessar o sistema.
                    Agradecemos a sua confiança e preferência pelo nosso sistema até então e nos colocamos à disposição para esclarecer qualquer dúvida.<br /><br />

                    Atenciosamente,<br /><br />

                    Walden Tecnologia</font></p>
                </div>
                <div>
                  <label><b>Bem-vindo ao WEB NFe, sistema emissor de nota fiscal eletrônica.</b></label>
                </div>
                {cadastroIncompleto && (
                    <div>
                      <label>Para a emissão de nota fiscal eletrônica pelo WEB NFe, é necessário realizar os seguintes passos: </label>
                      <ul>
                        <li>Cadastrar o certificado digital A1 no meu "Certificado Digital"</li>
                        <li>Confirmar e salvar os dados cadastrais da empresa que irá emitir as notas fiscais no meu "Empresa"</li>
                        <li>Informar os dados para a emissão da nota fiscal no menu "Configuração".</li>
                        <li>Cadastrar pelo menos um "Grupo de Imposto".</li>
                        <li>Cadastrar pelo menos um "Produto/Serviço".</li>
                        <li>Cadastrar pelo menos um "Destinatário".</li>
                        <li>Acessar o menu "Nota Fiscal" e clicar em incluir.</li>
                      </ul>
                    </div>
                )}
                <div>
                  <label><b>Configurações para emissão da nota fiscal eletrônica:</b></label>
                  <ul>
                    {certificado === null && (
                      <li><font color="red">Empresa sem certificado A1 carregado</font></li>
                    )}
                    {certificado != null && (
                      <li>Situação do certificado: {new Date(certificado.validade) < new Date() ? <font color="red">Certificado Vencido</font> : <font color="green">Regular</font>}</li>
                    )}
                    {certificado != null && (
                      <li>Validade do certificado A1: {new Intl.DateTimeFormat("pt-BR").format(new Date(certificado.validade))}</li>
                    )}
                    <li>Dados da Empresa: {cadastroCompleto.informouDadosEmpresa ? "Dados informados" : <font color="red">Dados não informados</font>}</li>
                    <li>Ambiente: {config.ambienteProducao ? <font color="green">Produção</font> : <font color="orange">Homologação</font>}</li>
                    <li>Natureza da Operação: {config.naturezaOperacao == null ? <font color="red">Não informado</font> : config.naturezaOperacao}</li>
                    <li>Informações Complementares: {config.informacoesComplementares == null ? <font color="red">Não informado</font> : config.informacoesComplementares}</li>
                    {config.ambienteProducao && (<li>Número da última nota: {config.numeroNotaProducaoNFe == null ? <font color="red">Não informado</font> : config.numeroNotaProducaoNFe}</li>)}
                    {!config.ambienteProducao && (<li>Número da última nota: {config.numeroNFe == null ? <font color="red">Não informado</font> : config.numeroNFe}</li>)}
                    <li>Grupo de Imposto: {cadastroCompleto.temGrupoDeImposto ? "Grupo de imposto cadastrado" : <font color="red">Grupo de imposto não informado</font>}</li>
                    <li>Produto: {cadastroCompleto.temProdutoCadastro ? "Produto cadastrado" : <font color="red">Produto não informado</font>}</li>
                  </ul>
                </div>
                <div>
                    <label><b>Dados da assinatura:</b></label>
                    <ul>
                      <li>Situação: {dtExpiracao < new Date() ? <font color="red">Assinatura Vencida</font> : <font color="green">Regular</font>}</li>
                      <li>Data de Expiração: {new Date(cadastroCompleto.dataVencimentoAssinatura).toLocaleDateString('pt-BR')}</li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}