import React, { Component } from "react";
import ApiService from "./../utils/ApiService";

export default class Configuracoes extends Component {
  state = {
    config: {
      idUsuario: "",
      ambienteProducao: false,
      nFe: false,
      nfCe: false,
      certificadoByte: "",
      naturezaOperacao: "",
      informacoesComplementares: "",
      numeroNFe: 0,
      serieNFe: 0,
      numeroNotaProducaoNFe: 0,
      serieNotaProducaoNFe: 0,
      numeroNFCe: 0,
      serieNFCe: 0,
      idCSC: 0,
      csc: 0
    }
  };

  componentWillMount() {
    var api = new ApiService();

    api.obterStatusCadastro().then(d => {
      if (!d.fezUploadCertificado)
      {
        alert("Por favor, cadastrar o certificado digital!");
        this.props.history.push("/cert");
      } else if (!d.informouDadosEmpresa)
      {
        alert("Por favor, informar os dados da empresa!");
        this.props.history.push("/empresa");
      }
    });
  }

  componentDidMount() {
    var api = new ApiService();
    api.obterConfiguracoes().then(x => {
      this.setState({ config: x });
    });
  }

  handleInputChange = e => {
    var config = Object.assign({}, this.state.config);
    if (e.target.type === "checkbox" || e.target.type === "radio") {
      config[e.target.name] = !config[e.target.name];
    } else {
      config[e.target.name] = e.target.value;
    }
    this.setState({
      config: config
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    var api = new ApiService();
    api.atualizarConfiguracao(this.state.config).then(x => {
      if (x.error && x.error.count > 0) {
        alert(x.error.erros[0]);
        this.setState({ loading: false });
      } else {
        this.setState({
          loading: false
        });
        alert("Operação realizada com sucesso.");
      }
    });
  };

  render() {
    const { config } = this.state;
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          <div className="card">
            <div className="card-header">Configuração NFe/NFCe</div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="form-group ">
                    <label>Tipos de Documentos</label>
                    <div className="form-group ">
                      <label className="checkbox ">
                        <input
                          type="checkbox"
                          checked={config.nFe}
                          name="nFe"
                          onChange={this.handleInputChange}
                        />
                        <span className="check-mark" /> NF-e
                      </label>
                      {/* <label
                        className="checkbox "
                        style={{ marginLeft: "15px" }}
                      >
                        <input
                          type="checkbox"
                          checked={config.nfCe}
                          name="nfCe"
                          onChange={this.handleInputChange}
                        />
                        <span className="check-mark" /> NFC-e
                      </label> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="form-group ">
                    <label>Ambientes</label>
                    <div
                      className="form-group"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div>
                        <input
                          type="radio"
                          name="ambienteProducao"
                          checked={config.ambienteProducao ? true : false}
                          onChange={this.handleInputChange}
                        />
                        <span className="check-mark" /> Produção
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <input
                          type="radio"
                          name="ambienteProducao"
                          checked={config.ambienteProducao ? false : true}
                          onChange={this.handleInputChange}
                        />
                        <span className="check-mark" /> Homologação
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="form-group">
                    <label>Natureza da Operação</label>
                    <input
                      type="text"
                      className="form-control"
                      value={config.naturezaOperacao}
                      name="naturezaOperacao"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="form-group">
                    <label>Informações Complementares</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      value={config.informacoesComplementares}
                      name="informacoesComplementares"
                      onChange={this.handleInputChange}
                      maxLength="1000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!config.ambienteProducao && config.nFe && (
            <div className="card" style={{ marginTop: 20 }}>
              <div className="card-header">NF-e</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Nº atual da nota</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.numeroNFe}
                          name="numeroNFe"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Série inicial</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.serieNFe}
                          name="serieNFe"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
          {!config.ambienteProducao && config.nfCe && (
            <div className="card">
              <div className="card-header">NFC-e</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Nº atual da nota</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.numeroNFCe}
                          name="numeroNFCe"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Série inicial</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.serieNFCe}
                          name="serieNFCe"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Id CSC:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.idCSC}
                          name="idCSC"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-7 col-lg-7">
                      <div className="form-group ">
                        <label>CSC</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.csc}
                          name="csc"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}

          {config.ambienteProducao && config.nFe && (
            <div className="card" style={{ marginTop: 20 }}>
              <div className="card-header">NF-e</div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Nº atual da nota</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.numeroNotaProducaoNFe}
                          name="numeroNotaProducaoNFe"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="form-group ">
                        <label>Série inicial</label>
                        <input
                          type="text"
                          className="form-control"
                          value={config.serieNotaProducaoNFe}
                          name="serieNotaProducaoNFe"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
          <div className="form-group" style={{ display: "flex" }}>
            <button
              type="submit"
              className="btn btn-primary mr-1"
              style={{ marginTop: 10, marginLeft: "auto" }}
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  }
}
