import React, { Component } from "react";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalInutilizarNumero from "../../modals/ModalInutilizarNumero"

class PesquisarNota extends Component {
  constructor(props) {
    super(props);
    registerLocale("pt-BR", ptBR);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Data Inicio</label>
                <DatePicker
                  className="form-control"
                  name="dataInicio"
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  selected={this.props.dtEmissaoInicio}
                  onChange={e => this.props.onChangeDate("dtEmissaoInicio", e)}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Data Fim</label>
                <DatePicker
                  className="form-control"
                  name="dataFim"
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  selected={this.props.dtEmissaoFim}
                  onChange={e => this.props.onChangeDate("dtEmissaoFim", e)}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Tipo Doc.</label>
                <select className="form-control">
                  <option value="tipoDoc">NFe</option>
                </select>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Número</label>
                <input
                  type="text"
                  className="form-control"
                  name="numeroNota"
                  value={this.props.numeroNota}
                  onChange={this.props.onChangeField}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>CFP/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="cnpj"
                  value={this.props.cnpj}
                  onChange={this.props.onChangeField}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Nome/Razão Social</label>
                <input
                  type="text"
                  className="form-control"
                  name="razaoSocial"
                  value={this.props.razaoSocial}
                  onChange={this.props.onChangeField}
                />
              </div>
            </div>
            {/* <div className="col">
                            <div className="form-group">
                                <label>Status</label>
                                <select className="form-control">
                                    <option>a</option>
                                </select>
                            </div>
                        </div> */}
          </div>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary mr-1"
                onClick={this.props.pesquisar}
              >
                Pesquisar
              </button>
              <ModalInutilizarNumero />
              {this.props.emailContabilidade != "" && (
                <button
                  className="btn btn-primary mr-1"
                  onClick={this.props.enviarParaContabilidade}
                >
                  Enviar para Contabilidade
                </button>
              )}
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                name="email"
                value={this.props.emailContabilidade}
                onChange={this.props.onChangeField}
                placeholder="E-mail Contabilidade"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PesquisarNota;
