import React, { Component } from "react";
import ModalPesquisarProduto from "./Produto/ModalPesquisarProduto";
import TotalResumo from "./Nota/TotalResumo";
import ResumoTotalICMS from "./Nota/ResumoTotalICMS";
import ResumoTotalISS from "./Nota/ResumoTotalISS";
import ApiServiceNota from "./../utils/ApiServiceNota";
import ModalEditarProduto from "./Nota/ModalEditarProduto";
import ApiServiceGrupoImposto from "../utils/ApiServiceGrupoImposto";
import ApiService from "./../utils/ApiService";

import SimplesNacional from "./GrupoImposto/simplesNacionalServico";
import SimplesNacionalProduto from "./GrupoImposto/SimplesNacionalProduto";
import ApiServiceCNPJ from "../utils/ApiServiceCNPJ";

import fileDownload from "js-file-download";
import TipoPagamento from "./Nota/TipoPagamento";
import LoadingOverlay from "react-loading-overlay";

import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CurrencyInput from "react-currency-input";

import Home from "./Home";

class CadastrarNotaFiscal extends Component {
  state = {
    idNotaFiscal: this.props.match.params.id
      ? parseInt(this.props.match.params.id, 10)
      : null,
    acao: this.props.match.params.acao
      ? this.props.match.params.acao
      : null,
    nota: {
      idNotaFiscal: 0,
      idEmpresa: 0,
      idLote: 0,
      idTipoDocumento: 0,
      idAmbiente: 0,
      nfeChave: "",
      nfeReferencia: "",
      naturezaOperacao: "",
      numeroNota: 0,
      serieNota: 0,
      idStatusSistema: 0,
      idDestinatario: 0,
      idIndicadorPresenca: 1,
      dtEmissao: "2019-04-23T01:09:35.886Z",
      dtSaida: "2019-04-23T01:09:35.886Z",
      idFinalidade: 1,
      idTransportadora: 0,
      numeroParcela: 0,
      idFormaPagamento: 1,
      valorTotal: 0,
      ValorTotalLiqudio: 0,
      notaFiscalProduto: [],
      notaFiscalDuplicata: [],
      informacoesComplementares: ""
    },
    resumoCalc: {
      produtos: 0.0,
      servicos: 0.0,
      descontos: 0.0,
      ipi: 0.0,
      seguro: 0.0,
      frete: 0.0,
      valorTotal: 0.0,
      icmsBaseCalculo: 0.0,
      icms: 0.0,
      icmsPis: 0.0,
      icmsCofins: 0.0,
      icmsStBaseCalculo: 0.0,
      icmsSt: 0.0,
      issBaseCalculo: 0.0,
      iss: 0.0,
      issPis: 0.0,
      issConfins: 0.0
    },
    CFOP: [],
    ORIGEM: [],
    loading: true,
    ICMS: [],
    IPI: [],
    PIS: [],
    COFINS: [],
    SERVICOS: [],
    modal: false,
    modalEditarProduto: false,
    produtoKeyEditar: 0,
    DESTINATARIO: [],
    msgLoading: "",
    CONFIG: [],
    redirect: false
  };

  // #region Funcoes
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  toggleEditarProduto = key => {
    this.setState(prevState => ({
      modalEditarProduto: !prevState.modalEditarProduto,
      produtoKeyEditar: key
    }));
  };

  preProcessarNota = nota => {
    var api = new ApiServiceNota();
    api.PreProcessarNota(nota).then(x => {
      this.setState({ nota: x, loading: true });
      this.calcularNota();
    });
  };

  selecionarItem = produto => {
    var { nota } = this.state;
    nota.notaFiscalProduto.push(produto);
    this.preProcessarNota(nota);

    this.toggle();
  };

  calcularNota = () => {
    var api = new ApiServiceNota();
    api.ObterCalculoResumoNota(this.state.nota).then(r => {
      this.setState({ resumoCalc: r, loading: false });
    });
  };

  alterarValorProduto = (i, v) => {
    var nota = Object.assign({}, this.state.nota);
    if (!v >= 0) v = 0;

    nota["notaFiscalProduto"][i]["valor"] = v;
    this.preProcessarNota(nota);
  };

    alterarDadosProduto = (i, e) => {
        var nota = Object.assign({}, this.state.nota);
        var v = e.target.value;
        if (e.target.name === "valor" && v === "") v = 0;
        if (e.target.name === "quantidade" && v === "") v = 0;

    nota["notaFiscalProduto"][i][e.target.name] = v;
    this.preProcessarNota(nota);
  };

  alterarDadosProdutoNovo = (i, valor, name) => {
    var nota = Object.assign({}, this.state.nota);
    nota["notaFiscalProduto"][i][name] = valor;
    this.preProcessarNota(nota);
  };

  alterarDadosImposto = (i, e) => {
    var nota = Object.assign({}, this.state.nota);
    nota["notaFiscalProduto"][i]["notaFiscalProdutoImposto"][0][e.target.name] =
      e.target.value;
    this.preProcessarNota(nota);
  };

  alterarDadosDuplicada = (i, e) => {
    var nota = Object.assign({}, this.state.nota);
    nota["notaFiscalDuplicata"][i][e.target.name] = e.target.value;
    this.setState({ nota });
  };

  alterarDadosNatureza = e => {
    var nota = Object.assign({}, this.state.nota);
    nota["naturezaOperacao"] = e.target.value;
    this.setState({ nota });
  };

  alterarInformacoesComplementares = e => {
    var nota = Object.assign({}, this.state.nota);
    nota["informacoesComplementares"] = e.target.value;
    this.setState({ nota });
  };

  alterarFormaPagamentoNota = (i, e) => {
    this.alterarDadosNota(e);
  };

  verificarNumeroDuplicata = () => {
    var nota = Object.assign({}, this.state.nota);
    if (nota["numeroParcela"] == "") {
      nota["numeroParcela"] = 1;
    }
    this.preProcessarNota(nota);
  };

  alterarDadosNota = e => {
    var nota = Object.assign({}, this.state.nota);
    nota[e.target.name] = e.target.value;

    if (e.target.name == "numeroParcela" && e.target.value == "") {
      this.setState({ nota });
      return;
    }

    this.preProcessarNota(nota);
  };

  onChangeDate = (i, e) => {
    var nota = Object.assign({}, this.state.nota);
    nota["notaFiscalDuplicata"][i]["vencimento"] = e;
    this.setState({ nota });
  };

  alterarValorDuplicata = (e, maskedvalue, floatvalue) => {
    var nota = Object.assign({}, this.state.nota);
    nota["notaFiscalDuplicata"][e.target.name]["valor"] = floatvalue;
    this.setState({ nota });
  };

  removerProduto = i => {
    var { nota } = this.state;
    var notaFiscalProduto = nota.notaFiscalProduto.filter(function(
      value,
      index,
      arr
    ) {
      return index != i;
    });

    nota.notaFiscalProduto = notaFiscalProduto;

    this.setState({ nota });
  };

  salvarNota = () => {
    var api = new ApiServiceNota();
    var { idNotaFiscal, acao} = this.state;
    if (idNotaFiscal && acao != 'copiar') {
      this.setState({
        loading: true,
        msgLoading: "Atualizando Documento Fiscal..."
      });
      api.Atualizar(this.state.nota).then(x => {
        if (x.error && x.error.count > 0) {
          alert(x.error.erros[0]);
          this.setState({ loading: false });
        } else {
          this.setState({
            loading: false
          });
          alert("Operação realizada com sucesso.");
        }
      });
    } else {
      this.setState({
        loading: true,
        msgLoading: "Salvando Documento Fiscal."
      });
      this.state.nota.idNotaFiscal = 0;
      api.incluirNota(this.state.nota).then(x => {
        if (x.error) {
          alert(x.error.erros[0]);
          this.setState({ loading: false });
        } else {
          idNotaFiscal = x.idNotaFiscal
          api.getNota(idNotaFiscal).then(n => {
            this.setState({ msgLoading: "", loading: false, nota: n.data, idNotaFiscal });
          });
        }
      });
    }
  };

  enviarNota = () => {
    var api = new ApiServiceNota();
    const { idNotaFiscal } = this.state;
    if (idNotaFiscal) {
      this.setState({
        loading: true,
        msgLoading: "Atualizando Documento Fiscal..."
      });
      api.Atualizar(this.state.nota).then(() => {
        this.setState({
          msgLoading: "Enviando Documento Fiscal..."
        });
        api.EnviarNota(this.state.nota).then(r => {
          this.setState({ loading: false });
          if (r.error && r.error.count > 0) {
            alert(r.error.erros[0]);
          } else {
            alert("Nota enviada com sucesso.");
          }
          this.props.history.push("/notafiscal");
        });
      });
    } else {
      this.setState({
        loading: true,
        msgLoading: "Salvando Documento Fiscal."
      });
      api.incluirNota(this.state.nota).then(x => {
        if (x.error) {
          alert(x.error.erros[0]);
          this.setState({ loading: false });
        } else {
          this.setState({
            msgLoading: "Enviando Documento Fiscal"
          });
          api.EnviarNota(x).then(r => {
            this.setState({ loading: false });
            if (r.error && r.error.count > 0) {
              alert(r.error.erros[0]);
            } else {
              alert("Nota enviada com sucesso.");
            }
            this.props.history.push("/notafiscal");
          });
        }
      });
    }
  };

  preVisualizar = () => {
    var api = new ApiServiceNota();
    this.setState({ loading: true, msgLoading: "Validando Nota." });
    api.validarNota(this.state.nota).then(r => {
      if (r.error && r.error.count > 0) {
        this.setState({ loading: false });
        alert(r.error.erros[0]);
      } else {
        this.setState({ loading: true, msgLoading: "Carregando pdf." });
        api.getPDFVisualizar(this.state.nota).then(x => {
          x.blob().then(b => {
            const file = new Blob([b], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.setState({ loading: false, msgLoading: "" });
          });
        });
      }
    });
  };

  componentDidMount() {
    var apiCNPJ = new ApiServiceCNPJ();
    var apiGrupoImposto = new ApiServiceGrupoImposto();
    var apiNota = new ApiServiceNota();
    var apiConfiguracao = new ApiService();

    apiConfiguracao.obterStatusCadastro().then(d => {
      if (d.certificadoVencido || !d.fezUploadCertificado || !d.informouDadosEmpresa || !d.temGrupoDeImposto || !d.temProdutoCadastro)
      {
        this.setState({ redirect: true });
        alert("Configurações necessárias para emissão da nota fiscal eletrônica incompleta. Por favor, verifique a situação do cadastro na próxima tela.");
      }
    });

    apiCNPJ.getDestinatario().then(d => {
      this.setState({ DESTINATARIO: d.data });
    });

    apiGrupoImposto.getCFOP().then(d => {
      this.setState({ CFOP: d.data });
    });

    apiGrupoImposto.getORIGEM().then(d => {
      this.setState({ ORIGEM: d.data });
    });

    apiGrupoImposto.getICMS().then(d => {
      this.setState({ ICMS: d.data });
    });

    apiGrupoImposto.getIPI().then(d => {
      this.setState({ IPI: d.data });
    });
    apiGrupoImposto.getPIS().then(d => {
      this.setState({ PIS: d.data });
    });
    apiGrupoImposto.getCOFINS().then(d => {
      this.setState({ COFINS: d.data });
    });

    apiConfiguracao.obterConfiguracoes().then(d => {
      this.setState({ CONFIG: d });
    });

    apiGrupoImposto.getTipoServico().then(d => {
      this.setState({ SERVICOS: d.data, loading: false });
    });

    const { idNotaFiscal } = this.state;
    var nota = Object.assign({}, this.state.nota);
    if (idNotaFiscal) {
      this.setState({ msgLoading: "Carregando Nota", loading: true });
      apiNota.getNota(idNotaFiscal).then(n => {
        nota = n.data;
        if(nota.informacoesComplementares == null || nota.informacoesComplementares == "") {
          apiConfiguracao.obterConfiguracoes().then(d => {
            nota.informacoesComplementares = d.informacoesComplementares;
            this.setState({ msgLoading: "", loading: false, nota });
          });
        } else {
          this.setState({ msgLoading: "", loading: false, nota });
        }
        
      });
    } else {
      apiConfiguracao.obterConfiguracoes().then(d => {
        nota.informacoesComplementares = d.informacoesComplementares;
        nota.naturezaOperacao = d.naturezaOperacao;
        this.setState({ nota: nota });
      });
    }
  }

  //#endregion

  render() {
    var nItem = 0;
    const {
      nota,
      resumoCalc,
      CFOP,
      loading,
      ORIGEM,
      PIS,
      COFINS,
      DESTINATARIO,
      modalEditarProduto,
      idDestinatario,
      FormaPagamento,
      msgLoading,
      CONFIG,
      redirect
    } = this.state;

    if (redirect){
      return <Home />;
    } else {
      return (
          <div className="container">
            <LoadingOverlay active={loading} spinner text={msgLoading}>
            {modalEditarProduto && (
              <ModalEditarProduto
                backdrop="static"
                toggle={this.toggleEditarProduto}
                modal={this.state.modalEditarProduto}
                produtos={this.state.nota.notaFiscalProduto}
                produtoKey={this.state.produtoKeyEditar}
                onAlterarDadosProduto={this.alterarDadosProduto}
                onAlterarDadosProdutoNovo={this.alterarDadosProdutoNovo}
                onAlterarDadosImposto={this.alterarDadosImposto}
                className="modal-content bg-light"
              />
            )}

            <ModalPesquisarProduto
              backdrop="static"
              toggle={this.toggle}
              modal={this.state.modal}
              className="modal-content bg-light"
              idDestinatario={nota.idDestinatario}
              selecionarItem={this.selecionarItem}
            />

            <div className="card">
              <div className="card-header">Cabelhaço da Nota</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-2 col-md-2 col-lg-2">
                    <div className="form-group ">
                      <label>Tipo</label>
                      <select
                        className="form-control"
                        onChange={this.alterarDadosNota}
                        value={nota.idTipoDocumento}
                        name="idTipoDocumento"
                      >
                        <option value="0" />
                        <option value="1">Nfse</option>
                        <option value="55">Nfe</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-2 col-md-2 col-lg-2">
                    <div className="form-group ">
                      <label>Serie</label>
                      <div className="form-group ">Auto</div>
                    </div>
                  </div>
                  <div className="col-2 col-md-2 col-lg-2">
                    <div className="form-group ">
                      <label>Nº</label>
                      <div className="form-group ">Auto</div>
                    </div>
                  </div>
                  {nota.idTipoDocumento === 55 && (
                  <div className="col-2 col-md-2 col-lg-2">
                    <div className="form-group ">
                      <label>Finalidade</label>
                      <div className="form-group ">
                        <select
                          className="form-control"
                          name="idFinalidade"
                          value={nota.idFinalidade}
                          onChange={this.alterarDadosNota}
                        >
                          <option value="1">Normal</option>
                          {/* <option>Complementar</option> */}
                          {/* <option>Ajuste</option> */}
                          <option value="4">Devolução</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  )}
                  {nota.idTipoDocumento === 55 && (
                    <div className="col-4 col-md-4 col-lg-4">
                      <div className="form-group ">
                        <label>Indicador Presença</label>
                        <div className="form-group ">
                          <select
                            className="form-control"
                            name="idIndicadorPresenca"
                            value={nota.idIndicadorPresenca}
                            onChange={this.alterarDadosNota}
                          >
                            <option value="1">Operação presencial</option>
                            <option value="2">
                              Operação não presencial, pela internet
                            </option>
                            <option value="3">
                              Operação não presencial, Teleatendimento
                            </option>
                            <option value="4">
                              NFCe em operação com entrega a domicilio
                            </option>
                            <option value="9">
                              Operação não presencial, outros
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-6 col-md-6 col-lg-6">
                    <div className="form-group ">
                      <label>Destinatário</label>
                      <div className="form-group ">
                        <select
                          className="form-control"
                          value={nota.idDestinatario}
                          name="idDestinatario"
                          onChange={this.alterarDadosNota}
                        >
                          <option value="0" />
                          {DESTINATARIO.map(d => (
                            <option
                              key={d.idDestinatario}
                              value={d.idDestinatario}
                            >
                              {d.cpfCnpj} - {d.nomeRazaoSocial} -{" "}
                              {d.municipio.uf.sigla}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {nota.idTipoDocumento === 55 && (
                  <div className="col-6 col-md-6 col-lg-6">
                      <label>Natureza de Operação</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="60"
                          value={ nota.naturezaOperacao }
                          name="naturezaOperacao"
                          onChange={this.alterarDadosNatureza}
                        />
                  </div>
                  )}
                </div>
              </div>
            </div>

            {nota.idFinalidade === 4 && (
              <div className="card">
                <div className="card-header">Nota de Referência</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="form-group ">
                        <label>Número</label>
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            name="nfeReferencia"
                            value={nota.nfeReferencia}
                            onChange={this.alterarDadosNota}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {nota.idDestinatario != 0 && (
              <div>
                <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="card-header">Serviços</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        {idDestinatario !== 0 && (nota.notaFiscalProduto.length == 0 || nota.idTipoDocumento == 55) && (
                          <button
                            className="btn btn-primary mr-1"
                            onClick={this.toggle}
                          >
                            Incluir Serviço
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        {nota.notaFiscalProduto.length > 0 && (
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Produto/Serviço</th>
                                <th>Quantidade</th>
                                <th>CFOP</th>
                                <th>Preço</th>
                                <th>Desconto</th>
                                <th>Total Liquido</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {nota.notaFiscalProduto.map((n, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{n.produto.descricao}</td>
                                  <td>
                                    {nota.notaFiscalProduto[key].quantidade}
                                  </td>
                                  <td>
                                    {
                                      nota.notaFiscalProduto[key]
                                        .notaFiscalProdutoImposto[0].cfop
                                    }
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL"
                                    }).format(nota.notaFiscalProduto[key].valor)}
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL"
                                    }).format(
                                      nota.notaFiscalProduto[key].desconto
                                    )}
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL"
                                    }).format(
                                      nota.notaFiscalProduto[key].valorLiquido
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-primary mr-1"
                                      onClick={() =>
                                        this.toggleEditarProduto(key)
                                      }
                                    >
                                      Editar
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-primary mr-1"
                                      onClick={() => {
                                        this.removerProduto(key);
                                      }}
                                    >
                                      Remover
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="card-header">Resumo</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        <TotalResumo resumoCalc={resumoCalc} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        <ResumoTotalICMS resumoCalc={resumoCalc} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        <ResumoTotalISS resumoCalc={resumoCalc} />
                      </div>
                    </div>
                  </div>
                </div>
                {nota.idFinalidade === 1 && nota.idTipoDocumento == 55 && (
                  <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
                    <div className="card-header">Forma de Pagamento</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-md-3 col-lg-3">
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="form-group ">
                              <label>Parcelas</label>
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="numeroParcela"
                                  value={nota.numeroParcela}
                                  onChange={this.alterarDadosNota}
                                  onBlur={this.verificarNumeroDuplicata}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="form-group ">
                              <label>Forma de Pagamento</label>
                              <div className="form-group ">
                                <TipoPagamento
                                  IdFormaPagamento={nota.idFormaPagamento}
                                  keyItem="0"
                                  onAlterarDadosDuplicata={
                                    this.alterarFormaPagamentoNota
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-9 col-md-9 col-lg-9">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th>Forma de Pagamento</th>
                                <th>Vencimento</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              {nota.notaFiscalDuplicata.map((d, k) => (
                                <tr key={k}>
                                  <td>{d.numeroDuplicata}</td>
                                  <td>
                                    <TipoPagamento
                                      keyItem={k}
                                      onAlterarDadosDuplicata={
                                        this.alterarDadosDuplicada
                                      }
                                      IdFormaPagamento={d.idFormaPagamento}
                                    />
                                  </td>
                                  <td>
                                    <DatePicker
                                      className="form-control"
                                      name="vencimento"
                                      locale={ptBR}
                                      dateFormat="dd/MM/yyyy"
                                      selected={new Date(d.vencimento)}
                                      onChange={e => this.onChangeDate(k, e)}
                                    />
                                  </td>
                                  <td>
                                    <CurrencyInput
                                      value={d.valor}
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      name={k}
                                      className="form-control"
                                      onChangeEvent={this.alterarValorDuplicata}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="card-header">Informações Complementares</div>
                  <div className="card-body">
                    <div className="row">
                      <textarea
                          rows="5"
                          className="form-control"
                          value={ nota.informacoesComplementares }
                          name="informacoesComplementares"
                          onChange={this.alterarInformacoesComplementares}
                          maxLength="1000"
                        />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <button
                      className="btn btn-primary mr-1"
                      onClick={this.salvarNota}
                    >
                      Salvar
                    </button>
                    <button
                      className="btn btn-primary mr-1"
                      onClick={this.enviarNota}
                    >
                      Salvar e Enviar
                    </button>
                    {nota.idTipoDocumento == 55 && (
                    <button
                      className="btn btn-primary mr-1"
                      onClick={this.preVisualizar}
                      > Visualizar
                    </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            </LoadingOverlay>
          </div>
      );
    }
  }
}

export default CadastrarNotaFiscal;
