import React, { Component } from "react";

class Paginacao extends Component {
  render() {
    var paginacao = [];
    var totalPaginas = Math.round(this.props.totalRegistro/this.props.numRegistro);
    
    const styles = {
        buttonStyle: {
            backgroundColor: "lightgray",
            marginLeft: "2px"
        },
        buttonSyteSelected: {
            backgroundColor: "white",
            marginLeft: "2px"
        }
    }

    for (var i = 1; i <= totalPaginas; i++) {
        paginacao.push(<button
            type="button"
            key={i}
            value={i}
            className="btn btn-sm"
            onClick={e => this.props.handleClick(e.target.value)}
            style={i == this.props.pageSelected ? styles.buttonStyle : styles.buttonSyteSelected}
            >{i}</button>);
        
    }

    return (
      <div>
        { paginacao }
      </div>
    );
  }
}

export default Paginacao;
