import React, { Component } from "react";
import serialize from "form-serialize";
import ApiServiceCertificado from "../utils/ApiServiceCertificado";
import ReactLoading from "react-loading";
import LoadingOverlay from "react-loading-overlay";

class Certificado extends Component {
  state = {
    certificado: null,
    file: null,
    loading: true,
    certificadoUpload: null,
    error: [],
  };

  componentDidMount() {
    this.carregarDodosCertificado();
  }

  carregarDodosCertificado() {
    var api = new ApiServiceCertificado();
    api.getCertificado().then(c => {
      if (c.cnpj) {
        this.setState({ loading: false, certificado: c, error: [] });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  handleFileChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        certificadoUpload: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    var form = serialize(e.target, { hash: true });
    var api = new ApiServiceCertificado();
    api
      .atualizarConfiguracaoCertificado({
        ...form,
        Certificado: this.state.certificadoUpload.split(",")[1]
      })
      .then(x => {
        if (x.error && x.error.count > 0) {
          this.setState({ error: x.error.erros, loading: false});
        } else if (x.count > 0) {
            this.setState({ error: x.erros, loading: false});
        } else {
          alert("Certificado cadastrado com sucesso.");
          this.carregarDodosCertificado();
        }
      });
  };

  removerCertificado = () => {
    var api = new ApiServiceCertificado();
    api.removerCertificado().then(() => {
      this.setState({ certificado: null });
    });
  };

  render() {
    const { certificado, loading, error } = this.state;
    return (
        <div className="container">
          <LoadingOverlay active={loading} spinner>
        {error.length > 0 && (
                <div className="callout callout-danger" style={{color: "red"}}>
                {error.map((e, c) => (
                    <p>
                    {e}
                    </p>
                ))}
                </div>
            )}
          <form onSubmit={this.handleSubmit}>
            <div className="callout callout-info">
              <p>
                É necessário fazer upload do certificado A1 para que as NFe/NFCe
                seja emitidas.
              </p>
            </div>
            {loading && (
              <ReactLoading
                type="bubbles"
                color="black"
                height={70}
                width={70}
              />
            )}

            {loading === false && (
              <div className="card">
                <div className="card-header">Certificado A1</div>
                {loading === false && certificado === null && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-10 col-md-10 col-lg-4">
                        <div className="form-group">
                          <label>Certificado</label>
                          <input
                            required
                            type="file"
                            name="certificado"
                            onChange={this.handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Senha do Certificado</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="SenhaCertificado"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {loading === false && certificado != null && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>CNPJ</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={certificado.cnpjFormatado}
                          />
                        </div>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>EMPRESA</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={certificado.empresa}
                          />
                        </div>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>RESPONSÁVEL</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={certificado.responsavel}
                          />
                        </div>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            <label>VALIDADE</label>
                            <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={new Intl.DateTimeFormat("pt-BR").format(new Date(certificado.validade))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="form-group" style={{ display: "flex" }}>
              {loading === false && certificado === null && (
                <button
                  type="submit"
                  className="btn btn-primary mr-1"
                  style={{ marginTop: 10, marginLeft: "auto" }}
                >
                  Salvar
                </button>
              )}

              {certificado != null && (
                <button
                  type="button"
                  className="btn btn-danger mr-1"
                  style={{ marginTop: 10, marginLeft: "auto" }}
                  onClick={this.removerCertificado}
                >
                  Excluir
                </button>
              )}
            </div>
          </form>

      </LoadingOverlay>
      </div>
    );
  }
}

export default Certificado;
