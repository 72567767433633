
export default class ApiViaCep {
    constructor(domain) {
        this.fetch = this.fetch.bind(this); // 
    }

    pesquisarCep(cep) {
        return this.fetch(`https://ws.apicep.com/cep/${cep}.json`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        })
        .catch(() => {
          return '';
        });
    }

    fetch(url, options) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json"
        };          

        return fetch(url, {
          headers,
          ...options
        }).then(response => response.json());
      }
}