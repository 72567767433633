import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import ApiServicePacote from "../utils/ApiServicePacote";

class historicoCompra extends Component {
  state = {
    loading: true,
    compras: []
  };

  componentDidMount() {
    var api = new ApiServicePacote();
    api.ListarCompras().then(x => {
      this.setState({ loading: false, compras: x });
    });
  }

  render() {
    const { loading, compras } = this.state;
    return (
      <div className="container">
        <LoadingOverlay active={loading} spinner>
          <br></br>
          <br></br>

          <h2 className="text-center">Histórico de Compras</h2>
          <br></br>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Data Compra</th>
                  <th>Pacote</th>
                  <th>Status</th>
                  <th>Data Expiração</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {compras.map(x => (
                  <tr key={x.iD_PACOTE_USUARIO}>
                    <td>{new Date(x.dT_COMPRA).toLocaleDateString('pt-BR')}</td>
                    <td>{x.pacote.dS_PACOTE}</td>
                    <td>{x.pagSeguroStatusTransacao.dS_DESCRICAO}</td>
                    <td>{new Date(x.dT_EXPIRACAO).toLocaleDateString('pt-BR')}</td>
                    <td>
                      {x.dS_LINKBOLETO && (
                        <a href={x.dS_LINKBOLETO} target="_blank">
                          Boleto
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default historicoCompra;
