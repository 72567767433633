import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ApiServicePacote from '../utils/ApiServicePacote';
import LoadingOverlay from 'react-loading-overlay';
import Home from "./Home";

class pacotes extends Component {
    state = {
        loading: true,
        pacotes: []
    }

    componentDidMount() {
        var api = new ApiServicePacote();
        api.ObterPacotesAtivos().then(x => {
            this.setState({ loading: false, pacotes: x.data })
            console.log(x);
        })
    }

    render() {
        const { loading, pacotes } = this.state;
        return <Home />;
        return (
            <LoadingOverlay active={loading} spinner>
                <div>
                    <h2 className="text-center">Pacotes</h2>
                    <div className="row">
                        {pacotes.map(x =>
                                <div className="col" key={x.iD_PACOTE}>
                                    <div className="card" style={{ width: '18rem' }}>
                                        <div className="card-body">
                                            <h5 className="card-title">{x.dS_PACOTE}</h5>
                                            <p className="card-text">{x.dS_DETALHADA}</p>
                                            <p className="card-text">{new Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(x.vL_PACOTE)}</p>
                                            <Link to={"comprar/" + x.iD_PACOTE} className="btn btn-primary">Comprar</Link>
                                        </div>
                                    </div>
                                </div>
                        )}
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}

export default pacotes;