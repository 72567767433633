import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ApiServiceNota from "../../utils/ApiServiceNota";
import { BeatLoader } from "react-spinners";

class ModalCartaCorrecao extends Component {
  state = {
    loading: false,
    nota: {},
    descricao: ""
  };

  RegistrarCartaCorrecao = nota => {
    this.setState({ loading: true });
    var api = new ApiServiceNota();
    var idNotaFiscal = nota.idNotaFiscal;
    var descricao = this.state.descricao;
    api.CartaCorrecao(idNotaFiscal, descricao).then(r => {
      this.setState({ loading: false });
      if (r.error && r.error.count > 0) {
        alert(r.error.erros[0]);
      } else {
        this.setState({ descricao: "" });
        this.props.toggle();
      }      
    });
  };

  render() {
    const { loading } = this.state;
    const { nota } = this.props;
    return (
      <div>
        <Modal
          onOpened={this.onOpened}
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          backdrop={this.props.backdrop}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            Carta correção: {nota.nfeChave}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="form-group ">
                  <label>Descrição:</label>
                  <div className="form-group ">
                    <textarea
                      className="form-control"
                      value={this.state.descricao}
                      name="descricao"
                      onChange={e =>
                        this.setState({ descricao: e.target.value })
                      }
                    />
                  </div>
                  <label>{this.state.descricao.length} / 1000</label>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.loading && (
              <Button color="primary">
                <BeatLoader
                  sizeUnit={"px"}
                  size={10}
                  color={"#123abc"}
                  loading={loading}
                />
              </Button>
            )}

            {!this.state.loading && (
              <Button
                color="primary"
                onClick={() => this.RegistrarCartaCorrecao(nota)}
              >
                Registrar
              </Button>
            )}

            <Button color="secondary" onClick={this.props.toggle}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalCartaCorrecao;
