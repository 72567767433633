import React, { Component } from "react";

class SimplesNacionalProduto extends Component {

    state = {
        grupoImpostoItem: []
    };

  render() {
    const {
      grupoImpostoItem,
      CFOP,
      loading,
      ORIGEM,
      ICMS,
      IPI,
      PIS,
      COFINS,
      NOMECAMPO,
      CFOPValueSelect
    } = this.props;
    return (
      <div>
        <div className="row">
        <input type="hidden" name={"[" + NOMECAMPO + "][idGrupoImpostoItem]"} value={grupoImpostoItem == null ? null : grupoImpostoItem.idGrupoImpostoItem} />
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>CFOP</label>
              <div className="form-group ">
                {!loading && (
                  <select
                    defaultValue={CFOPValueSelect}
                    className="form-control"
                    name={"[" + NOMECAMPO + "][cfop]"}
                    onChange={(e) => this.props.onAlterarCFOP("produto", NOMECAMPO, e.target.value)}
                    value={grupoImpostoItem == null ? null : grupoImpostoItem.cfop}
                  >
                    {CFOP.map(c => (
                      <option key={c.cfop} value={c.cfop}>
                        {c.cfop} - {c.descricao.substring(0, 100)}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ORIGEM</label>
              <div className="form-group ">
                {!loading && (
                  <select
                    className="form-control"
                    name={"[" + NOMECAMPO + "][idOrigem]"}
                    value={grupoImpostoItem == null ? null : grupoImpostoItem.idOrigem}
                    onChange={e => {
                          this.props.handleInputChangeItem(e);
                      }}
                  >
                    {ORIGEM.map(c => (
                      <option key={c.idOrigem} value={c.idOrigem}>
                        {c.descricao}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ICMS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqICMS]"}
                      placeholder="%Aliq"
                      onChange={e => {
                          this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqICMS}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        className="form-control"
                        name={"[" + NOMECAMPO + "][idICMS]"}
                        onChange={e => {
                            this.props.handleInputChangeItem(e);
                        }}
                        value={grupoImpostoItem == null ? null : grupoImpostoItem.idICMS}
                      >
                        {ICMS.map(c => (
                          <option key={c.idICMS} value={c.idICMS}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ICMS-ST</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqICMSST]"}
                      placeholder="%Aliq"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      required
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqICMSST}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][margemICMSST]"}
                      placeholder="Margem"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.margemICMSST}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][reducaoICMSST]"}
                      placeholder="Redução"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.reducaoICMSST}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>IPI</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqIPI]"}
                      placeholder="%Aliq"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqIPI}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  {!loading && (
                    <select
                      defaultValue="1"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][idIPI]"}
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.idIPI}
                    >
                      {IPI.map(c => (
                        <option key={c.idIPI} value={c.idIPI}>
                          {c.descricao}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>PIS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqPIS]"}
                      placeholder="%Aliq"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqPIS}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        defaultValue="99"
                        className="form-control"
                        name={"[" + NOMECAMPO + "][idPIS]"}
                        onChange={e => {
                            this.props.handleInputChangeItem(e);
                        }}
                        value={grupoImpostoItem == null ? null : grupoImpostoItem.idPIS}
                      >
                        {PIS.map(c => (
                          <option key={c.idPIS} value={c.idPIS}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>COFINS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqCOFINS]"}
                      placeholder="%Aliq"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqCOFINS}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        defaultValue="99"
                        className="form-control"
                        name={"[" + NOMECAMPO + "][idCOFINS]"}
                        onChange={e => {
                            this.props.handleInputChangeItem(e);
                        }}
                        value={grupoImpostoItem == null ? null : grupoImpostoItem.idCOFINS}
                      >
                        {COFINS.map(c => (
                          <option key={c.idCofins} value={c.idCofins}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SimplesNacionalProduto;
