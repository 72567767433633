import React, { Component } from "react";
import { Link } from "react-router-dom";
import ApiServiceNota from "./../utils/ApiServiceNota";
import LoadingOverlay from "react-loading-overlay";
import ModalHistoricoNota from "./Nota/ModalHistoricoNota";
import fileDownload from "js-file-download";
import PesquisarNota from "./Nota/PesquisarNota";
import ModalCancelarNota from "./Nota/ModalCancelarNota";
import ModalCartaCorrecao from "./Nota/ModalCartaCorrecao";
import Paginacao from "./Paginacao";

class Notafiscal extends Component {
  state = {
    loading: false,
    numPages: null,
    totalRegistro: 0,
    numRegistro: 10,
    pageSelected: 1,
    notas: [],
    modalHistorio: false,
    modalCancelarNota: false,
    modalCartaCorrecaoNota: false,
    historicoNota: [],
    chaveNota: "",
    pesquisa: {
      dtEmissaoInicio: null,
      dtEmissaoFim: null,
      numeroNota: "",
      cnpj: "",
      razaoSocial: "",
      idStatusSistema: null,
      email: "",
      pageSize: 10,
      pageIndex: 1,
    },
    notaCancelamento: {}
  };

  carregar() {
    this.setState({ loading: true });

    var api = new ApiServiceNota();
    api.pesquisarNota(this.state.pesquisa).then(t => {  
      this.setState({ totalRegistro: t.numeroRegistros, notas:t.objResposta, loading: false });
    });
  }

  paginar = pageNumber => {
    this.setState({ loading: true });

    var pesquisa = Object.assign({}, this.state.pesquisa);
    pesquisa["pageIndex"] = pageNumber;

    var api = new ApiServiceNota();
    api.pesquisarNota(pesquisa).then(t => {  
      this.setState({ totalRegistro: t.numeroRegistros, notas:t.objResposta, loading: false, pageSelected: pageNumber });
    });
  };

  componentDidMount() {
    this.carregar();
  }

  pesquisar = () => {
    this.setState({ loading: true });

    var api = new ApiServiceNota();
    api.pesquisarNota(this.state.pesquisa).then(t => {  
      this.setState({ totalRegistro: t.numeroRegistros, notas: t.objResposta, loading: false });
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  toggle = () => {
    this.setState(prevState => ({
      modalHistorio: !prevState.modalHistorio
    }));
  };

  toggleModalCancelarNota = () => {
    this.setState(prevState => ({
      modalCancelarNota: !prevState.modalCancelarNota
    }));
  };

  toggleModalCartaCorrecao = () => {
    this.setState(prevState => ({
      modalCartaCorrecaoNota: !prevState.modalCartaCorrecaoNota
    }));
  };

  recarregarNotas = () => {
    this.toggleModalCancelarNota();
    this.carregar();
  };

  setHistoricoState = e => {
    this.setState({ historicoNota: e });
    this.toggle();
  };

  setChave = e => {
        this.setState({ chaveNota: e });
    };

  enviarNotaReceita = nota => {
    var api = new ApiServiceNota();
    this.setState({ loading: true, msgLoading: "Enviando..." });
    api.EnviarNota(nota).then(r => {
      this.setState({ loading: false });
      if (r.error && r.error.count > 0) {
        alert(r.error.erros[0]);
      } else {
        alert("Nota enviada com sucesso.");
        this.carregar();
      }
    });
  };

  cancelarNota = nota => {
    var api = new ApiServiceNota();
    this.setState({ notaCancelamento: nota });
    this.toggleModalCancelarNota();
  };

  carregarHistorico = (chaveNota, historicoNota) => {
    this.setChave(chaveNota);
    this.setHistoricoState(historicoNota);
  }

  cartaCorrecao = nota => {
    var api = new ApiServiceNota();
    this.setState({ notaCancelamento: nota });
    this.toggleModalCartaCorrecao();
  };

  preVisualizar = nota => {
    var api = new ApiServiceNota();
    this.setState({ loading: true, msgLoading: "Carregando pdf." });
    api.getPDF(nota).then(x => {
      x.blob().then(b => {
        if (b.type == 'application/json'){
          alert("Danfe ainda não gerado, por favor tente novamente!");
        } else {
          fileDownload(
            b,
            nota.nfeChave ? nota.nfeChave + ".zip" : "notaFiscal.zip"
          );
        }
        this.setState({ loading: false, msgLoading: "" });
      });
    });
  };

  visualizarPDF = nota => {
    var api = new ApiServiceNota();
    this.setState({ loading: true, msgLoading: "Carregando pdf." });
    api.getPDFSemZip(nota).then(x => {
        x.blob().then(b => {
          if (b.type != 'application/pdf'){
            alert("Danfe ainda não gerado, por favor tente novamente!");
          } else {
            const file = new Blob([b], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
          this.setState({ loading: false, msgLoading: "" });  
        });
    });
  };

  //Pesquisa
  handleChangeDate = (t, date) => {
    var pesquisa = Object.assign({}, this.state.pesquisa);
    pesquisa[t] = date;
    this.setState({
      pesquisa
    });
  };

  handlePesquisaFieldChange = e => {
    var pesquisa = Object.assign({}, this.state.pesquisa);
    pesquisa[e.target.name] = e.target.value;
    this.setState({
      pesquisa
    });
  };

  enviarParaContabilidade = () => {
    this.setState({ loading: true });
    var api = new ApiServiceNota();
    api.EnviarContabilidade(this.state.pesquisa).then(r => {
      if (r.error && r.error.count > 0) {
        alert(r.error.erros[0]);
      } else {
        alert("E-mail enviado com sucesso");
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const { loading, notas, pesquisa, totalRegistro, numRegistro, pageSelected } = this.state;

    return (
        <div className="container">
          <PesquisarNota
            dtEmissaoInicio={pesquisa.dtEmissaoInicio}
            dtEmissaoFim={pesquisa.dtEmissaoFim}
            numeroNota={pesquisa.numeroNota}
            cnpj={pesquisa.cnpj}
            razaoSocial={pesquisa.razaoSocial}
            onChangeDate={this.handleChangeDate}
            onChangeField={this.handlePesquisaFieldChange}
            emailContabilidade={pesquisa.email}
            pesquisar={this.pesquisar}
            enviarParaContabilidade={this.enviarParaContabilidade}
          />

          <div className="form-group" style={{marginTop:10}}>
            <Link to="/cadastrarNotaFiscal" className="btn btn-primary mr-1">
              Incluir
            </Link>
          </div>
          <ModalHistoricoNota
            modal={this.state.modalHistorio}
            toggle={this.toggle}
            historico={this.state.historicoNota}
            chave={this.state.chaveNota}
          />

          <ModalCancelarNota
            modal={this.state.modalCancelarNota}
            toggle={this.toggleModalCancelarNota}
            nota={this.state.notaCancelamento}
            recarregarNotas={this.recarregarNotas}
          />

          <ModalCartaCorrecao
            modal={this.state.modalCartaCorrecaoNota}
            toggle={this.toggleModalCartaCorrecao}
            nota={this.state.notaCancelamento}
            recarregarNotas={this.recarregarNotas}
          />
          <LoadingOverlay active={loading} spinner>
          <div className="card" style={{ width: "100%" }}>
              <div className="card-body">
                <div className="row">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Nº </th>
                        <th scope="col">Data Emissão</th>
                        <th scope="col">Autorizado</th>
                        <th scope="col">Destinatário</th>
                        <th scope="col">UF</th>
                        <th scope="col" width="15%">
                          CNPJ/CPF
                        </th>
                        <th scope="col">Valor</th>
                        <th scope="col" width="20%">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {notas.map(n => {
                        var dtComparacaEmissao = new Date(n.dtEmissao);
                        dtComparacaEmissao.setDate(dtComparacaEmissao.getDate() + 1);
                        return <tr key={n.idNotaFiscal}>
                          <td>{n.idTipoDocumento}</td>
                          <td>{n.numeroNota}</td>
                          <td>
                            {new Intl.DateTimeFormat("pt-BR").format(
                              new Date(n.dtRegistro)
                            )}
                          </td>
                          <td>
                            {n.dtEmissao
                              ? new Intl.DateTimeFormat("pt-BR").format(
                                  new Date(n.dtEmissao)
                                )
                              : "-"}
                          </td>
                          <td>{n.destinatario.nomeRazaoSocial}</td>
                          <td>{n.destinatario.municipio.uf.sigla}</td>
                          <td>
                            {n.destinatario.cpfCnpj.length > 11
                              ? n.destinatario.cpfCnpj.replace(
                                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                                  "$1.$2.$3/$4-$5"
                                )
                              : n.destinatario.cpfCnpj.replace(
                                  /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                  "$1.$2.$3-$4"
                                )}
                          </td>
                          <td>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(n.valorTotal)}
                          </td>
                          <td style={{width: "22%"}}>
                            {n.idStatusSistema === 0 && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Editar"
                                onClick={() =>
                                  this.props.history.push(
                                    `/cadastrarNotaFiscal/${n.idNotaFiscal}`
                                  )
                                }
                              >
                                <i className="fa fa-edit" />
                              </button>
                            )}

                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              title="Histórico"
                              onClick={() =>
                                this.carregarHistorico(n.nfeChave, n.notaFiscalHistorico)
                              }
                              style={{ marginLeft: "5px" }}
                            >
                              <i className="fa fa-history" />
                            </button>

                            {n.idStatusSistema === 0 && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Enviar"
                                onClick={() => this.enviarNotaReceita(n)}
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fa fa-paper-plane" />
                              </button>
                            )}
                            
                            { ((n.idTipoDocumento == 55 && n.idStatusSistema === 100 && dtComparacaEmissao > new Date()) || (n.idTipoDocumento == 1 && n.idStatusSistema === 100)) && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Cancelar"
                                onClick={() => this.cancelarNota(n)}
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fa fa-window-close" />
                              </button>
                            )}

                            {n.idStatusSistema === 100 && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Carta Correcao"
                                onClick={() => this.cartaCorrecao(n)}
                                style={{ marginLeft: "5px", backgroundColor: "red", borderColor : "red"  }}
                              >
                                <i className="fa fa-envelope" />
                              </button>
                            )}

                            {(n.idStatusSistema === 100 ||
                              n.idStatusSistema === 101) && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Download"
                                onClick={() => this.preVisualizar(n)}
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fa fa-file" />
                              </button>
                            )}

                            {(n.idStatusSistema === 100 ||
                              n.idStatusSistema === 101) && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Danfe"
                                onClick={() => this.visualizarPDF(n)}
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fa fa-file-pdf-o" />
                              </button>
                            )}

                            {(n.idStatusSistema === 100 ||
                              n.idStatusSistema === 101) && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                title="Copiar Dados"
                                onClick={() =>
                                  this.props.history.push(
                                    `/cadastrarNotaFiscalCopia/${n.idNotaFiscal}/copiar`
                                  )
                                }
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fa fa-files-o" />
                              </button>
                            )}
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </LoadingOverlay>
          <Paginacao
            totalRegistro={totalRegistro}
            pageSelected={pageSelected}
            numRegistro={numRegistro}
            handleClick={this.paginar}
          />
        </div>
    );
  }
}

export default Notafiscal;
