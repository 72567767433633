import React, { Component } from "react";
import serialize from "form-serialize";
import ApiServiceCertificado from "../utils/ApiServiceCertificado";
import ReactLoading from "react-loading";
import LoadingOverlay from "react-loading-overlay";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

class Certificado extends Component {
  state = {
    certificado: null,
    file: null,
    loading: false,
    certificadoUpload: null,
    error: [],
    token: this.props.match.params.id
  };

  handleFileChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        certificadoUpload: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    var form = serialize(e.target, { hash: true });
    var api = new ApiServiceCertificado();
    api
      .atualizarConfiguracaoCertificadoCadastro({
        ...form,
        Certificado: this.state.certificadoUpload.split(",")[1]
      })
      .then(x => {
        if (x.error && x.error.count > 0) {
          this.setState({ error: x.error.erros, loading: false});
        } else {
          alert("Certificado cadastrado com sucesso.");
          this.setState({ loading: false });
        }
      });
  };

  render() {
    const { certificado, loading, token, error } = this.state;
    return (
      <LoadingOverlay active={loading} spinner>
        <div style={{width: "100vw", display: "flex", overflow: "hidden"}}>
          <form onSubmit={this.handleSubmit}>
            <h5>
            É necessário fazer upload do certificado A1 para que as NFe
                sejam emitidas.
            </h5>
            {loading && (
              <ReactLoading
                type="bubbles"
                color="black"
                height={70}
                width={70}
              />
            )}

            {loading === false && (
              <div style={{ width: "100vw", display: "flex", overflow: "hidden"}}>
                {loading === false && certificado === null && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label><b>Certificado</b></label><br/>
                          <input
                            type="file"
                            name="certificado"
                            required
                            onChange={this.handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label><b>Senha do Certificado</b></label>
                          <input
                            type="text"
                            className="form-control"
                            name="SenhaCertificado"
                            required
                          />
                          <input
                            type="hidden"
                            name="token"
                            value={token}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label><b>Inscrição Estadual</b></label>
                          <input
                            type="text"
                            className="form-control"
                            name="InscricaoEstadual"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {loading === false && certificado != null && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>CNPJ</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={certificado.cnpjFormatado}
                          />
                        </div>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>EMPRESA</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={certificado.empresa}
                          />
                        </div>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>RESPONSÁVEL</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={certificado.responsavel}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="form-group">
              {loading === false && certificado === null && (
                <button
                  type="submit"
                  className="btn btn-primary"
                  variant="contained"
                  required
                >
                  Salvar
                </button>
              )}
            </div>
          </form>
        </div>
        {error.length > 0 && (
                <div className="callout callout-danger" style={{color: "red"}}>
                {error.map((e, c) => (
                    <p>
                    {e}
                    </p>
                ))}
                </div>
            )}
      </LoadingOverlay>
    );
  }
}

export default Certificado;
