import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { BeatLoader } from "react-spinners";

import ApiService from "../utils/ApiService";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function SignUp({ history }) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [cadastro, setDados] = React.useState({
    senha: "",
    nome: null,
    sobrenome: null,
    email: null
  });

  const handleInputChange = e => {
    var tmp = { ...cadastro, [e.target.name]: e.target.value };
    setDados(tmp);
  };

  const cadastrar = e => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      var api = new ApiService();
      api.register(cadastro).then(r => {
        if (r.error && r.error.count > 0) {
          alert(r.error.erros[0]);
        } else {
          alert("Operação realizada com sucesso.");
          history.push("/login");
        }
        setLoading(false);
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Cadastro
        </Typography>
        <form className={classes.form} onSubmit={cadastrar}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="nome"
                variant="outlined"
                required
                fullWidth
                id="nome"
                label="Nome"
                autoFocus
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="sobrenome"
                label="Sobrenome"
                name="sobrenome"
                autoComplete="lname"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="senha"
                label="Senha"
                type="password"
                id="senha"
                autoComplete="current-password"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              Ao prosseguir com o cadastro você concorda o com nossos<br />
                <Link href="TermoUso" variant="body2" target="_blank">
                  Termos e Condições de Uso
                </Link>
                &nbsp; e com nossa  &nbsp;
                <Link href="PoliticaPrivacidade" variant="body2" target="_blank">
                  Política de Privacidade
                </Link> 
                .
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {loading ? (
              <BeatLoader
                sizeUnit={"px"}
                size={10}
                color={"red"}
                loading={loading}
              />
            ) : (
              "Cadastrar"
            )}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="login" variant="body2">
                Você já possui uma conta? Login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
