import React, { Component } from "react";
import "./NavMenu.css";
import SimplesNacional from "./GrupoImposto/simplesNacionalServico";
import SimplesNacionalProduto from "./GrupoImposto/SimplesNacionalProduto";
import ApiServiceGrupoImposto from "./../utils/ApiServiceGrupoImposto";
import serialize from "form-serialize";
import LoadingOverlay from "react-loading-overlay";

class CadastrarGrupoImposto extends Component {
  state = {
      tipoServico: [
      { idTipoServico: 0, tipoServico: "Selecione" },
      { idTipoServico: 2, tipoServico: "Serviço" }
    ],
    loading: false,
    tipoServicoSelected: "2",
    CFOP: [],
    ORIGEM: [],
    ICMS: [],
    IPI: [],
    PIS: [],
    COFINS: [],
    SERVICOS: [],
    error: [],
    defaultCfopField: {
      produto: {
        ENTRADA: 5102,
        SAIDA: 6102
      },
      servico: {
        ENTRADA: 5933,
        SAIDA: 6933
      }
    },
    idGrupoImposto: null,
    grupoImposto: {
          descricao: "",
          idTipo: 0,
          incentivoFiscal: false,
          idServico: 0,
          grupoImpostoItens: []
      }
  };

  componentDidMount() {
    var { idGrupoImposto } = this.state;

    if (this.props.match) {
      idGrupoImposto = this.props.match.params.id
        ? parseInt(this.props.match.params.id, 10)
        : null;

      this.setState({ idGrupoImposto });
    }   

    var apiGrupoImposto = new ApiServiceGrupoImposto();
    apiGrupoImposto.getCFOP().then(d => {
      this.setState({ CFOP: d.data });
    });

    apiGrupoImposto.getORIGEM().then(d => {
      this.setState({ ORIGEM: d.data });
    });

    apiGrupoImposto.getICMS().then(d => {
      this.setState({ ICMS: d.data });
    });

    apiGrupoImposto.getIPI().then(d => {
      this.setState({ IPI: d.data });
    });
    apiGrupoImposto.getPIS().then(d => {
      this.setState({ PIS: d.data });
    });
    apiGrupoImposto.getCOFINS().then(d => {
      this.setState({ COFINS: d.data });
    });

    apiGrupoImposto.getTipoServico().then(d => {
      this.setState({ SERVICOS: d.data });
    });

    if (idGrupoImposto) {
        apiGrupoImposto.getGrupoImpostoAlterar(idGrupoImposto).then(p => {
            var grupoImposto = p.data;
            this.setState({ grupoImposto: p.data, tipoServicoSelected: grupoImposto.idTipo, loading: false });
        });
    }
  }

  alterarCFOP = (tipo, dado, valor) => {
    var defaultCfopField = Object.assign({}, this.state.defaultCfopField);
    defaultCfopField[tipo][dado] = valor;

    this.setState({ defaultCfopField });

    const { idGrupoImposto } = this.state;
    if (idGrupoImposto) {
      var grupoImposto = Object.assign({}, this.state.grupoImposto);
      if (dado == 'ENTRADA') {
        grupoImposto.grupoImpostoItens[0]['cfop'] = valor;
      } else {
        grupoImposto.grupoImpostoItens[1]['cfop'] = valor;
      }
      this.setState({ grupoImposto });  
    }
  }

  alterarDadosGrupoImpostoItem = e => {
    const { idGrupoImposto } = this.state;
    var grupoImposto = Object.assign({}, this.state.grupoImposto);
    
    var posicao = e.target.name.split('][');
     var campo = posicao[1].substring(0, posicao[1].length - 1);
    
     if (idGrupoImposto) {
      var valor = e.target.value
      if (campo.indexOf('aliq') == 0 )
      {
        valor = valor.replace(/[^0-9.,]+/g, "").replace(",",".");
      }

      if (posicao[0].substring(1) == 'ENTRADA') {
        grupoImposto.grupoImpostoItens[0][campo] = valor;
      } else {
        grupoImposto.grupoImpostoItens[1][campo] = valor;
      }
      this.setState({ grupoImposto });  
    } else {
      if (campo.indexOf('aliq') == 0 )
      {
        e.target.value = e.target.value.replace(/[^0-9.,]+/g, "").replace(",",".");
      }
    }
  };

  handleInputChange = e => {
    var grupoImposto = Object.assign({}, this.state.grupoImposto);
      if (e.target.type === 'checkbox') {
          grupoImposto[e.target.name] = e.target.checked;
      } else {
          grupoImposto[e.target.name] = e.target.value;
      }
      
      this.setState({
          grupoImposto
      });
    };

  handleSubmit = e => {
    e.preventDefault();
    
    const { idGrupoImposto } = this.state;
    var form = serialize(e.target, { hash: true });
    var api = new ApiServiceGrupoImposto();
    if (idGrupoImposto) {
        var obj = {
          idGrupoImposto: idGrupoImposto,
          descricao: "",
          idTipo: 0,
          idServico: 0,
          incentivoFiscal: false,
          grupoImpostoItens: []
        };

        obj.grupoImpostoItens.push(form.ENTRADA);
        obj.grupoImpostoItens.push(form.SAIDA);
        obj.descricao = form.descricao;
        obj.idTipo = this.state.tipoServicoSelected;
        obj.idServico = form.idServico
        if (this.state.tipoServicoSelected === "2") {
            obj.incentivoFiscal = form.incentivoFiscal ? true : false;
        }
    
        api.alterarGrupoImposto(obj).then(r => {
            if (r.error && r.error.count > 0) {
                this.setState({ error: r.error.erros });
            } else {
                alert("Operação realizada com sucesso.")
                this.props.history.push('/grupoImpostos')
            }
        });
    } else {
        var obj = {
          descricao: "",
          idTipo: 0,
          idServico: 0,
          incentivoFiscal: false,
          grupoImpostoItens: []
        };

        obj.grupoImpostoItens.push(form.ENTRADA);
        obj.grupoImpostoItens.push(form.SAIDA);
        obj.descricao = form.descricao;
        obj.idTipo = this.state.tipoServicoSelected;
        obj.idServico = form.idServico
        if (this.state.tipoServicoSelected === "2") {
            obj.incentivoFiscal = form.incentivoFiscal ? true : false;
        }
  
        api.incluirGrupoImposto(obj).then(r => {
          if (r.error && r.error.count > 0) {
            this.setState({ error: r.error.erros });
          } else {    
            alert("Operação realizada com sucesso.")
            this.props.history.push('/grupoImpostos')
          }
        });
    }
    this.setState({ loading: false });
    //console.log(form);
    //console.log(obj);
  };

  render() {
    const {
      grupoImposto,
      tipoServico,
      tipoServicoSelected,
      CFOP,
      ORIGEM,
      ICMS,
      IPI,
      PIS,
      COFINS,
      SERVICOS,
      loading,
      error,
      defaultCfopField
    } = this.state;
      
    return (
        <div className="container">
          <LoadingOverlay active={loading} spinner>
            {error.length > 0 && (
                <div className="callout callout-danger">
                {error.map((e, c) => (
                    <p>
                    {c + 1} - {e}
                    </p>
                ))}
                </div>
            )}
            <form onSubmit={this.handleSubmit}>
                <div className="card">
                <div className="card-header">Grupo de impostos</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-8">
                            <div className="form-group ">
                                <label>Descrição do Grupo</label>
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        value={grupoImposto.descricao}
                                        className="form-control"
                                        name="descricao"
                                        onChange={this.handleInputChange}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group ">
                        <label>Tipo</label>
                        <div className="form-group">
                            <select
                            className="form-control"
                            value={tipoServicoSelected}
                            onChange={e =>
                                this.setState({ tipoServicoSelected: e.target.value })
                            }
                            >
                            {tipoServico.map(s => (
                                <option value={s.idTipoServico} key={s.idTipoServico}>
                                {s.tipoServico}
                                </option>
                            ))}
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
                    {tipoServicoSelected == "2" && (
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-8">
                        <div className="form-group">
                            <label>Tipo do Serviço</label>
                            <select 
                                className="form-control" 
                                value={grupoImposto.idServico} 
                                name="idServico"
                                onChange={this.handleInputChange}>
                            {SERVICOS.map(c => (
                                <option key={c.idServico} value={c.idServico}>
                                { c.codigoFormatado + ' - ' + c.descricao.substring(0, 100)}
                                </option>
                            ))}
                            </select>
                        </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                            <label className="checkbox">
                            <input
                                checked={grupoImposto.incentivoFiscal}
                                type="checkbox" 
                                name="incentivoFiscal"
                                onChange={this.handleInputChange}/>
                            <span className="check-mark" /> Incentivo Fiscal
                            </label>
                        </div>
                        </div>
                    </div>
                    )}
                </div>
                </div>
                {tipoServicoSelected == "2" && (
                <div className="row">
                    <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                        Impostos aplicados para o grupo ( ENTRADA )
                        </div>
                        <div className="card-body">
                        <SimplesNacional
                            handleInputChangeItem={this.alterarDadosGrupoImpostoItem}
                            grupoImpostoItem={grupoImposto.grupoImpostoItens[0]}
                            CFOP={CFOP.filter(x => x.entrada === true)}
                            ORIGEM={ORIGEM}
                            PIS={PIS}
                            COFINS={COFINS}
                            NOMECAMPO="ENTRADA"
                            CFOPValueSelect={defaultCfopField.servico.ENTRADA}
                            onAlterarCFOP={this.alterarCFOP}
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                        Impostos aplicados para o grupo ( SAIDA )
                        </div>
                        <div className="card-body">
                        <SimplesNacional
                            handleInputChangeItem={this.alterarDadosGrupoImpostoItem}
                            grupoImpostoItem={grupoImposto.grupoImpostoItens[1]}
                            CFOP={CFOP.filter(x => x.entrada === false)}
                            ORIGEM={ORIGEM}
                            PIS={PIS}
                            COFINS={COFINS}
                            NOMECAMPO="SAIDA"
                            CFOPValueSelect={defaultCfopField.servico.SAIDA}
                            onAlterarCFOP={this.alterarCFOP}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                )}
                {tipoServicoSelected == "1" && (
                <div className="row">
                    <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                        Impostos aplicados para o grupo ( ENTRADA )
                        </div>
                        <div className="card-body">
                        <SimplesNacionalProduto
                            handleInputChangeItem={this.alterarDadosGrupoImpostoItem}
                            grupoImpostoItem={grupoImposto.grupoImpostoItens[0]}
                            CFOP={CFOP.filter(x => x.entrada === true)}
                            ORIGEM={ORIGEM}
                            ICMS={ICMS}
                            IPI={IPI}
                            PIS={PIS}
                            COFINS={COFINS}
                            NOMECAMPO="ENTRADA"
                            CFOPValueSelect={defaultCfopField.produto.ENTRADA}
                            onAlterarCFOP={this.alterarCFOP}
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                        Impostos aplicados para o grupo ( SAIDA )
                        </div>
                        <div className="card-body">
                        <SimplesNacionalProduto
                            handleInputChangeItem={this.alterarDadosGrupoImpostoItem}
                            grupoImpostoItem={grupoImposto.grupoImpostoItens[1]}
                            CFOP={CFOP.filter(x => x.entrada === false)}
                            ORIGEM={ORIGEM}
                            ICMS={ICMS}
                            IPI={IPI}
                            PIS={PIS}
                            COFINS={COFINS}
                            NOMECAMPO="SAIDA"
                            CFOPValueSelect={defaultCfopField.produto.SAIDA}
                            onAlterarCFOP={this.alterarCFOP}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                )}
                {tipoServicoSelected != "0" && (
                <div className="form-group" style={{ display: "flex" }}>
                <button type="submit" 
                  className="btn btn-primary mr-1"
                  style={{ marginTop: 10, marginLeft: "auto" }}
                  >
                    Salvar
                </button>
                </div>
                )}
            </form>
            </LoadingOverlay>
        </div>
    );
  }
}

export default CadastrarGrupoImposto;
