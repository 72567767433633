import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ApiServiceProduto from "./../../utils/ApiServiceProduto";
import Dialog from '@material-ui/core/Dialog';

class ModalProduto extends Component {
  state = {
    NCM: [],
    loading: false
  };

  keypressHandler = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  pesquisaNcm = e => {
    var api = new ApiServiceProduto();
    if (e.target.value !== "") {
      this.setState({ loading: true });

      api.getProdutoNCM(e.target.value).then(r => {
        this.setState({ NCM: r.data, loading: false });
      });
    } else {
      this.setState({ NCM: [] });
    }
  };
  render() {
    const { NCM, loading } = this.state;    
    return (
      <div>
        <Dialog           
          open={this.props.modal}          
          className={this.props.className}
          backdrop={this.props.backdrop}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar NCM"
              onBlur={this.pesquisaNcm}
              onKeyPress={this.keypressHandler}
            />
          </ModalHeader>
          <ModalBody>
            {loading ? (
              <div>Carregando..</div>
            ) : (
              <div>
                {NCM.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Item</th>
                      </tr>
                    </thead>
                    <tbody>
                      {NCM.map(n => (
                        <tr key={n.idNCM}>
                          <td 
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.props.selecionarItem(n.idNCM, n.descricao)
                            }
                          >
                            {n.descricao}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>Use o campo acima para pesquisar.</div>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Dialog>
      </div>
    );
  }
}

export default ModalProduto;
