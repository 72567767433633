import ApiService from "./ApiService";

export default class ApiServiceGrupoImposto extends ApiService {
  constructor(props) {
    super();
  }

  fetch(url, options) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
    }

    return fetch(`api/v${this.version}/` + url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      return response;
    }
  }

  getCFOP() {
    return this.fetch(`Sistema/SisCFOP`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getORIGEM() {
    return this.fetch(`Sistema/SisOrigem`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getICMS() {
    return this.fetch(`Sistema/SisICMS`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getIPI() {
    return this.fetch(`Sistema/SisIPI`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  getPIS() {
    return this.fetch(`Sistema/SisPIS`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  getCOFINS() {
    return this.fetch(`Sistema/SisCOFINS`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getTipoServico() {
    return this.fetch(`Sistema/SisServico`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getGrupoImposto() {
    return this.fetch(`GrupoImposto/Empresa`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getGrupoImpostoAlterar(id) {
    return this.fetch(`GrupoImposto/${id}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  excluir(id) {
    return this.fetchSemJson(`GrupoImposto?id=${id}`, {
      method: "DELETE"      
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  incluirGrupoImposto(body) {
    return this.fetch(`GrupoImposto`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

    alterarGrupoImposto(body) {
    return this.fetch(`GrupoImposto/${body.idGrupoImposto}`, {
      method: "PUT",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
}
