import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { connect } from "react-redux";
import { loginUser } from "./../actions/index";

import ApiService from "../utils/ApiService";
import serialize from "form-serialize";

import logo from '../components/assets/img/nfe_logo_transp.png';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function Login({ logar, history }) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [erros, setErros] = React.useState([]);

  const handleSubmit = e => {
    e.preventDefault();
    
    setLoading(true);
    var api = new ApiService();
    var form = serialize(e.target, { hash: true });
    try {
      api.login(form.email, form.senha).then(x => {
        if (x.error) {
          setHasError(true);
          setErros(x.error.erros);
        } else {
          setHasError(false);
          logar({
            user: {
              token: x.data.token,
              isAuthenticated: true,
              nome: x.data.nome,
              sobrenome: x.data.sobrenome
            }
          });
          history.push(`/`);
        }
        //setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <div><img src={logo} width={400} height={130} /></div>
        {hasError && (
          <div>
            <ul>
              {erros.map(e => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </div>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail ou CNPJ"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="RecuperarSenha" variant="body2">
                Esqueceu a Senha?
              </Link>
            </Grid>
            <Grid item>
              <Link href="Register" variant="body2">
                Não tem conta? Cadastre-se
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

function mapStateToProps({ user }) {
  return user;
}

function mapDispatchToProps(dispatch) {
  return {
    logar: data => dispatch(loginUser(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
