import ApiService from "./ApiService";

export default class ApiServiceCertificado extends ApiService {
  constructor(props) {
    super();
  }

  getCertificado() {
    return this.fetch(`Configuracao/DadosCertificado`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  removerCertificado(){
    return this.fetchSemJson(`Configuracao/RemoverCertificado`, {
      method: "DELETE"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  atualizarConfiguracaoCertificado(body) {
    return this.fetch(`Configuracao/AtualizaCertificado`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  atualizarConfiguracaoCertificadoCadastro(body) {
    return this.fetch(`Certificado/AtualizaCertificadoCadastro`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
}
