import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ApiServiceNota from '../../utils/ApiServiceNota';
import { BeatLoader } from 'react-spinners';

class ModalCancelarNota extends Component {
    state = {
        loading: false,
        nota: {},
        motivoCancelamento: ""
    };

    cancelarNota = nota => {
        if ( this.state.motivoCancelamento.length >= 15){
            this.setState({ loading: true })
            var api = new ApiServiceNota();
            var notaTmp = Object.assign({}, nota);
            notaTmp["motivoCancelamento"] = this.state.motivoCancelamento;
            this.setState({ nota: notaTmp });
            api.CancelarNota(notaTmp).then(r => {
                this.setState({ loading: false })
                this.props.recarregarNotas();
            });                
        } else {
            alert("O campo motivo do cancelamento devo possuir no mínimo 15 caracteres.")
        }
    };


    render() {
        const { loading } = this.state;
        const { nota } = this.props;
        return (
            <div>
                <Modal
                    onOpened={this.onOpened}
                    isOpen={this.props.modal}
                    toggle={this.props.toggle}
                    className={this.props.className}
                    backdrop={this.props.backdrop}
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle}>
                        <h4>Cancelar Nota : {nota.nfeChave}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="form-group ">
                                    <label>Motivo de cancelamento</label>
                                    <div className="form-group ">
                                        <input type="text" required className="form-control" value={this.state.motivoCancelamento} name="motivoCancelamento" onChange={(e) => this.setState({ motivoCancelamento: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.loading && (
                            <Button color="primary">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={10}
                                    color={'#123abc'}
                                    loading={loading}
                                />
                            </Button>
                        )}

                        {!this.state.loading && (
                            <Button color="primary" onClick={() => this.cancelarNota(nota)}>
                                Cancelar Nota
                            </Button>
                        )}

                        <Button color="secondary" onClick={this.props.toggle}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }
}

export default ModalCancelarNota;