import React, { Component } from "react";
import ApiServiceGrupoImposto from "./../utils/ApiServiceGrupoImposto";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

class GrupoImpostos extends Component {
  state = {
    grupoImposto: [],
    loading: true
  };
  componentDidMount() {
    this.carregar();
  }

  redirectEdit = id => {
    this.props.history.push(`/cadastrarGrupoImposto/${id}`);
  };

  carregar(){
    var api = new ApiServiceGrupoImposto();
    api.getGrupoImposto().then(x => {
      this.setState({ grupoImposto: x.data, loading: false });
    });
  }

  exlcuir = id => {
    var api = new ApiServiceGrupoImposto();
    this.setState({ loading: true })
    api.excluir(id).then(() => {
      this.carregar();
    })
  };

  render() {
    const { loading, grupoImposto } = this.state;
    return (
        <div className="container">
          <LoadingOverlay active={loading} spinner>
          <div className="form-group">
            <Link to="/cadastrarGrupoImposto" className="btn btn-primary mr-1">
              Incluir
          </Link>
          </div>
          <div className="card" style={{ width: "100%" }}>
              <div className="card-header">Lista de Grupo de Impostos</div>
              <div className="card-body">
                <div className="row">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descrição</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {grupoImposto.map(i => (
                        <tr key={i.idGrupoImposto}>
                          <td>{i.idTipo === 1 ? "Produto" : "Serviço"}</td>
                          <td>{i.descricao}</td>
                          <td><button onClick={() => { this.redirectEdit(i.idGrupoImposto) }}>Editar</button></td>
                          <td>
                            <button onClick={() => { this.exlcuir(i.idGrupoImposto) }}>Remover</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      );
  }
}

export default GrupoImpostos;