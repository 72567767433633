import ApiService from "./ApiService";

export default class ApiServicePagSeguro extends ApiService {
  constructor(props) {
    super();
  }

  iniciarTransacao() {
    return this.fetch(`PagSeguro/IniciarTransacao`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  criarPagamento(body) {
    return this.fetch(`PagSeguro/CriarPagamento`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
}
