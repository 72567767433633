import * as decode from "jsonwebtoken";

export default class ApiService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || ""; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.version = 1;
  }

  login(username, password) {
    return this.fetch(`Usuario/Login`, {
      method: "POST",
      body: JSON.stringify({
        Email: username,
        Senha: password
      })
    }).then(res => {
      console.log(!res.error);
      if (!res.error) this.setToken(res.data);

      return Promise.resolve(res);
    });
  }

  RecuperarSenha(email) {
    return this.fetch(`Usuario/Recuperar`, {
      method: "POST",
      body: JSON.stringify({
        email
      })
    }).then(res => {
      console.log(!res.error);
      if (!res.error) this.setToken(res.data);

      return Promise.resolve(res);
    });
  }

  register(body) {
    return this.fetch(`Usuario/Register`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  
  AlterarSenha(body) {
    return this.fetch(`Usuario/AlterarSenha`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode.decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(data) {
    localStorage.setItem("id_token", data.token);
    localStorage.setItem("nome", data.nome);
    localStorage.setItem("sobrenome", data.sobrenome);
    localStorage.setItem("email", data.email);
    localStorage.setItem("cnpj", data.cnpj);
    localStorage.setItem("nomefantasia", data.nomeFantasia);
  }

  getToken() {
    return localStorage.getItem("id_token");
  }

  getNome() {
    return localStorage.getItem("nome");
  }

  getSobrenome() {
    return localStorage.getItem("sobrenome");
  }

  getEmail() {
    return localStorage.getItem("email");
  }

  getCnpj() {
    return localStorage.getItem("cnpj");
  }

  getNomeFantasia() {
      return localStorage.getItem("nomefantasia");
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("nome");
    localStorage.removeItem("sobrenome");
    localStorage.removeItem("email");
    localStorage.removeItem("cnpj");
      localStorage.removeItem("nomefantasia");
  }

  getProfile() {
    //return decode(this.getToken());
    return "";
  }

  obterConfiguracoes() {
    return this.fetch(`Configuracao`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterUFs() {
    return this.fetch(`Sistema/UF`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterMunicipio(idUF) {
    return this.fetch(`Sistema/MunicipioByUF/${idUF}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterContadores() {
    return this.fetch(`Cliente/contadores`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterEmpresa() {
    return this.fetch(`Empresa`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  atualizarCadastroEmpresa(body) {
    return this.fetch(`Empresa`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  atualizarConfiguracao(body) {
    return this.fetch(`Configuracao`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterAgendamentos(data) {
    return this.fetch(`Agendamento/AgendamentoRealizado/${data}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterAgendamentosMarcacao(data) {
    return this.fetch(`Agendamento/data?data=${data}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  carregarCliente(idCliente) {
    return this.fetch(`Cliente/${idCliente}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  consultarCliente(campanha, unidade, ordenacao, busca) {
    return this.fetch(
      `Cliente/campanha?campanha=${campanha}&unidade=${unidade}&ordenacao=${ordenacao}&busca=${busca}`,
      {
        method: "GET"
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  obterStatusCadastro(body) {
    return this.fetch(`Configuracao/Status`, {
      method: "GET",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  carregarIndicado(idIndicado) {
    return this.fetch(`Indicacao/${idIndicado}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  inserirHistoricoAtendimento(body) {
    return this.fetch(`HistoricoAtendimento/HistoricoAtendimentoInserir`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  AtualizarAgendamento(body) {
    return this.fetch(`Agendamento`, {
      method: "PUT",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  obterHistoricoCliente(idCliente) {
    return this.fetch(`HistoricoAtendimento/HistoricoCliente/${idCliente}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  fetch(url, options) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
    }

    return fetch(`api/v${this.version}/` + url, {
      headers,
      ...options
    }).then(response => response.json());
  }

  fetchSemJson(url, options) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
    }

    return fetch(`api/v${this.version}/` + url, {
      headers,
      ...options
    }).then(this._checkStatus);
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      console.log(response);
      var msg = "";
      if (response.statusText) msg = response.statusText;

      var error = new Error(msg);
      error.response = response;
      throw error;
    }
  }
}
