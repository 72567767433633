import React, { Component } from "react";
import ApiServicePagSeguro from "../utils/ApiServicePagSeguro";
import ApiViaCep from "../utils/ApiViaCep";
import "./cartaoCredito.css";
import LoadingOverlay from "react-loading-overlay";
import ApiServicePacote from "../utils/ApiServicePacote";
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
import Home from "./Home";

class Comprar extends Component {
    state = {
        loading: true,
        anos: [],
        pacote: {},
        id_pacote: this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : null,
        payment: {
            mode: "default",
            method: "boleto",
            sender: {
                name: "",
                email: "",
                phone: {
                    areaCode: "",
                    number: ""
                },
                documents: {
                    document: {
                        type: "CPF",
                        value: ""
                    }
                },
                hash: "{{hash_do_comprador}}"
            },
            currency: "BRL",
            extraAmount: "0.00",
            reference: "R123456",
            shippingAddressRequired: "false",
            creditCard: {
                token: "{{token_do_cartao}}",
                holder: {
                    name: "",
                    documents: {
                        document: {
                            type: "CPF",
                            value: ""
                        }
                    },
                    birthDate: "",
                    phone: {
                        areaCode: "",
                        number: ""
                    }
                },
                billingAddress: {
                    street: "",
                    number: "",
                    complement: "",
                    district: "",
                    city: "",
                    state: "",
                    country: "BRA",
                    postalCode: ""
                }
            }
        },
        card: {
            maxLength: 19,
            cardNumber: "",
            brand: "",
            cvv: "",
            expirationMonth: "",
            expirationYear: ""
        }
    };

    pesquisarCEP = e => {
        var cep = e.target.value;
        var payment = { ...this.state.payment };
        var api = new ApiViaCep();
        if (cep.length == 10) {
            payment.creditCard.billingAddress.postalCode = cep;
            this.setState(payment);
            api.pesquisarCep(cep.replace(/[^0-9]+/g, "")).then(r => {
                console.log(r);
                if (r.erro || r == '') {
                    alert("Cep não encontrado.");
                } else {
                    payment.creditCard.billingAddress.street = r.address;
                    payment.creditCard.billingAddress.number = "";
                    payment.creditCard.billingAddress.complement = "";
                    payment.creditCard.billingAddress.district = r.district;
                    payment.creditCard.billingAddress.city = r.city;
                    payment.creditCard.billingAddress.state = r.state;
                    payment.creditCard.billingAddress.postalCode = cep;
                    this.setState(payment);
                }
            });
        } else {
            payment.creditCard.billingAddress.postalCode = cep ;
            this.setState(payment);
        }
    };

    alterarDataNascimento = e => {
        var payment = { ...this.state.payment };
        var valor = e.target.value;
        payment.creditCard.holder.birthDate = valor;
        this.setState(payment);
    };

    alterarMetodoPagameto = e => {
        var payment = { ...this.state.payment };
        payment.method = e;
        this.setState({ payment });
    }

    alterarCPFCNPJ = e => {
        var payment = { ...this.state.payment };
        var valor = e.target.value;
        var tipoPessoa = ""
        if (valor.replace(/[^0-9]+/g, "").length > 11) {
            tipoPessoa = "CNPJ"
        } else {
            tipoPessoa = "CPF"
        }
        
        payment.creditCard.holder.documents.document.value = valor
        payment.creditCard.holder.documents.document.type = tipoPessoa
        payment.sender.documents.document.value = valor;
        payment.sender.documents.document.type = tipoPessoa;
        this.setState({ payment });
    }

    setSenderHash = () => { };

    componentDidMount() {

        const { id_pacote } = this.state;
        if (id_pacote) {
            var apiPacote = new ApiServicePacote();
            apiPacote.Carregar(id_pacote).then(x => {
                this.setState({ pacote: x.data });
            })
        }

        this.obterAnosCartao();
        var apiPagSeguro = new ApiServicePagSeguro();

        apiPagSeguro.iniciarTransacao().then(x => {
            window.PagSeguroDirectPayment.setSessionId(x);
            this.setState({ loading: false });
        });

        var options = {
            onChange : function(cpfcnpj, e, field, options) {
                var masks = ['000.000.000-000', '00.000.000/0000-00'];
                var mask = (cpfcnpj.length > 14) ? masks[1] : masks[0];
                $('.cpfcnpj').mask(mask, options);
            },
            onInput : function(cpfcnpj, e, field, options) {
                var masks = ['000.000.000-000', '00.000.000/0000-00'];
                var mask = (cpfcnpj.length > 14) ? masks[1] : masks[0];
                $('.cpfcnpj').mask(mask, options);
            }
        };

        $('.cpfcnpj').mask('00.000.000/0000-00', options);
        $('.telefone').mask('000000000', { reverse: false });
        $('.cep').mask('00.000-000', { reverse: false });
        $('.data').mask('00/00/0000', { reverse: false });
        $('.numero').mask('0000000');
        $('.ddd').mask('000');
    }

    obterAnosCartao = () => {
        var anos = [];
        var year = new Date().getFullYear();
        for (var index = year; index < year + 10; index++) {
            anos.push(index);
        }
        this.setState({ anos });
    };

    onChangeInput = e => { };

    clearCardNumber = () => {
        var card = { ...this.state.card };
        card.cardNumber = "";
        this.setState({ card });
    };

    obterBrandCard(cardBin) {
        return new Promise((resolve, reject) => {
            window.PagSeguroDirectPayment.getBrand({
                cardBin,
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }
            });
        });
    }

    onHandleChangeCardInfo = e => {
        var card = Object.assign({}, this.state.card);
        card[e.target.name] = e.target.value;
        if (e.target.name == "cardNumber") {
            var cardNumber = e.target.value;
            if (cardNumber.length >= 6) {
                this.obterBrandCard(cardNumber.slice(0, 6)).then(
                    x => {
                        var acceptedLengths = x.brand.config.acceptedLengths;
                        card["brand"] = x.brand.name;
                    },
                    () => {
                        alert("Cartão Inválido");
                        this.clearCardNumber();
                    }
                );
            }
        }
        this.setState({ card });
    };

    obterTokenCartao(cardNumber, brand, cvv, expirationMonth, expirationYear) {
        return new Promise((resolve, reject) => {
            window.PagSeguroDirectPayment.createCardToken({
                cardNumber,
                brand,
                cvv,
                expirationMonth, // Mês da expiração do cartão
                expirationYear,
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }
            });
        });
    }

    solicitarPagamento = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const { card } = this.state;
        var payment = { ...this.state.payment };
        payment.creditCard.holder.documents.document.value = payment.creditCard.holder.documents.document.value.replace(/[^0-9]+/g, "")
        payment.sender.documents.document.value = payment.creditCard.holder.documents.document.value.replace(/[^0-9]+/g, "");
        payment.creditCard.billingAddress.postalCode = payment.creditCard.billingAddress.postalCode.replace(/[^0-9]+/g, "");

        if(payment.method == "boleto") {
            payment.sender.hash = window.PagSeguroDirectPayment.getSenderHash();
            this.setState(payment);

            var apiPagSeguro = new ApiServicePagSeguro();
            const { id_pacote } = this.state;

            apiPagSeguro.criarPagamento({ id_pacote, payment }).then(x => {
                if (x.error && x.error.count > 0) {
                    alert(x.error.erros[0]);
                    this.setState({ loading: false });
                } else {
                    alert("Boleto gerado com sucesso.");
                    this.props.history.push("/historicoCompra");
                }            
            });
        } else {
            this.obterTokenCartao(
                card.cardNumber,
                card.brand,
                card.cvv,
                card.expirationMonth,
                card.expirationYear
            ).then(x => {
                payment.creditCard.token = x.card.token;
    
                payment.sender.hash = window.PagSeguroDirectPayment.getSenderHash();
                this.setState(payment);
    
                var apiPagSeguro = new ApiServicePagSeguro();
                const { id_pacote } = this.state;
    
                apiPagSeguro.criarPagamento({ id_pacote, payment }).then(x => {
                    if (x.error && x.error.count > 0) {
                        alert(x.error.erros[0]);
                        this.setState({ loading: false });
                    } else {
                        alert("Pagamento efetuado com sucesso.");
                        this.props.history.push("/historicoCompra");
                    }            
                });
            });
        }
    };

    render() {
        const { payment, anos, card, loading } = this.state;
        return <Home />;
        return (
            <LoadingOverlay active={loading} spinner>
                <form onSubmit={this.solicitarPagamento}>
                    <div className="row" style={{ marginBottom: "30px" }}>
                        <div className="col-sm-9 offset-md-1">
                            <h2>Dados de cobrança</h2>
                            <div className="alert alert-info">
                                {/* Caso o método de pagamento escolhido seja <b>CARTÃO DE CRÉDITO</b>
                  , os dados de cobrança devem ser os mesmos do títular do cartão e */}
                                Os dados de cobrança devem ser os mesmos do títular do cartão e
                    o endereço deve ser o mesmo onde a fatura é entregue.{" "}
                                <b>Não aceitamos CARTÃO DE DÉBITO</b>.
                  </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Nome Completo</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={payment.sender.name}
                                            required
                                            onChange={e => {
                                                var payment = { ...this.state.payment };
                                                payment.sender.name = e.target.value;
                                                this.setState(payment);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label>CPF/CNPJ</label>
                                    <input
                                        className="form-control cpfcnpj"
                                        type="text"
                                        value={payment.sender.documents.document.value}
                                        required
                                        onInput={this.alterarCPFCNPJ}
                                        onChange={this.alterarCPFCNPJ}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <label>Data de Nascimento</label>
                                    <input
                                        className="form-control data"
                                        type="text"
                                        
                                        onChange={this.alterarDataNascimento}
                                        value={payment.creditCard.holder.birthDate}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <label>DDD</label>
                                    <input
                                        className="form-control ddd"
                                        type="text"
                                        value={payment.sender.phone.areaCode}
                                        required
                                        onChange={e => {
                                            var payment = { ...this.state.payment };
                                            payment.creditCard.holder.phone.areaCode = e.target.value;
                                            payment.sender.phone.areaCode = e.target.value;
                                            this.setState(payment);
                                        }}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <label>Telefone</label>
                                    <input
                                        className="form-control telefone"
                                        type="text"
                                        value={payment.sender.phone.number}
                                        required
                                        onChange={e => {
                                            var payment = { ...this.state.payment };
                                            payment.creditCard.holder.phone.number = e.target.value;
                                            payment.sender.phone.number = e.target.value;
                                            this.setState(payment);
                                        }}
                                        onInput={e => {
                                            var payment = { ...this.state.payment };
                                            payment.creditCard.holder.phone.number = e.target.value;
                                            payment.sender.phone.number = e.target.value;
                                            this.setState(payment);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <label>CEP</label>
                                    <input
                                        className="form-control cep"
                                        type="text"
                                        onInput={this.pesquisarCEP}
                                        required
                                        value={payment.creditCard.billingAddress.postalCode}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label>Endereço</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={payment.creditCard.billingAddress.street}
                                        required
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <label>Número</label>
                                    <input
                                        className="form-control numero"
                                        type="text"
                                        value={payment.creditCard.billingAddress.number}
                                        required
                                        onChange={e => {
                                            var payment = { ...this.state.payment };
                                            payment.creditCard.billingAddress.number = e.target.value;
                                            this.setState(payment);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <label>UF</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={payment.creditCard.billingAddress.state}
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                                <div className="col-sm-5">
                                    <label>Cidade</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={payment.creditCard.billingAddress.city}
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                                <div className="col-sm-5">
                                    <label>Bairro</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={payment.creditCard.billingAddress.district}
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Complemento</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={payment.creditCard.billingAddress.complement}
                                        onChange={e => {
                                            var payment = { ...this.state.payment };
                                            payment.creditCard.billingAddress.complement =
                                                e.target.value;
                                            this.setState(payment);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <span>Método de Pagamento</span>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                            <div>
                                <input
                                    type="radio"
                                    name="tipoPagamento"
                                    checked={payment.method === "boleto" ? true : false}
                                    onChange={() => this.alterarMetodoPagameto("boleto")}
                                />
                                <span style={{ marginLeft: 2 }}>Boleto</span>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <input
                                    type="radio"
                                    name="tipoPagamento"
                                    checked={payment.method === "creditCard" ? true : false}
                                    onChange={() => this.alterarMetodoPagameto("creditCard")}
                                />
                                <span style={{ marginLeft: 2 }}>Cartão de Crédito</span>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        {payment.method === "creditCard" && (
                            <div>
                                <h2 className="text-center">Cartão de Crédito</h2>
                                <div className="row justify-content-center">
                                    <div className="col-sm-3 ">
                                        <label htmlFor="cartao">Número do cartão de crédito:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cardNumber"
                                            required
                                            onChange={this.onHandleChangeCardInfo}
                                            value={card.cardNumber}
                                            maxLength={card.maxLength}
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-3">
                                        <label htmlFor="nomeCartao">Nome (escrito no cartão):</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nomeCartao"
                                            required
                                            onChange={e => {
                                                var payment = { ...this.state.payment };
                                                payment.creditCard.holder.name = e.target.value;
                                                this.setState(payment);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-1">
                                        <label>Validade mês:</label>
                                        <select
                                            className="form-control"
                                            name="expirationMonth"
                                            onChange={this.onHandleChangeCardInfo}
                                            required
                                            value={card.expirationMonth}
                                        >
                                            <option value=""></option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-1">
                                        <label>Validade ano:</label>
                                        <select
                                            className="form-control"
                                            value={payment.creditCard.year}
                                            name="expirationYear"
                                            required
                                            onChange={this.onHandleChangeCardInfo}
                                            value={card.expirationYear}
                                        >
                                            <option value=""></option>
                                            {anos.map(x => (
                                                <option value={x} key={x}>
                                                    {x}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-1">
                                        <label>Cod. segurança:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cvv"
                                            required
                                            onChange={this.onHandleChangeCardInfo}
                                            value={card.cvv}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <hr></hr>
                        <div className="row justify-content-center">
                            <div className="col-sm-6">
                                <label>{this.state.pacote.dS_PACOTE} Por {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(this.state.pacote.vL_PACOTE)}</label>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-sm-3">
                                <button type="submit" className="btn btn-success">
                                    Efetuar Pagamento
                                </button>
                            </div>
                        </div>
                    </div>

                </form>
            </LoadingOverlay>
        );
    }
}

export default Comprar;