import ApiService from "./ApiService";

export default class ApiServicePacote extends ApiService {
    constructor(props) {
        super();
    }

    ObterPacotesAtivos() {
        return this.fetch(`Pacotes`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    Carregar(id) {
        return this.fetch(`Pacotes/${id}`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    ListarCompras() {
        return this.fetch(`PacoteUsuario/Usuario`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

}
