import React, { Component } from "react";

class TipoPagamento extends Component {
  state = {
    FormaPagamento: [
      { id: 1, tipo: "Dinheiro" },
      { id: 2, tipo: "Cheque" },
      { id: 3, tipo: "Cartão Crédito" },
      { id: 4, tipo: "Cartão Débito" },
      { id: 5, tipo: "Crédito Loja" },
      { id: 10, tipo: "Vale Alimentação" },
      { id: 11, tipo: "Vale Refeição" },
      { id: 12, tipo: "Vale Presente" },
      { id: 13, tipo: "Vale Combustível" },
      { id: 14, tipo: "Duplicata Mercantil" },
      { id: 15, tipo: "Boleto Bancário" },
      { id: 90, tipo: "Sem Pagamento" },
      { id: 99, tipo: "Outros" }
    ]
  };

  //   onChange={this.alterarDadosNota}
  //         value={nota.IdFormaPagamento}
  render() {
    const { FormaPagamento } = this.state;
    const { IdFormaPagamento, keyItem } = this.props;
    return (
      <select
        className="form-control"
        name="idFormaPagamento"
        value={IdFormaPagamento}
        onChange={e => this.props.onAlterarDadosDuplicata(keyItem, e)}
      >
        {FormaPagamento.map(f => (
          <option value={f.id} key={f.id}>
            {f.tipo}
          </option>
        ))}
      </select>
    );
  }
}

export default TipoPagamento;
