import React, { Component } from "react";
import { Link } from "react-router-dom";
import ApiServiceCNPJ from "./../utils/ApiServiceCNPJ";
import LoadingOverlay from "react-loading-overlay";

class Destinatarios extends Component {
  state = {
    dest: [],
    loading: true
  };
  componentDidMount() {
    var api = new ApiServiceCNPJ();
    api.getDestinatario().then(d => {
      this.setState({ dest: d.data, loading: false });
    });
  }
  render() {
    const { dest, loading } = this.state;
    return (
      <div className="container">
        <LoadingOverlay active={loading} spinner>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <Link
                to="/cadastrarDestinatario"
                className="btn btn-primary mr-1"
              >
                Incluir
              </Link>
            </div>
            <div className="card" style={{ width: "100%" }}>
              <div className="card-header">Lista de Destinatários</div>
              <div className="card-body">
                <div className="row">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Destinário</th>
                        <th>CPF/CNPJ</th>
                        <th>UF</th>
                        <th>Municipio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dest.map(d => (
                        <tr
                          key={d.idDestinatario}
                          onClick={() =>
                            this.props.history.push(
                              `/cadastrarDestinatario/${d.idDestinatario}`
                            )
                          }
                        >
                          <td>{d.nomeRazaoSocial}</td>
                          <td>
                            {d.cpfCnpj.replace(
                              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                              "$1.$2.$3/$4-$5"
                            )}
                          </td>
                          <td>{d.municipio.uf.descricao}</td>
                          <td>{d.municipio.descricao}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Destinatarios;
