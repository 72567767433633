import ApiService from "./ApiService";

export default class ApiServiceNota extends ApiService {
  constructor(props) {
    super();
  }

  getNotas() {
    return this.fetch(`Nota/Empresa`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getNota(id) {
    return this.fetch(`Nota/${id}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  pesquisarNota(body) {
    return this.fetch(`Nota/NotaFiscalFiltro`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  
  getHistoricoInutilizacao() {
    return this.fetch(`Nota/HistoricoInutilizacaoNumero`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  InutilizarNumeracaoNota(obj) {
    return this.fetch(`Nota/InutilizarNumero`, {
      method: "POST",
      body: JSON.stringify(obj)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  ObterCalculoResumoNota(nota) {
    return this.fetch(`Nota/ResumoTotal`, {
      method: "POST",
      body: JSON.stringify(nota)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  PreProcessarNota(nota) {
    return this.fetch(`Nota/CalcularNota`, {
      method: "POST",
      body: JSON.stringify(nota)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  incluirNota(body) {
    return this.fetch(`Nota/IncluirNota`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  EnviarNota(body) {
    return this.fetch(`Nota/EnviarNota`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  CancelarNota(body) {
    return this.fetch(`Nota/CancelarNota`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  CartaCorrecao(idNotaFiscal, descricao) {
    return this.fetch(`Nota/CartaCorrecao`, {
      method: "POST",
      body: JSON.stringify({ idNotaFiscal, descricao })
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  EnviarContabilidade(body) {
    return this.fetch(`Nota/EnviarEmailContabilidade`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  Atualizar(body) {
    return this.fetch(`Nota/AtualizarNota`, {
      method: "PUT",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getPDF(body) {
    return this.fetchSemJson(`Nota/ObterPDF`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getPDFVisualizar(body) {
    return this.fetchSemJson(`Nota/ObterPDFVisualizar`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getPDFSemZip(body) {
    return this.fetchSemJson(`Nota/ObterPDFSemZip`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  validarNota(body) {
    return this.fetch(`Nota/ValidarNota`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
}
