import React, { Component } from "react";

class SimplesNacionalServico extends Component {
  render() {
      const {
      grupoImpostoItem,
      CFOP,
      loading,
      ORIGEM,
      ICMS,
      IPI,
      PIS,
      COFINS,
      NOMECAMPO,
      CFOPValueSelect
    } = this.props;
    return (
      <div>
        <div className="row">
        <input type="hidden" name={"[" + NOMECAMPO + "][idGrupoImpostoItem]"} value={grupoImpostoItem == null ? null : grupoImpostoItem.idGrupoImpostoItem} />
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>CFOP</label>
              <div className="form-group ">
                {!loading && (
                  <select
                    defaultValue={CFOPValueSelect}
                    className="form-control"
                    name={"[" + NOMECAMPO + "][cfop]"}
                    onChange={(e) => this.props.onAlterarCFOP("servico", NOMECAMPO, e.target.value)}       
                    value={grupoImpostoItem == null ? null : grupoImpostoItem.cfop}
                  >
                    {CFOP.map(c => (
                      <option key={c.cfop} value={c.cfop}>
                        {c.cfop} - {c.descricao.substring(0, 100)}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ORIGEM</label>
              <div className="form-group ">
                {!loading && (
                  <select
                    value={grupoImpostoItem == null ? null : grupoImpostoItem.idOrigem}
                    className="form-control"
                    name={"[" + NOMECAMPO + "][idOrigem]"}
                    onChange={e => {
                      this.props.handleInputChangeItem(e);
                    }}
                  >
                    {ORIGEM.map(c => (
                      <option key={c.idOrigem} value={c.idOrigem}>
                        {c.descricao}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>PIS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqPIS]"}
                      placeholder="%Aliq"
                      required
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqPIS}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        defaultValue="99"
                        className="form-control"
                        name={"[" + NOMECAMPO + "][idPIS]"}
                        onChange={e => {
                          this.props.handleInputChangeItem(e);
                        }}
                        value={grupoImpostoItem == null ? null : grupoImpostoItem.idPIS}
                      >
                        {PIS.map(c => (
                          <option key={c.idPIS} value={c.idPIS}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>COFINS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqCOFINS]"}
                      placeholder="%Aliq"
                      required
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqCOFINS}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group ">
                    {!loading && (
                      <select
                        defaultValue="99"
                        className="form-control"
                        name={"[" + NOMECAMPO + "][idCOFINS]"}
                        onChange={e => {
                          this.props.handleInputChangeItem(e);
                        }}
                        value={grupoImpostoItem == null ? null : grupoImpostoItem.idCOFINS}
                      >
                        {COFINS.map(c => (
                          <option key={c.idCofins} value={c.idCofins}>
                            {c.descricao}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group ">
              <label>ISS</label>
              <div className="row">
                <div className="col">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name={"[" + NOMECAMPO + "][aliqISS]"}
                      placeholder="%Aliq"
                      required="required"
                      onChange={e => {
                        this.props.handleInputChangeItem(e);
                      }}
                      value={grupoImpostoItem == null ? null : grupoImpostoItem.aliqISS}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SimplesNacionalServico;
