import React, { Component } from "react";

export class PoliticaPrivacidade extends Component {
    render() {
      return (
        <div>
          <h3><a name="política-de-privacidade">POLÍTICA DE PRIVACIDADE</a></h3>
          <p><b>SEÇÃO 1 - INFORMAÇÕES GERAIS</b></p>
          <p>A presente Política de Privacidade contém informações sobre
            coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos
            usuários e visitantes do aplicativo ou site Web NFe, com a finalidade de
            demonstrar absoluta transparência quanto ao assunto e esclarecer a todos
            interessados sobre os tipos de dados que são coletados, os motivos da coleta e
            a forma como os usuários podem gerenciar ou excluir as suas informações
            pessoais.</p>
          <p>Esta Política de Privacidade aplica-se a todos os usuários e
            visitantes do aplicativo ou site Web NFe e integra os Termos e Condições Gerais
            de Uso do aplicativo ou site Web NFe, devidamente inscrita no CNPJ sob o nº <span style={{fontSize: '10.5pt', fontFamily: '"Helvetica",sans-serif', color: 'black', letterSpacing: '.1pt', background: 'white'}}>30.715.055/0001-49</span>, situado na
            Quadra EQNL 6/8 Bloco A Sala 203 Taguatinga Norte (Taguatinga) Brasília-DF,
            doravante nominada WALDEN TECNOLOGIA LTDA.</p>
          <p>O presente documento foi elaborado em conformidade com a Lei
            Geral de Proteção de Dados Pessoais (Lei <span lang="EN-US"><a href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"><span><span lang="PT-BR" style={{textDecoration: 'none'}}>13.709</span></span></a></span>/18),
            o <span lang="EN-US"><a href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14"><span><span lang="PT-BR" style={{textDecoration: 'none'}}>Marco Civil da
                    Internet</span></span></a></span> (Lei <span lang="EN-US"><a href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14"><span><span lang="PT-BR" style={{textDecoration: 'none'}}>12.965</span></span></a></span>/14)
            (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado
            em decorrência de eventual atualização normativa, razão pela qual se convida o
            usuário a consultar periodicamente esta seção.</p>
          <p><b>&nbsp;</b></p>
          <p><b>SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO?</b></p>
          <p>Os dados pessoais do usuário são recolhidos pela plataforma
            da seguinte forma:</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}><a name="_Hlk43903163">•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>Quando o usuário cria uma conta na plataforma Web NFe esses dados são os
              dados de identificação básicos do usuário: e-mail e nome completo. A partir
              deles, podemos identificar o usuário, além de garantir uma maior segurança e
              bem-estar às suas necessidade.</a></p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O
              USUÁRIO?</b></p>
          <p>Os dados pessoais do usuário recolhidos são os seguintes:</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Dados para a criação da conta na plataforma Web NFe: e-mail, nome
            completo.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Dados para concretizar inclusão de nota fiscal: dados referentes a
            imposto, destinatários e outros dados da nota fiscal.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Dados para concretizar transações: dados referentes ao pagamento e
            transações, tais como, número do cartão de crédito e outras informações sobre o
            cartão, além dos pagamentos efetuados.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Dados relacionados a contratos: diante da formalização do contrato de
            compra e venda ou de prestação de serviços entre a plataforma e o usuário poderão
            ser coletados e armazenados dados relativos a execução contratual, inclusive as
            comunicações realizada entre a empresa e o usuário.</p>
          <p style={{marginLeft: '24.0pt'}}>&nbsp;</p>
          <p><b>SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS
              PESSOAIS DO USUÁRIO?</b></p>
          <p>Os dados pessoais do usuário coletados e armazenados pela
            plataforma Web NFe tem por finalidade:</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Bem-estar do usuário: aprimorar o produto e/ou serviço oferecido,
            facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a
            empresa, melhorar a experiência dos usuários e fornecer funcionalidades
            específicas.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Melhorias da plataforma: compreender como o usuário utiliza os serviços
            da plataforma, para ajudar no desenvolvimento de negócios e técnicas.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Anúncios: apresentar anúncios personalizados para o usuário com base nos
            dados fornecidos.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Comercial: os dados são usados para personalizar o conteúdo oferecido e
            gerar subsídio à plataforma para a melhora da qualidade no funcionamento dos
            serviços.</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Dados de cadastro: para permitir o acesso do usuário a determinados
            conteúdos da plataforma, exclusivo para usuários cadastrados</p>
          <p style={{marginLeft: '24.0pt', textIndent: '-24.0pt'}}>•<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>Dados de contrato: conferir às partes segurança jurídica e facilitar a
            conclusão do negócio.</p>
          <p>O tratamento de dados pessoais para finalidades não
            previstas nesta Política de Privacidade somente ocorrerá mediante comunicação
            prévia ao usuário, de modo que os direitos e obrigações aqui previstos
            permanecem aplicáveis.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM
              ARMAZENADOS?</b></p>
          <p>Os dados pessoais do usuário e visitante são armazenados
            pela plataforma durante o período necessário para a prestação do serviço ou o
            cumprimento das finalidades previstas no presente documento, conforme o
            disposto no inciso <span lang="EN-US"><a href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018"><span className="Link"><span lang="PT-BR" style={{textDecoration: 'none'}}>I</span></span></a></span>
            do artigo <span lang="EN-US"><a href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018"><span><span lang="PT-BR" style={{textDecoration: 'none'}}>15</span></span></a></span>
            da Lei <span lang="EN-US"><a href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"><span><span lang="PT-BR" style={{textDecoration: 'none'}}>13.709</span></span></a></span>/18.</p>
          <p>Os dados podem ser removidos ou anonimizados a pedido do
            usuário, excetuando os casos em que a lei oferecer outro tratamento.</p>
          <p>Ainda, os dados pessoais dos usuários apenas podem ser conservados
            após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16
            da referida lei:</p>
          <p>I - cumprimento de obrigação legal ou regulatória pelo
            controlador;</p>
          <p>II - estudo por órgão de pesquisa, garantida, sempre que
            possível, a anonimização dos dados pessoais;</p>
          <p>III - transferência a terceiro, desde que respeitados os
            requisitos de tratamento de dados dispostos nesta Lei;</p>
          <p>IV - uso exclusivo do controlador, vedado seu acesso por
            terceiro, e desde que anonimizados os dados.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</b></p>
          <p>A plataforma se compromete a aplicar as medidas técnicas e
            organizativas aptas a proteger os dados pessoais de acessos não autorizados e
            de situações de destruição, perda, alteração, comunicação ou difusão de tais
            dados.</p>
          <p>Os dados relativas a cartões de crédito são criptografados
            usando a tecnologia "secure socket layer" (SSL) que garante a
            transmissão de dados de forma segura e confidencial, de modo que a transmissão
            dos dados entre o servidor e o usuário ocorre de maneira cifrada e encriptada.</p>
          <p>A plataforma não se exime de responsabilidade por culpa
            exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa
            exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a
            terceiros. O site se compromete a comunicar o usuário em caso de alguma
            violação de segurança dos seus dados pessoais.</p>
          <p>Os dados pessoais armazenados são tratados com
            confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas
            informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você
            violar nossos Termos de Serviço.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 7 – COOKIES OU DADOS DE NAVEGAÇÃO</b></p>
          <p>Os cookies referem-se a arquivos de texto enviados pela
            plataforma ao computador do usuário e visitante e que nele ficam armazenados,
            com informações relacionadas à navegação no site. Tais informações são
            relacionadas aos dados de acesso como local e horário de acesso e são
            armazenadas pelo navegador do usuário e visitante para que o servidor da
            plataforma possa lê-las posteriormente a fim de personalizar os serviços da
            plataforma.</p>
          <p>O usuário e o visitante da plataforma Web NFe manifesta
            conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de
            navegação mediante à utilização de cookies.</p>
          <p>O cookie persistente permanece no disco rígido do usuário e
            visitante depois que o navegador é fechado e será usado pelo navegador em
            visitas subsequentes ao site. Os cookies persistentes podem ser removidos
            seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e
            desaparece depois que o navegador é fechado. É possível redefinir seu navegador
            da web para recusar todos os cookies, porém alguns recursos da plataforma podem
            não funcionar corretamente se a capacidade de aceitar cookies estiver
            desabilitada.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 8 - CONSENTIMENTO</b></p>
          <p>Ao utilizar os serviços e fornecer as informações pessoais
            na plataforma, o usuário está consentindo com a presente Política de
            Privacidade.</p>
          <p>O usuário, ao cadastrar-se, manifesta conhecer e pode
            exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus
            dados pessoais e garante a veracidade das informações por ele disponibilizadas.</p>
          <p>O usuário tem direito de retirar o seu consentimento a
            qualquer tempo, para tanto deve entrar em contato através do e-mail waldentecnologia@outlook.com
          </p>
          <p><b>&nbsp;</b></p>
          <p><b>SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</b></p>
          <p>Reservamos o direito de modificar essa Política de
            Privacidade a qualquer momento, então, é recomendável que o usuário e visitante
            revise-a com frequência.</p>
          <p>As alterações e esclarecimentos vão surtir efeito
            imediatamente após sua publicação na plataforma. Quando realizadas alterações
            os usuários serão notificados. Ao utilizar o serviço ou fornecer informações
            pessoais após eventuais modificações, o usuário e visitante demonstra sua
            concordância com as novas normas.</p>
          <p>Diante da fusão ou venda da plataforma à outra empresa os
            dados dos usuários podem ser transferidas para os novos proprietários para que
            a permanência dos serviços oferecidos.</p>
          <p>&nbsp;</p>
          <p><b>SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</b></p>
          <p>Para a solução de controvérsias decorrentes do presente
            instrumento será aplicado integralmente o Direito brasileiro.</p>
          <p>Os eventuais litígios deverão ser apresentados no foro da
            comarca em que se encontra a sede da empresa.</p>
        </div>
      );
    }
  }