import React from "react";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ResumoTotalISS = props => {
  const { resumoCalc } = props;
  return (
    <div className="card">
      <div className="card-header">ISS</div>
      <div className="card-body">
        <div className="row">
          <div className="col-2 col-md-2 col-lg-2">
            <div className="form-group ">
              <label>Base de Cálculo</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.issBaseCalculo)}</div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>ISS</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.iss)}</div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>PIS</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.issPis)}</div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-1 col-lg-1">
            <div className="form-group ">
              <label>COFINS</label>
              <div className="form-group ">
                <div className="form-group ">{formatter.format(resumoCalc.issConfins)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResumoTotalISS.propTypes = {};

export default ResumoTotalISS;
