import ApiService from "./ApiService";

export default class ApiServiceProduto extends ApiService {
    constructor(props) {
        super();
    }

    getUnidadeMedida() {
        return this.fetch(`Sistema/UnidadeMedida`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    getProdutoNCM(nome) {
        return this.fetch(`Sistema/SisNCM/${nome}`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    getProdutos() {
        return this.fetch(`Produtos/Empresa`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    getProduto(id) {
        return this.fetch(`Produtos/${id}`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    getPesquisarProduto(produto, idDestinatario) {
        return this.fetch(`Nota/PesquisarProduto/${idDestinatario}?pesquisa=${produto}`, {
            method: "GET"
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    incluirProduto(body) {
        return this.fetch(`Produtos`, {
            method: "POST",
            body: JSON.stringify(body)
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    excluirProduto(id) {
        return this.fetchSemJson(`Produtos?id=${id}`, {
            method: "DELETE"            
        }).then(res => {
            return Promise.resolve(res);
        });
    }


    alterarProduto(body) {
        return this.fetch(`Produtos/${body.idProduto}`, {
            method: "PUT",
            body: JSON.stringify(body)
        }).then(res => {
            return Promise.resolve(res);
        });
    }
}
