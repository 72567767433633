import React, { Component } from "react";
import admin4bImg from "./assets/brand/admin4b.svg";
import { Link } from "react-router-dom";
import serialize from "form-serialize";
import ApiService from "../utils/ApiService";
import { BeatLoader } from "react-spinners";

class RecuperarSenhaNova extends Component {
  state = {
    hasError: false,
    errors: [],
    loading: false,
    dados: {
      email: "",
      token: "",
      novaSenha: "",
      novaSenhaConfirmacao: ""
    },
    token: this.props.match.params.id
  };

  componentDidMount() {
    var dados = Object.assign({}, this.state.dados);
    dados["token"] = this.state.token;
    this.setState({ dados });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({loading: true})
    var api = new ApiService();
    try {
      api.AlterarSenha(this.state.dados).then(x => {
        if (x.error && x.error.count > 0) {
          this.setState({ hasError: true, errors: x.error.erros });
        } else {
          alert("Senha alterada com sucesso.");
          this.props.history.push(`/login`);
        }
        this.setState({loading: false})
      });
    } catch (error) {
      this.setState({loading: false})
    }
  };

  handleInputChange = e => {
    var dados = Object.assign({}, this.state.dados);
    dados[e.target.name] = e.target.value;
    this.setState({ dados });
  }

  render() {
    const { hasError, errors, dados } = this.state;
    return (
      <div className="container-sign">
        <div className="card">
          <div className="card-header">
            {hasError && (
              <div>
                <ul>
                  {errors.map(e => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
              </div>
            )}

            <img src={admin4bImg} />
            <h1 />
            <span>Alterar senha</span>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label className="d-flex">
                  <div>Senha</div>
                </label>
                <input type="password"
                  className="form-control"
                  name="novaSenha"
                  value={dados.novaSenha}
                  onChange={this.handleInputChange} />
              </div>
              <div className="form-group">
                <label className="d-flex">
                  <div>Confirmação de Senha</div>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="novaSenhaConfirmacao"
                  value={dados.novaSenhaConfirmacao}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-group d-flex">
                <button className="btn btn-primary ml-auto" type="submit">
                  {this.state.loading && (
                    <BeatLoader
                      sizeUnit={"px"}
                      size={10}
                      color={'#123abc'}
                      loading={this.state.loading}
                    />
                  )}
                  {!this.state.loading && (
                    "Recuperar"
                  )}</button>
              </div>
            </div>
            <div className="card-footer">
              <p className="text-muted">Não têm conta?</p>
              <Link to="/Register">Cadastre-se</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default RecuperarSenhaNova;
