import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HomeIcon from "@material-ui/icons/Home";
import HistoryIcon from "@material-ui/icons/History";
import PeopleIcon from "@material-ui/icons/People";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StoreIcon from "@material-ui/icons/Store";
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { Link } from "react-router-dom";

const menu = [
  { label: "Home", link: "/Home", icon: <HomeIcon /> },
  { label: "Certificado Digital", link: "/cert", icon: <CardMembershipIcon /> },
  { label: "Empresa", link: "/empresa", icon: <BusinessIcon /> },
  { label: "Configuração", link: "/configuracoes", icon: <SettingsIcon /> },
  {
    label: "Grupo de Impostos",
    link: "/grupoImpostos",
    icon: <AccountTreeIcon />
  },
  { label: "Produto/Serviço", link: "/produtos", icon: <StoreIcon /> },
  {
    label: "Destinatário",
    link: "/destinatarios",
    icon: <PeopleIcon />
  },
  { label: "Nota Fiscal", link: "/notafiscal", icon: <ReceiptIcon /> }
];

export const mainListItems = (
  <div>
    {menu.map((m, i) => (
      <ListItem button key={"m_" + i} component={Link} to={m.link}>
        <ListItemIcon>{m.icon}</ListItemIcon>
        <ListItemText primary={m.label} />
      </ListItem>
    ))}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListItem button component={Link} to="/historicoCompra">
      <ListItemIcon>
        <HistoryIcon />
      </ListItemIcon>
      <ListItemText primary="Histórico de Compra" />
    </ListItem>
  </div>
);
