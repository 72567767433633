import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ModalHistoricoNota extends Component {
  state = {
    loading: true
  };
  render() {
    const { NCM, loading } = this.state;
    const { historico, chave } = this.props;
    return (
      <div>
        <Modal
          onOpened={this.onOpened}
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          backdrop={this.props.backdrop}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Historico</h1>
          </ModalHeader>
          <ModalBody>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Descrição</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {historico.map(h => (
                    <tr>
                      <td>{h.cStat}</td>
                      <td>{h.xMotivo}</td>
                      <td>
                            {new Intl.DateTimeFormat("pt-BR").format(
                              new Date(h.dhRecbto)
                            )}
                          </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <div><b>Chave:</b> {chave}</div>
            <Button color="secondary" onClick={this.props.toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalHistoricoNota;
