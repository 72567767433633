import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ApiServiceGrupoImposto from "../../utils/ApiServiceGrupoImposto";
import ModalNotaProdutoSimplesNacionalServico from "./ModalNotaProdutoSimplesNacionalServico";

import CurrencyInput from 'react-currency-input'

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

class ModalEditarProduto extends Component {
  state = {
    loading: false,
    CFOP: [],
    ORIGEM: [],
    ICMS: [],
    IPI: [],
    PIS: [],
    COFINS: [],
    SERVICOS: []
  };

  componentDidMount() {
    var apiGrupoImposto = new ApiServiceGrupoImposto();
    apiGrupoImposto.getCFOP().then(d => {
      this.setState({ CFOP: d.data });
    });

    apiGrupoImposto.getORIGEM().then(d => {
      this.setState({ ORIGEM: d.data });
    });

    apiGrupoImposto.getICMS().then(d => {
      this.setState({ ICMS: d.data });
    });

    apiGrupoImposto.getIPI().then(d => {
      this.setState({ IPI: d.data });
    });
    apiGrupoImposto.getPIS().then(d => {
      this.setState({ PIS: d.data });
    });
    apiGrupoImposto.getCOFINS().then(d => {
      this.setState({ COFINS: d.data });
    });

    apiGrupoImposto.getTipoServico().then(d => {
      this.setState({ SERVICOS: d.data });
    });
  }

  alterarDadosValorProduto = (e, maskedvalue, floatvalue) => {
    var v = floatvalue;
    if (floatvalue === "") v = 0;
    var i = e.target.name.indexOf("_")
    var j = e.target.name.substr(i + 1, e.target.name.length - 1);
    var nameCampo = e.target.name.substr(0, i);
    this.props.onAlterarDadosProdutoNovo(j, v, nameCampo)
  };

  render() {
    const {
      loading,
      CFOP,
      ORIGEM,
      ICMS,
      IPI,
      PIS,
      COFINS,
      SERVICOS
    } = this.state;
    const { produtos, produtoKey } = this.props;
    var produto = produtos[produtoKey];
    return (
      <div>
        {produto && (
          <Modal
            onOpened={this.onOpened}
            isOpen={this.props.modal}
            toggle={this.props.toggle}
            className={this.props.className}
            backdrop={this.props.backdrop}
            size="lg"
          >
            <div className="modal-header">{produto.produto.descricao}</div>

            <ModalBody>
              <div className="row justify-content-around">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="form-group ">
                    <label>Complemento (Opcional)</label>
                    <div className="form-group ">
                      <textarea 
                        className="form-control" 
                        name="descricaoComplementar" 
                        maxLength="500"
                        onChange={e => this.props.onAlterarDadosProduto(produtoKey, e)
                      }>{produto.descricaoComplementar}</textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Quantidade</label>
                    <div className="form-group ">
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        value={produto.quantidade}
                        name="quantidade"
                        onChange={e =>
                          this.props.onAlterarDadosProduto(produtoKey, e)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Valor Unitário</label>
                    <div className="form-group ">
                      <CurrencyInput value={produto.valor}
                        decimalSeparator=","
                        thousandSeparator="."
                        name={"valor_" + produtoKey}
                        className="form-control" onChangeEvent={this.alterarDadosValorProduto} />
                    </div>
                  </div>
                </div>
                <div className="col-1 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Total Bruto</label>
                    <div className="form-group ">{formatter.format(produto.valorBruto)}</div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Descontos</label>
                    <div className="form-group ">
                      <CurrencyInput value={produto.desconto}
                        decimalSeparator=","
                        thousandSeparator="."
                        name={"desconto_" + produtoKey}
                        className="form-control" onChangeEvent={this.alterarDadosValorProduto} />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Frete</label>
                    <div className="form-group ">
                      <CurrencyInput value={produto.frete}
                        decimalSeparator=","
                        thousandSeparator="."
                        name={"frete_" + produtoKey}
                        className="form-control" onChangeEvent={this.alterarDadosValorProduto} />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Outros</label>
                    <div className="form-group ">
                      <CurrencyInput value={produto.outros}
                        decimalSeparator=","
                        thousandSeparator="."
                        name={"outros_" + produtoKey}
                        className="form-control" onChangeEvent={this.alterarDadosValorProduto} />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Seguro</label>
                    <div className="form-group ">
                      <CurrencyInput value={produto.seguro}
                        decimalSeparator=","
                        thousandSeparator="."
                        name={"seguro_" + produtoKey}
                        className="form-control" onChangeEvent={this.alterarDadosValorProduto} />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                  <div className="form-group ">
                    <label>Total Liquido</label>
                    <div className="form-group ">{formatter.format(produto.valorLiquido)}</div>
                  </div>
                </div>
              </div>
              <ModalNotaProdutoSimplesNacionalServico
                CFOP={CFOP.filter(x => x.entrada === produto.notaFiscalProdutoImposto[0].objetoCFOP.entrada)}
                ORIGEM={ORIGEM}
                PIS={PIS}
                COFINS={COFINS}
                PRODUTO={produto}
                NOMECAMPO="ENTRADA"
                produtoKey={produtoKey}
                onAlterarDadosImposto={this.props.onAlterarDadosImposto}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.props.toggle}>
                Fechar
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

export default ModalEditarProduto;
