import React, { Component } from "react";
import ApiService from "./../utils/ApiService";
import serialize from "form-serialize";
import ApiServiceCNPJ from "../utils/ApiServiceCNPJ";
import LoadingOverlay from "react-loading-overlay";
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

class empresa extends Component {
  state = {
    uf: [],
    municipios: [],
    loading: false,
    empresa: {
      idEmpresa: 0,
      idUsuario: "",
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      inscricaoEstadual: "",
      idRegimeTributario: 0,
      cnae: "",
      cep: "",
      endereco: "",
      bairro: "",
      idMunicipio: 0,
      telefone: "",
      contato: "",
      municipio: {
        idMunicipio: 0,
        descricao: "",
        idUF: 0
      }
    },
    error: [],
    mensagemConfirmacao: "",
    redirect: false
  };

  componentDidMount() {
    $('.telefone').mask('(00) 0000-0000', { reverse: false });
    $('.cep').mask('00.000-000', { reverse: false });
    $('.inscricao').mask('0000000000000', { reverse: false });

    var api = new ApiService();

    api.obterStatusCadastro().then(d => {
      if (!d.fezUploadCertificado)
      {
        this.setState({ redirect: true });
        alert("Por favor, cadastrar o certificado digital!");
        this.props.history.push("/cert");
      } else {
        if (!d.informouDadosEmpresa)
        {
          this.setState({ mensagemConfirmacao: "É necessário confirmar os dados da empresa, clicando em salvar." });
        } 

        api.obterUFs().then(x => {
          this.setState({ uf: x.data });      
        });
    
        api.obterEmpresa().then(x => {
          if (x.data) {
            var empresa = x.data;
            if(empresa.cep != null) empresa.cep = empresa.cep.replace(/(\d{2})(\d{3})(\d{3})/g,"$1.$2-$3");
            if(empresa.telefone != null) empresa.telefone = empresa.telefone.replace(/^(\d{2})(\d)/g,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2");
    
            this.setState({ empresa });
            this.handleUFChange({
              target: { value: x.data.municipio.idUF }
            });
          }
        }); 
      }
    });
  }

  handleUFChange = e => {
    var api = new ApiService();
    var idUF = e.target.value;
    if (idUF !== "") {
      var empresa = Object.assign({}, this.state.empresa);
      empresa["municipio"]["idUF"] = e.target.value;
      api.obterMunicipio(idUF).then(x => {
        this.setState({ municipios: x.data, empresa });
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    var api = new ApiService();
    var form = serialize(e.target, { hash: true });

    api.atualizarCadastroEmpresa(form).then(r => {
      if (r.error && r.error.count > 0) {
        this.setState({ error: r.error.erros });
      } else {
        alert("Operação realizada com sucesso.");
      }
      this.setState({ loading: false });
      //alert("Operação realizada com sucesso.");
    });
  };

  handleInputChange = e => {
    //console.log(this.state);
    var empresa = Object.assign({}, this.state.empresa);
    empresa[e.target.name] = e.target.value;

    if (e.target.name === "cnpj") {
      if (e.target.value.length === 14) {
        var api = new ApiServiceCNPJ();
        api.getCNPJ(e.target.value).then(cnpj => {
          cnpj = cnpj.data;
          empresa["razaoSocial"] = cnpj.nome;
          empresa["nomeFantasia"] = cnpj.nome;
          empresa["municipio"]["idUF"] = this.state.uf.filter(
            x => x.sigla === cnpj.uf
          )[0].idUF;
          empresa["cep"] = cnpj.cep.replace(/[^0-9]+/g, "");
          empresa["endereco"] = cnpj.logradouro;
          empresa["telefone"] = cnpj.telefone;
          empresa["bairro"] = cnpj.bairro;
          api.obterMunicipio(empresa["municipio"]["idUF"]).then(x => {
            this.setState({ municipios: x.data });
          });

          //this.setState({ CNPJ: d.data });
        });
      }
    } else if (e.target.name === "cnae") {
      empresa["cnae"] = e.target.value.replace(/[^0-9]+/g, "");
    }
    this.setState({
      empresa: empresa
    });
  };

  render() {
    const { uf, municipios, empresa, loading, error, mensagemConfirmacao } = this.state;
    return (
      <LoadingOverlay active={loading} spinner>
        <div className="container">
          {error.length > 0 && (
            <div className="callout callout-danger" style={{color: "red"}}>
              {error.map((e, c) => (
                <p>
                  {c + 1} - {e}
                </p>
              ))}
            </div>
          )}
          {mensagemConfirmacao != "" && (
            <div className="callout callout-danger" style={{color: "red"}}>
              {mensagemConfirmacao}
            </div>
          )}
          <form onSubmit={this.handleSubmit}>
            <div className="card">
              <div className="card-header">Dados da Empresa</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group ">
                      <label>CNPJ</label>
                      <div><label>{empresa.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5")}</label></div>
                      <input
                        type="hidden"
                        name="cnpj"
                        value={empresa.cnpj}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group ">
                      <label>Razão Social</label>
                      <input
                        type="text"
                        className="form-control"
                        name="razaoSocial"
                        maxLength="60"
                        value={empresa.razaoSocial}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="form-group ">
                      <label>Nome Fantasia</label>
                      <input
                        type="text"
                        className="form-control"
                        name="nomeFantasia"
                        maxLength="60"
                        value={empresa.nomeFantasia}
                        onChange={this.handleInputChange}
                      />
                      {/* <small className="text-danger">
                    Este campo é obrigatório.
                  </small> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group ">
                      <label>Insc. Estadual</label>
                      <input
                        required
                        type="text"
                        className="form-control inscricao"
                        name="inscricaoEstadual"
                        value={empresa.inscricaoEstadual}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Regime Tributário</label>
                      <select
                        className="form-control"
                        name="idRegimeTributario"
                        value={empresa.idRegimeTributario}
                        onChange={this.handleInputChange}
                      >
                        <option value="0">Nenhum</option>
                        <option value="1">
                          Micro Empresa Municipal
                        </option>
                        <option value="4">Cooperativa</option>
                        <option value="5">
                          Micro Empresa Individual
                        </option>
                        <option value="7">
                          Lucro Real
                        </option>
                        <option value="8">
                          Lucro Presumido
                        </option>
                        <option value="9">
                          Simples Nacional
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group ">
                      <label>Nº CNAE</label>
                      <input
                        type="text"
                        className="form-control"
                        name="cnae"
                        value={empresa.cnae}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-2 col-lg-2">
                    <div className="form-group ">
                      <label>CEP</label>
                      <input
                        type="text"
                        className="form-control cep"
                        name="cep"
                        value={empresa.cep}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group ">
                      <label>Endereço</label>
                      <input
                        type="text"
                        className="form-control"
                        name="endereco"
                        value={empresa.endereco}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Bairro</label>
                      <input
                        type="text"
                        className="form-control"
                        name="bairro"
                        value={empresa.bairro}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-2 col-lg-2">
                    <div className="form-group">
                      <label>UF</label>
                      <select
                        name="idUF"
                        className="form-control"
                        onChange={this.handleUFChange}
                        value={empresa.municipio.idUF}
                      >
                        <option />
                        {uf.map(u => (
                          <option value={u.idUF} key={u.idUF}>
                            {u.sigla}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>Município</label>
                      <select
                        className="form-control"
                        name="idMunicipio"
                        value={empresa.idMunicipio}
                        onChange={this.handleInputChange}
                      >
                        <option />
                        {municipios.map(m => (
                          <option key={m.idMunicipio} value={m.idMunicipio}>
                            {m.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>Telefone</label>
                      <input
                        type="text"
                        className="telefone form-control"
                        name="telefone"
                        value={empresa.telefone}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <button
                type="submit"
                className="btn btn-primary mr-1"
                style={{ marginTop: 10, marginLeft: "auto" }}
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </LoadingOverlay>
    );
  }
}

export default empresa;
