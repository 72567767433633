export const LOGGIN_USER = "LOGGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export function loginUser({ user }) {
  return {
    type: LOGGIN_USER,
    user
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}
