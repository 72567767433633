import React, { Component } from "react";
import { Link } from "react-router-dom";
import ApiServiceProduto from "../utils/ApiServiceProduto";
import LoadingOverlay from "react-loading-overlay";

class produtos extends Component {
  state = {
    produtos: [],
    loading: true
  };

  componentDidMount() {
    this.carregarProdutos();
  }

  redirectEdit = id => {
    this.props.history.push(`/cadastrarProduto/${id}`);
  };

  carregarProdutos() {
    var api = new ApiServiceProduto();
    api.getProdutos().then(x => {
      this.setState({ produtos: x.data, loading: false });
    });
  }

  exlcuir = id => {
    var api = new ApiServiceProduto();
    this.setState({ loading: true })
    api.excluirProduto(id).then(() => {
      this.carregarProdutos();
    })
  };

  render() {
    const { loading, produtos } = this.state;

    return (
        <div className="container">
          <LoadingOverlay active={loading} spinner>
          <div className="form-group">
            <Link to="/cadastrarProduto" className="btn btn-primary mr-1">
              Incluir
          </Link>
          </div>
          <div className="card" style={{ width: "100%" }}>
              <div className="card-header">Lista de Produto/Serviço</div>
              <div className="card-body">
                <div className="row">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Valor Unitário</th>
                        <th scope="col">Unidade Medida</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Grupo</th>
                        <th scope="col">NCM</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {produtos.map(i => (
                        <tr
                          key={i.idProduto}
                        >
                          <td>{i.codigoProduto}</td>
                          <td>{i.descricao}</td>
                          <td>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(i.valorUnitario)}
                          </td>
                          <td>{i.unidadeMedida.sigla}</td>
                          <td>{i.idTipo === 1 ? "Produto" : "Serviço"}</td>
                          <td>{i.grupoImposto.descricao}</td>
                          <td>{i.ncm.codNCM}</td>
                          <td><button onClick={() => { this.redirectEdit(i.idProduto) }}>Editar</button></td>
                          <td>
                            <button onClick={() => { this.exlcuir(i.idProduto) }}>Remover</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
    );
  }
}

export default produtos;
