import React, { Component } from "react";
import admin4bImg from "./assets/brand/admin4b.svg";
import { Link } from "react-router-dom";
import serialize from "form-serialize";
import ApiService from "../utils/ApiService";
import { BeatLoader } from "react-spinners";

class RecuperarSenha extends Component {
  state = {
    hasError: false,
    errors: [],
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true })
    var api = new ApiService();
    var form = serialize(e.target, { hash: true });
    try {
      api.RecuperarSenha(form.email, form.senha).then(x => {
        if (x.error && x.error.count > 0) {
          this.setState({ hasError: true, errors: x.error.erros });
        } else {
          this.setState({ hasError: false });
          alert("Foi enviado um e-mail com todas as instruções para alterar a senha")
          this.props.history.push(`/login`);
        }
        this.setState({ loading: false })
      });
      
    } catch (error) {
      console.log(error);
      this.setState({ loading: false })
    }
    
  };

  render() {
    const { hasError, errors } = this.state;
    return (
      <div className="container-sign">
        <div className="card">
          <div className="card-header">
            {hasError && (
              <div>
                <ul>
                  {errors.map(e => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
              </div>
            )}
          <h1>Recuperar Senha</h1>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label>E-mail</label>
                <input type="text" className="form-control" name="email" />
              </div>
              <div className="form-group d-flex">
                <button className="btn btn-primary ml-auto" type="submit">
                  {this.state.loading && (
                    <BeatLoader
                      sizeUnit={"px"}
                      size={10}
                      color={'#123abc'}
                      loading={this.state.loading}
                    />
                  )}
                  {!this.state.loading && (
                    "Recuperar"
                  )}</button>
              </div>
            </div>
            <div className="card-footer">
              <Link to="/login">Login</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default RecuperarSenha;
