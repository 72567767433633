import ApiService from "./ApiService";

export default class ApiServiceCNPJ extends ApiService {
  constructor(props) {
    super();
  }

  getCNPJ(cnpj) {
    return this.fetch(`Cnpj/${cnpj}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  getDestinatario() {
    return this.fetch(`Destinatarios/Empresa`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  carregarDestinatario(id) {
    return this.fetch(`Destinatarios/${id}`, {
      method: "GET"
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  incluirDestinario(body) {
    return this.fetch(`Destinatarios`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  alterarDestinario(body) {
    return this.fetch(`Destinatarios/${body.idDestinatario}`, {
      method: "PUT",
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
}
